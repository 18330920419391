import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalGeneric } from '../../../../ui/generics';
import { ExportTypesEnum, ExportTypesTranslationsEnum } from '../../core/enums/ExportTypesEnum';
import { ReportsErrorResolverService, ReportsService } from '../../../reports/core/services';
import { ExportService, NotificationService } from '../../../../core/services';
import { Guid } from 'guid-typescript';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-new-export-modal',
  templateUrl: './new-export-modal.component.html',
  styleUrls: ['./new-export-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewExportModalComponent extends ModalGeneric implements OnInit {

  @ViewChild('formElementRef') formElementRef: ElementRef<HTMLFormElement>;

  public form: FormGroup;
  public isSubmitted = false;
  public isConfirmLoading = false;
  public readonly exportTypesEnum = ExportTypesEnum;
  public readonly exportTypesTranslationsEnum = ExportTypesTranslationsEnum;

  private readonly _formControls = {
    exportType: ['', Validators.required]
  };

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly _formBuilder: FormBuilder,
    private readonly _reportsService: ReportsService<null>,
    private readonly _exportService: ExportService,
    private readonly _notificationService: NotificationService,
    private readonly _reportsErrorResolverService: ReportsErrorResolverService,
  ) {
    super(cdr);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
  }

  public submitForm(): void {
    this.isSubmitted = true;

    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      }
    }

    if (!this.form.valid) {
      return;
    }

    this.isConfirmLoading = true;
    const operationId = Guid.create().toString();
    this._exportService.operationId = operationId;

    this._reportsService.generateSalesReport(this.form.value.exportType, operationId).pipe(
      finalize(this.resetLoading.bind(this))
    ).subscribe(() => {
      this._notificationService.pushSuccess('Erfolg', 'Dokument wird generiert');
      this.handleCancel();
    }, (err) => {
      this._notificationService.pushError(
        'Fehler',
        this._reportsErrorResolverService.getMessage(err ? err.errorCode : '')
      );
    });
  }

  public handleCancel(): void {
    super.handleCancel();
    this.initForm();
  }

  private initForm(): void {
    this.form = this._formBuilder.group(this._formControls);
  }

  private resetLoading(): void {
    this.isConfirmLoading = false;
    this.isSubmitted = false;
    this.cdr.detectChanges();
  }
}
