<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    {{ contentText }}
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Nein</button>
    <button nz-button nzType="primary" (click)="handleOk()">Ja</button>
  </ng-template>
</nz-modal>
