import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrderTypes } from '../core/enums';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersComponent implements OnDestroy {

  @ViewChild('listComponent') listComponent: OrdersListComponent;

  public selectedOrderType = OrderTypes.MIXED;
  public readonly orderTypes = OrderTypes;

  private readonly _sub$: Subscription;

  public constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _cdr: ChangeDetectorRef,
  ) {
    this._sub$ = this._activatedRoute.queryParams.subscribe(param => {
      this.selectedOrderType = param['orderType'] && OrderTypes[param['orderType']]
        ? param['orderType']
        : OrderTypes.MIXED;
      this._cdr.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this._sub$?.unsubscribe();
  }
}
