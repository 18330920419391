import { GenericService } from '../../../../core/services/GenericService';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { Observable } from 'rxjs';
import { ISimpleListItem } from '../../../../core/interfaces/ISimpleListItem';
import { HttpParams } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { IStore } from '../interfaces/IStore';

@Injectable({
  providedIn: 'root'
})

export class StoresService extends GenericService<IStore> {
  protected readonly baseUrl = '/stores';
  private _stores$: Observable<ISimpleListItem[]>;

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  public getSimple(): Observable<ISimpleListItem[]> {
    if (!this._stores$) {
      const params = new HttpParams()
      .append('Search', '')
      .append('Results', '50');

      this._stores$ = this.apiService.get(`${this.baseUrl}/simple`, params).pipe(shareReplay(1));
    }

    return this._stores$;
  }

}
