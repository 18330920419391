import { GenericService } from '../../../../core/services/GenericService';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { ISupplier } from '../interfaces/ISupplier';
import { Observable } from 'rxjs';
import { ISimpleListItem } from '../../../../core/interfaces/ISimpleListItem';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService extends GenericService<ISupplier>{
  protected readonly baseUrl = '/suppliers';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getSimpleList( search = ''): Observable<ISimpleListItem[]> {
    const params = new HttpParams()
      .append('search', `${search}`);
    return this.apiService.get(`${this.baseUrl}/simple`, params);
  }
}
