import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPossitiveNumbers]'
})
export class PossitiveNumbersDirective {

  constructor(
    private _el: ElementRef
  ) {
    _el.nativeElement.setAttribute('type', 'number');
    _el.nativeElement.setAttribute('min', 0);

    _el.nativeElement.oninput = ($event)  => {
      if ($event.target.validity.rangeUnderflow) {
        _el.nativeElement.value = '';
      }
    };
  }


}
