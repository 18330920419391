import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-boolean-icon',
  templateUrl: './boolean-icon.component.html',
  styleUrls: ['./boolean-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanIconComponent implements OnInit {

  private _state = false;

  constructor() { }

  @Input() set state(state: boolean) {
    this._state = state;
  }

  get state(): boolean {
    return this._state;
  }

  ngOnInit(): void {
  }

}
