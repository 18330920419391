import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ListComponentGeneric } from '../../../ui/generics';
import { NotificationService } from '../../../core/services';
import { ProductionOrderServiceErrorResolverService } from '../core/services/error-resolvers/production-order-service-error-resolver.service';
import { IProductionOrder } from '../core/interfaces/IProductionOrder';
import { ProductionOrdersService } from '../core/services/ProductionOrdersService';
import { ProductionOrderStatuses } from '../../../core/enums';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends ListComponentGeneric<IProductionOrder> implements OnInit {
  public showUncompletedOrders = false;
  public selectedDocumentKeys: string[] = [];
  statusFilters = Object.keys(ProductionOrderStatuses).map(key => {
    return {
      value: key,
      text: ProductionOrderStatuses[key],
      byDefault: false
    };
  });
  searchColumns = [
    'id',
    'createdAt',
    'article.orderNumber',
    'article.name',
    'processingDate',
    'worker.fullName',
    'status',
  ];

  protected readonly defaultSorting = {
    sortField: 'id',
    sortOrder: 'descend'
  };
  protected readonly exportedTableVisibleName = 'Production orders list';

  constructor(
    protected _service: ProductionOrdersService,
    protected _cdr: ChangeDetectorRef,
    protected _notify: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: ProductionOrderServiceErrorResolverService
  ) {
    super(_service, _cdr, _notify, renderer, _errorResolver);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public filtersChanged(): void {
    this.showUncompletedOrders = false;
    this._cdr.detectChanges();
  }

  public valueChanged(): void {
    this.statusFilters = this.statusFilters.map(el => {
      el.byDefault = this.showUncompletedOrders && (
        el.value === 'Created' ||
        el.value === 'Filled' ||
        el.value === 'Labeled');
      return el;
    });
    this._cdr.detectChanges();
  }

  public cancelProductionOrder(id: number): void {
    this.loading = true;
    this._service.cancel(id).pipe(finalize(() => this.loading = false)).subscribe(() => {
      this.onRefreshList(false);
    }, this.parseError);
  }

  protected refreshCheckedStatus(): void {
    super.refreshCheckedStatus();
    this.selectedDocumentKeys = this.data.results
      .filter(value => value.documentKey && this.setOfCheckedId.has(value.id)).map(value => value.documentKey);
    this._cdr.detectChanges();
  }
}
