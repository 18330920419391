import { Injectable } from '@angular/core';
import { SignalRService } from './SingalRService';
import { JwtService } from './auth';
import { ISignalRResponse } from '../interfaces/ISignalRResponse';
import { IExport } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ExportWebSocketService extends SignalRService {
  private readonly _hubUrl = '/export';

  constructor(
    protected readonly jwtService: JwtService
  ) {
    super(jwtService);
    super.buildConnection(this._hubUrl);
  }

  public exportDocumentGenerated(method: (payload: ISignalRResponse<IExport>) => void): void {
    this.createMethod('export-generated', method);
    this.createMethod('sale-report-generated', method);
  }
}
