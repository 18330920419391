import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef, ViewContainerRef
} from '@angular/core';
import { D_GLOBAL_MODAL } from '../../../core/directives';
import { ModalGeneric } from '../../../ui/generics';
import { Subject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { debounceTime } from 'rxjs/operators';
import { IStorageLocation } from '../core/interfaces/IStorageLocation';
import { StoresService } from '../core/services/StoresService';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { IStoreStocksListItem } from '../../stores-stocks/core/interfaces/IStoreStocksListItem';
import { StoreStocksService } from '../../stores-stocks/core/services/StoreStocks.service';
import { QrcodeScannerComponent } from '../../../ui/qrcode-scanner/qrcode-scanner.component';

@Component({
  selector: 'app-storage-place-finder-modal',
  templateUrl: './storage-place-finder-modal.component.html',
  styleUrls: ['./storage-place-finder-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: StoragePlaceFinderModalComponent }]
})
export class StoragePlaceFinderModalComponent extends ModalGeneric implements OnInit {

  @ViewChild('scanField') scanField: ElementRef<HTMLInputElement>;

  public eanCode = '';
  public location: string;
  public title = 'Lagerplatz finder';
  public locations: IStorageLocation[];
  public loading = false;
  private _stores: ISimpleListItem[] = [];
  private _valueChange$ = new Subject<void>();

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly _modal: NzModalService,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _storeStocksService: StoreStocksService<IStoreStocksListItem>,
    private readonly _storesService: StoresService,
  ) {
    super(cdr);
  }


  ngOnInit() {
    this._storesService.getSimple().subscribe(stores => {
      this._stores = stores;
      this._valueChange$.pipe(debounceTime(1000)).subscribe(() => {
        this.loading = true;
        if (this.eanCode !== '') {
          this._storeStocksService.getLocationByEan(this.eanCode)
            .subscribe(res => this.mapLocationsWithStores([res]), this.clearLocations.bind(this));
        } else {
          this._storeStocksService.getProductDetailsByLocation(this.location)
            .subscribe(res => this.mapLocationsWithStores(res), this.clearLocations.bind(this));
        }
        this.cdr.detectChanges();
      });
    });
    super.ngOnInit();
  }

  handleNumberScan = (): void => {
    this.location = '';
    this._valueChange$.next();
  }

  handleBarcodeScanner = (): void => this.createBarcodeScannerModal();

  afterModalOpen(): void {
    if (this.scanField) {
      this.setFocusOnScanField();
    }
  }

  private mapLocationsWithStores(locations: IStorageLocation[]): void {
    this.locations = locations.map(location => {
      location.stores = Object.keys(location).filter(key => key.includes('store') && key.includes('Value')).map(key => {
        return {
          location: location[key],
          ...this._stores.find(({ id }) => key.replace(new RegExp(/[store Value]/, 'g'), '') === id)
        };
      }).filter(store => this.location !== '' ? (store.id && store.location !== '') : store.id);

      return location;
    });

    this.loading = false;
    this.cdr.detectChanges();
  }

  createBarcodeScannerModal(): void {
    const modal = this._modal.create({
      nzTitle: 'Barcode scanner',
      nzContent: QrcodeScannerComponent,
      nzViewContainerRef: this._viewContainerRef,
      nzWidth: 689,
      nzFooter: [
        {
          label: 'Ok',
          type: 'primary',
          onClick: () => modal.destroy()
        }
      ]
    });
    modal.afterClose.subscribe(result => {
      this.eanCode = result?.code ?? '';
      this.handleNumberScan();
      this.cdr.detectChanges();
    });

    this.cdr.detectChanges();
  }

  setFocusOnScanField(): void {
    setTimeout(() => this.scanField.nativeElement.focus(), 100);
  }

  public handleLocationChange(): void {
    this.eanCode = '';
    this._valueChange$.next();
  }

  public handleCancel(): void {
    this.eanCode = '';
    this.location = '';
    this.clearLocations();
    super.handleCancel();
  }

  private clearLocations(): void {
    this.locations = [];
    this.loading = false;
    this.cdr.detectChanges();
  }
}
