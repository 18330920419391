import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { Observable } from 'rxjs';
import { ITableResults } from '../../../../core/interfaces';
import { IReportFlourArticle } from '../../../reports/core/interfaces';
import { GenericService } from '../../../../core/services/GenericService';
import { IFlourPackingListArticle, IFlourPackingListItem, IPackingListArticle } from '../../../flour/core/interfaces';
import { DeliveryNoteDocumentTypes, FlourStockTypes } from '../../../flour/core/enums';
import { map } from 'rxjs/operators';
import { IDeliveryNoteArticle } from '../interfaces/IDeliveryNoteArticle';
import { HttpParams } from '@angular/common/http';
import { HttpUtils } from '../../../../core/utils/HttpUtils';

@Injectable({
  providedIn: 'root'
})
export class FlourService<T> extends GenericService<T> {
  protected readonly baseUrl = '/flour';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  refreshArticlesData(operationId: string): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/refresh-flour-articles-detail`, { operationId });
  }

  public getFlourArticles(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    search: Array<{ key: string; value: string[] }>
  ):  Observable<ITableResults<IReportFlourArticle>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, []);
    return this.apiService.get(`${ this.baseUrl }/articles`, params );
  }

  public getPackingLists(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    search: Array<{ key: string; value: string[] }>,
  ): Observable<ITableResults<IFlourPackingListItem>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, []);
    return this.apiService.get(`${ this.baseUrl }/packing-lists`, params);
  }

  public getPackingListById(packingListId: number): Observable<IFlourPackingListItem> {
    return this.apiService.get(`${ this.baseUrl }/packing-lists/${ packingListId }`);
  }

  public getPackingListArticleByEan(packingListId: number, ean: string): Observable<IFlourPackingListArticle> {
    return this.apiService.get(`${ this.baseUrl }/packing-lists/${ packingListId }/articles/${ ean }`);
  }

  public getPackingListDeliveryNotes(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    search: Array<{ key: string; value: string[] }>,
    documentType: DeliveryNoteDocumentTypes,
    packingListId: number,
  ): Observable<ITableResults<IFlourPackingListItem>> {
    let params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, []);
    params = params.append('documentType', documentType.toString());
    return this.apiService.get(`${ this.baseUrl }/packing-lists/${ packingListId }/delivery-notes`, params);
  }

  public generatePackingList(flourStockType: FlourStockTypes, operationId): Observable<any> {
    return this.apiService.post(`${ this.baseUrl }/generate-packing-list`, {
      flourStock: flourStockType,
      operationId
    });
  }

  public exportCSV(ids: number[]): Observable<any> {
    let params = new HttpParams();
    ids.forEach((id: number) => {
      params = params.append(`ids`, id.toString());
    });

    return this.apiService.getFile(`${this.apiService.getApiUrl()}${ this.baseUrl }/packing-lists/export-delivery-notes`, 'application/csv',params);
  }

  public getPackingListArticles(
    packingListId: number,
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    search: Array<{ key: string; value: string[] }>,
    showOnlyInvalid = false
  ):  Observable<ITableResults<IPackingListArticle>> {
    let params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, []);
    params = params.append('showOnlyInvalid', showOnlyInvalid.toString());

    return this.apiService.get(`${ this.baseUrl }/packing-lists/${ packingListId }/articles`, params);
  }

  public generateDeliveryNote(packingListId: number, deliveryNoteArticles: IDeliveryNoteArticle[]): Observable<IFlourPackingListItem> {
    const body = {
      articleDetails: deliveryNoteArticles
    };

    return this.apiService.post(`${ this.baseUrl }/packing-lists/${ packingListId }/generate-delivery-note`, body);
  }

  getQueryableAll(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, filters: Array<{ key: string; value: string[] }>, search: Array<{ key: string; value: string[] }>): Observable<ITableResults<T>> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }

  delete(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }

  deletePackingList(packingListId: string): Observable<any> {
    return this.apiService.delete(`${ this.baseUrl }/packing-lists/${ packingListId }`);
  }

  update(object: T, id: number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  create(object: T): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
