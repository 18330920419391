import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiVersionSubject: BehaviorSubject<{prev: string|null, active: string|null}> = new BehaviorSubject<{ prev: string|null, active: string|null }>(
    {prev: '', active: ''}
  );

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  private formatErrors(error: any) {
    return  throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams(), options?: object): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params, ...options })
      .pipe(catchError(this.formatErrors));
  }

  getFile(path: string, contentType: string, params: HttpParams = new HttpParams()): Observable<Blob> {
    return this.http.get(path,
      {
        params,
        headers: {
          'Accept': contentType
        },
        responseType : 'blob',
      },
      ).pipe(
        catchError(this.formatErrors),
    );
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body),
      {
        params: params,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).pipe(catchError(this.formatErrors));
  }

  postFormData(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body,
      {
        params: params,
      }
    ).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}${path}`
    ).pipe(catchError(this.formatErrors));
  }

  getApiUrl(): string {
    return `${environment.apiUrl}`;
  }

  setVersion(apiVersion: string) {
    apiVersion = apiVersion.replace('dev-', '');
    const currentState = this.apiVersionSubject.getValue();
    if (currentState.active !== apiVersion) {
      this.apiVersionSubject.next({prev: currentState.active, active: apiVersion});
    }
  }

  getVersion(): Observable<{prev: string|null, active: string|null}> {
    return this.apiVersionSubject.asObservable();
  }

  public uploadFileToS3WithPresignedUrl(url: string, file: File): Observable<Response> {
    return fromPromise(fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file,
    }));
  }
}
