import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ModalGeneric } from '../../../ui/generics';

@Component({
  selector: 'app-store-stocks-corrections-modal',
  templateUrl: './store-stocks-corrections-modal.component.html',
  styleUrls: ['./store-stocks-corrections-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreStocksCorrectionsModalComponent extends ModalGeneric implements OnInit {

  public title = 'Corrections';
  public object: { articleVariantId: number, storeId: number };

  constructor(
    protected readonly cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  public ngOnInit(): void {
  }

  public showModal(object: { articleVariantId: number, storeId: number }): void {
    super.showModal();
    this.object = {
      ...object
    };
  }
}
