import { AbstractControl } from '@angular/forms';

// custom validator to check that two fields match
export function MFAFormat(control: AbstractControl) {

    if (control.value.toString().length !== 6) {
      return { mfaFormat: true };
    }

    return null;
}
