import * as dayjs from 'dayjs';

export interface IOpenOrder {
  id: number;
  number: string;
  fullDate: dayjs.Dayjs;
  shopProviderKey: string;
  isInvoiceRequired: boolean;
  isInvoiceGenerated: boolean;
  isDeliveryNoteGenerated: boolean;
  customer: string;
  status: string;
  documentKey?: string;
  comment: string;
  customerComment: string;
  internalComment: string;
  trackingCode: string;
  deliveryMethod: string;
  deliveryMethodName: string;
  deliverySlipStatus: number; // 0 - in fly, 1- slip, 2 - error
  deliverySlipError: string;
}
