<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
  (nzAfterOpen)="afterModalOpen()"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>

    <form nz-form [formGroup]="form" (ngSubmit)="submit()">
      <nz-row>
        <nz-col>
          <div class="details" *ngIf="!currentStatus">
            <p>Scannen Sie das untenstehende Bild mit der Multi-Faktor-Authentisierung App auf Ihrem Handy. Wenn du diesen Textcode nicht verwenden kannst, gib ihn stattdessen ein</p>
            <br>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input type="text" disabled value="{{manualCode}}" class="form-control">
            </nz-form-control>
            <div class="barcode">
              <img [src]="qrCodeUrl" alt="">
            </div>
            <h3>Geben Sie den sechsstelligen Code von der Applikation ein</h3>
            <p>Nach dem Scannen des Barcodebildes zeigt die App einen sechsstelligen Code an, den Sie unten eingeben können</p><br>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input verificationCode!">
              <input nz-input formControlName="verificationCode" />
            </nz-form-control>
          </div>
          <div class="details" *ngIf="currentStatus">
            <p>Zum Deaktivieren der Multi-Faktor-Authentifizierung geben Sie den 6-stelligen Code von der App auf Ihrem Telefon unten ein</p>
            <br>
            <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="verificationCodeErrorTpl">
              <input nz-input formControlName="verificationCode" />
              <ng-template #verificationCodeErrorTpl>
                <ng-container *ngIf="form.controls.verificationCode.errors['mfaFormat'] && !form.controls.verificationCode.errors['required']">
                  Format von 2FA-CODE sollte 6-stellig sein.
                </ng-container>
                <ng-container *ngIf="form.controls.verificationCode.errors['required']">
                  Code ist erforderlich
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>
        </nz-col>
      </nz-row>

    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button type="button" [disabled]="isSubmitting" (click)="handleCancel()">Schließen</button>
    <button nz-button nzType="primary" [disabled]="isSubmitting" (click)="submit()">
      {{ currentStatus ? 'Deaktivieren' : 'Aktivieren' }}
    </button>
  </ng-template>
</nz-modal>
