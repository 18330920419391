import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../ui/ui.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { CoreModule } from '../../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { StoreStocksComponent } from './store-stocks/store-stocks.component';
import { StorestocksRoutingModule } from './storestocks-routing.module';
import { AddEditStockModalComponent } from './add-edit-stock-modal/add-edit-stock-modal.component';
import { ListComponent } from './list/list.component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { ArticleVariantsStoresStocksComponent } from './article-variants-stores-stocks/article-variants-stores-stocks.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [StoreStocksComponent, ListComponent, AddEditStockModalComponent, ArticleVariantsStoresStocksComponent],
  exports: [
    AddEditStockModalComponent,
    ArticleVariantsStoresStocksComponent,
  ],
  imports: [
    CommonModule,
    StorestocksRoutingModule,
    UiModule,
    NzTableModule,
    CoreModule,
    FormsModule,
    NzFormModule,
    NzDatePickerModule,
    NzSpinModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzIconModule,
    NzModalModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzInputModule,
    NzCheckboxModule,
    NzInputNumberModule,
    SharedModule
  ]
})

export class StorestocksModule { }
