<nz-spin [nzSpinning]="loading">
  <nz-row>
    <form nz-form [formGroup]="form" (ngSubmit)="submitForm()" >
      <nz-form-item>
        <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="name" nzRequired>
          <span>Name</span>
        </nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="Bitte Namen eingeben!">
          <input nz-input id="name" formControlName="name" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="address" nzRequired>
          <span>Adresse</span>
        </nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="Bitte Adresse eingeben!">
          <input nz-input id="address" formControlName="address" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="12" [nzXs]="12" nzFor="isActive">
          <span>Aktiv</span>
        </nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="12">
          <label
            class="verify-checkbox"
            nz-checkbox formControlName="isActive">Ja/Nein</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="12" [nzXs]="12" nzFor="isMainStore">
          <span>Haupt-Lagerhaus</span>
        </nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="12">
          <label
            class="verify-checkbox"
            nz-checkbox formControlName="isMainStore">Ja/Nein</label>
        </nz-form-control>
      </nz-form-item>
    </form>
  </nz-row>
</nz-spin>
