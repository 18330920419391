import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../core/services';
import { AddEditFormGeneric } from '../../../ui/generics';
import { IProductionOrder } from '../core/interfaces/IProductionOrder';
import { ProductionOrdersService } from '../core/services/ProductionOrdersService';
import { ProductionOrderServiceErrorResolverService } from '../core/services/error-resolvers/production-order-service-error-resolver.service';
import { IScanableItem } from '../../../core/interfaces';
import { IProductionOrderVariant } from '../core/interfaces/IProductionOrderVariant';
import { ProductionOrderStatuses } from '../../../core/enums';
import { IProductionOrderChangeLog } from '../core/interfaces/IProductionOrderChangeLog';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import Fields from '../../../core/utils/fields';
import { NzSafeAny } from 'ng-zorro-antd/core/types';


@Component({
  selector: 'app-process-form',
  templateUrl: './process-form.component.html',
  styleUrls: ['./process-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessFormComponent extends AddEditFormGeneric<IProductionOrder> implements OnInit {
  @ViewChild('eanField') eanField: ElementRef<HTMLInputElement>;
  @Output() statusChange: EventEmitter<ProductionOrderStatuses> = new EventEmitter<ProductionOrderStatuses>();
  @Input() set orderNumber(obj: { val: string }) {
    if (obj) {
      this._orderNumber = obj.val;
      this.loadForm();
    }
  }
  public _orderNumber: string;
  public productionOrder: IProductionOrder;
  public currentEan: string;
  public currentStatus: ProductionOrderStatuses;
  public statuses = ProductionOrderStatuses;
  public variants: IScanableItem<IProductionOrderVariant>[];
  public correctModalVisible = false;
  public insertModalVisible = false;
  public revisedVariant: IProductionOrderVariant;
  public newVariantValue: number;
  public changeLogs$: Observable<IProductionOrderChangeLog[]>;
  private _checkMhdModal: NzModalRef<unknown, NzSafeAny>;

  constructor(
    protected _fb: FormBuilder,
    protected _formService: ProductionOrdersService,
    protected _notifyService: NotificationService,
    protected _errorResolver: ProductionOrderServiceErrorResolverService,
    protected _cdr: ChangeDetectorRef,
    private _modal: NzModalService,
    private _viewContainerRef: ViewContainerRef
  ) {
    super(_fb, _formService, _notifyService, _errorResolver);
    this.form = this._fb.group({});
  }

  loadForm(): void {
    if (this._orderNumber) {
      this.loading = true;
      this.isEdit = true;
      this._formService.getById(this._orderNumber).subscribe(
        value => {
          this._editObject = value;
          this.variants = this._editObject.productionOrderVariants.map(value => ({...value, scanned: 0}));
          this.currentStatus = ProductionOrderStatuses[this._editObject.status];
          this.productionOrder = this._editObject;
          this.loading = false;
          if (this.eanField) {
            this.eanField.nativeElement.focus();
          }

          if (this.currentStatus === ProductionOrderStatuses.Filled) {
            this.checkMhd();
          }
          this.statusChange.emit(this.currentStatus);
          this._cdr.detectChanges();
        },
        error => {
          this._notifyService.pushError('Fehler', 'Barcode / ean ungültig.');
          this.clearForm();
          this.loading = false;
          this._cdr.detectChanges();
        }
      );
      this.changeLogRefresh();
    }
  }

  handleEanScan(val: string):  void {
    val = val.trim();
    if (val !== '') {
      const currentVariant = this.variants.find(variant => variant.articleVariant.ean === val);
      if(!currentVariant) {
        this._notifyService.pushError('Ean gescannt', 'Die eingescannte ean-Nummer wurde im Fertigungsauftrag nicht gefunden');
        this.correctScannedField(this.eanField, false);
        return;
      }
      this.correctScannedField(this.eanField, true);
      currentVariant.scanned++;
    }
  }
  submitForm(): void {
    this.isSubmitted = true;
    const invalidEans = (this.currentStatus === ProductionOrderStatuses.Created) ? this.validateAllScanned() : [];
    if (invalidEans.length > 0) {
      this._notifyService.pushError('Fehlgeschlagen',
        `Die folgenden Varianten wurden nicht korrekt gescannt:<br><strong>${invalidEans.join('<br>')}</strong>`)
      this.formFinished.emit(false);
      return;
    }

    let call: Observable<any> = null;
    switch (this.currentStatus) {
      case ProductionOrderStatuses.Created:
        call = this._formService.fill(this.productionOrder.id,
          this.variants.map(({articleVariantId, weight, scanned, id, recipeCardVariantId}) => ({quantity:scanned, articleVariantId, weight, id, recipeCardVariantId})));
        break;
      case ProductionOrderStatuses.MhdChecked:
        call = this._formService.label(this.productionOrder.id);
        break;
      case ProductionOrderStatuses.Labeled:
        call = this._formService.finish(this.productionOrder.id);
        break;
      default:
        this._notifyService.pushError('Fehlgeschlagen',
          'Sie können diese Aktion nicht für einen stornierten Auftrag durchführen');
        this.formFinished.emit(false);
        return;
    }

    if (call) {
      call.subscribe(() => {
        this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt erstellt`);

        if (this.currentStatus !== ProductionOrderStatuses.MhdChecked) {
          this.clearForm();
          this.formFinished.emit(true);
        }
        this.clearForm();
        this.formFinished.emit(true);
        this.loading = false;
      }, err => {
        this.parseError(err);
        this.formFinished.emit(false);
      });
    }

  }

  correctScannedField(el: ElementRef<HTMLInputElement>, success: boolean): void {
    if (success) {
      Fields.SetAsCorrect(el, () => {
        this.currentEan = "";
        this._cdr.detectChanges();
      });
    } else {
      Fields.SetAsWrong(el, () => {
        this.currentEan = "";
        this._cdr.detectChanges();
      });
    }
  }

  openCorrectModal(variant: IProductionOrderVariant) {
    this.revisedVariant = variant;
    this.newVariantValue = variant.quantity;
    this.correctModalVisible = true;
    this._cdr.detectChanges();
  }

  openInsertModal() {
    this.insertModalVisible = true;
    this._cdr.detectChanges();
  }

  validateAllScanned(): string[] {
     return this.variants.filter(variant => variant.scanned < variant.quantity && !variant.isCorrected)
       .map(value => `${value.articleVariant.name} - ${value.articleVariant.ean}`);
  }

  correctVariant(): void {
    const currentVariant = this.variants.find(variant => variant.articleVariant.ean === this.revisedVariant.articleVariant.ean);
    currentVariant.scanned = this.newVariantValue;
    currentVariant.isCorrected = true;
    this.correctModalVisible = false;
  }

  private checkMhd() {
    this.formFinished.emit(false);
    this._checkMhdModal = this._modal.create({
      nzTitle: 'MHD-Kontrolle',
      nzContent: "<p> Ist MHD korrekt? </p>",
      nzViewContainerRef: this._viewContainerRef,
      nzWidth: 300,
      nzCloseIcon: '',
      nzFooter: [
        {
          label: 'Nein',
          onClick: () => {
            this._checkMhdModal.destroy();
            this.formFinished.emit(true);
            this.clearForm();
          }
        },
        {
          label: 'Ja',
          type: 'primary',
          onClick: () => {
            this.loading = true;
            this._formService.mhdChecked(this.productionOrder.id).subscribe(() => {
              this.changeLogRefresh();
              this.currentStatus = ProductionOrderStatuses.MhdChecked;
              this._checkMhdModal.destroy();
              this._checkMhdModal = null;
              this.loadForm();
            }, err => {
              this.parseError(err);
              this.formFinished.emit(false);
            });
          }
        },
      ]
    });
  }

  private changeLogRefresh() {
    this.changeLogs$ = null;
    this.changeLogs$ = this._formService.getChangeLog(this._orderNumber);
  }

  private clearForm() {
    this._editObject = null;
    this.variants = [];
    this.currentStatus = null;
    this.productionOrder = null;
    this.loading = false;
    this.statusChange.emit();
    this.changeLogs$ = null;
    this._cdr.detectChanges();
  }
}
