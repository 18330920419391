import { Pipe, PipeTransform } from '@angular/core';
import { IProductionOrderVariant } from '../../pages/production-orders/core/interfaces/IProductionOrderVariant';

@Pipe({
  name: 'getVariantsWithoutLabel'
})
export class GetVariantsWithoutLabelPipe implements PipeTransform {

  public transform(val: IProductionOrderVariant[]): IProductionOrderVariant[] {
    return val.filter(x => !x.articleVariant.labelKey);
  }
}
