import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from '../../../../../core/services/error-resolvers/base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrderServiceErrorResolverService extends BaseServiceErrorResolverService {

  codes = {
    BulkContainerLackOfResources : 'Diese Lieferung ist nicht ausreichend'
  };
}
