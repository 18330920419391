import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2
} from '@angular/core';
import { NotificationService } from '../../../core/services';

import { ListComponentGeneric } from '../../../ui/generics';
import { StoresServiceErrorResolverService } from '../../stores/core/services/error-resolvers/stores-service-error-resolver.service';
import { IDocument } from '../../../core/interfaces';
import { DocumentService } from '../../../core/services/DocumentService';

@Component({
  selector: 'app-batches-list',
  templateUrl: './batches-list.component.html',
  styleUrls: ['./batches-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchesListComponent extends ListComponentGeneric<IDocument> implements OnInit {

  protected predefindedFilters: { key: string; value: string[] }[] = [
    {
      key: 'type',
      value: ['BatchOpenOrdersDocument'],
    }
  ];
  protected readonly exportedTableVisibleName = 'Batches list';

  constructor(
    protected service: DocumentService,
    protected cdr: ChangeDetectorRef,
    protected notify: NotificationService,
    protected renderer: Renderer2,
    protected errorResolver: StoresServiceErrorResolverService
  ) {
    super(service, cdr, notify, renderer, errorResolver);
  }

  callService(pageIndex: number,
              pageSize: number,
              sortField: string | null,
              sortOrder: string | null,
              filters: Array<{ key: string; value: string[] }>,
              search: Array<{ key: string; value: string[] }>,
              additionalFilters: Array<{ key: string; value: string[] }>,
  ) {
    this.loading = true;
    this.service.getBatchOpenOrdersDocuments(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters).subscribe(res => {
      this.loading = false;
      this.data = res;
      this._cdr.detectChanges();
    }, this.parseError);
  }
}
