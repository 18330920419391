export const titles = {

  // Welcome
  '': 'Dashboard',

  // Auth
  'auth': 'Auth',
  'auth-login': 'Login',
  'auth-logout': 'Logout',
  'auth-can-not-login': 'Can not login',
  'set-password': 'Set password',

  // Bulk container
  'bulk-containers': 'Großgebinde',
  'bulk-containers-orders': 'Bestellungen',
  'bulk-containers-bulk-container-articles': 'Großgebinde',
  'bulk-containers-bulk-container-non-packing': 'Non-packing',
  'bulk-containers-non-packing': 'Non-packing',

  // Production orders
  'production-orders': 'Produktionsaufträge',

  // Users
  'users': 'Mitarbeiter',

  // Suppliers
  'suppliers': 'Suppliers',

  // Tools
  'tools-link-shortener': 'Linkverkürzer',
  'tools-exports': 'Ausfuhren'
};
