import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export abstract class ModalGeneric implements OnInit, OnDestroy {
  public isVisible = false;
  public title = '';
  protected subscriptions: Subscription[] = [];

  protected constructor (
    protected readonly cdr: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {

  }

  public showModal(object = null): void {
    this.isVisible = true;
    this.cdr.markForCheck();
  }

  public closeModal(): void {
    this.isVisible = false;
    this.cdr.markForCheck();
  }

  public handleCancel(): void {
    this.closeModal();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
