import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { AddEditFormComponent } from './add-edit-form/add-edit-form.component';
import { AddEditModalComponent } from './add-edit-modal/add-edit-modal.component';
import { CoreModule } from '../../core/core.module';
import { UiModule } from '../../ui/ui.module';
import { StoresComponent } from './stores/stores.component';
import { StoresRoutingModule } from './stores-routing.module';
import { StoragePlaceFinderModalComponent } from './storage-place-finder-modal/storage-place-finder-modal.component';

@NgModule({
  declarations: [
    StoresComponent,
    ListComponent,
    AddEditFormComponent,
    AddEditModalComponent,
    StoragePlaceFinderModalComponent,
  ],
  exports: [
    StoragePlaceFinderModalComponent
  ],
  imports: [
    CommonModule,
    StoresRoutingModule,
    CoreModule,
    UiModule,
  ]
})
export class StoresModule { }
