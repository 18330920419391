import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { ITableResults } from '../../../../core/interfaces';
import { IStoreStocksListItem } from '../interfaces/IStoreStocksListItem';
import { Observable } from 'rxjs';
import { IStoreStock } from '../interfaces/IStoreStock';
import { IStoreStockCorrection } from '../interfaces/IStoreStockCorrection';
import { IStoreStocksCorrectionsListItem } from '../interfaces/IStoreStocksCorrectionsListItem';
import { GenericService } from '../../../../core/services/GenericService';
import { IStorageLocation } from '../../../stores/core/interfaces/IStorageLocation';
import { HttpUtils } from '../../../../core/utils/HttpUtils';

@Injectable({
  providedIn: 'root'
})
export class StoreStocksService<T> extends GenericService<T> {
  protected readonly baseUrl = '/StoreStocks';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  public getAllStoreStocks(): Observable<ITableResults<IStoreStocksListItem>> {
    return this.apiService.get(`${this.baseUrl}/stocks`);
  }

  public getLocationByEan(ean: string): Observable<IStorageLocation> {
    return this.apiService.get(`${ this.baseUrl }/locations/ean/${ ean }`);
  }

  public getProductDetailsByLocation(location: string): Observable<IStorageLocation[]> {
    return this.apiService.get(`${ this.baseUrl }/locations/location/${ location }`);
  }

  public getStocksByArticleId(articleId: number): Observable<IStoreStock> {
    return this.apiService.get(`${this.baseUrl}/stocks/${articleId}`);
  }

  public storeStockCorrection(correctionObj: IStoreStockCorrection) {
    return this.apiService.post(`${this.baseUrl}/correction`, correctionObj);
  }

  public getCorrections(pageIndex: number,
                         pageSize: number,
                         sortField: string | null,
                         sortOrder: string | null,
                         filters: Array<{ key: string; value: string[] }>,
                         search: Array<{ key: string; value: string[] }>,
                        additionalFilters: Array<{ key: string; value: string[] }>,
  ): Observable<ITableResults<IStoreStocksCorrectionsListItem>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters);
    return this.apiService.get(`${this.baseUrl}/corrections`, params );
  }

  getById(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }

  delete(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }

  update(object: T, id: number): Observable<T> {
    throw new Error('Method not implemented.');
  }

  create(object: T): Observable<T> {
    throw new Error('Method not implemented.');
  }

}
