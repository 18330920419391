import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AddEditModalGeneric } from '../../../ui/generics';
import { IUser } from '../core/interfaces/IUser';
import { EditMyProfileFormComponent } from '../edit-my-profile-form/edit-my-profile-form.component';
import { D_GLOBAL_MODAL, IGlobalModal } from '../../../core/directives';

@Component({
  selector: 'app-edit-my-profile-modal',
  templateUrl: './edit-my-profile-modal.component.html',
  styleUrls: ['./edit-my-profile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: EditMyProfileModalComponent }]
})
export class EditMyProfileModalComponent extends AddEditModalGeneric<IUser, EditMyProfileFormComponent> implements OnInit, OnDestroy, IGlobalModal {

  constructor(protected cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  rebuildTitle(): void {
    this.title = 'Mein Profil bearbeiten';
  }

}
