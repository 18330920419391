import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkContainersComponent } from './bulk-containers/bulk-containers.component';
import { BulkContainersRoutingModule } from './bulk-containers-routing.module';
import { CoreModule } from '../../core/core.module';
import { UiModule } from '../../ui/ui.module';
import { OrdersComponent } from './orders/orders.component';
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { ArticlesListComponent } from './bulk-containers/articles-list/articles-list.component';
import { AddEditOrderFormComponent } from './orders/add-edit-order-form/add-edit-order-form.component';
import { AddEditOrderModalComponent } from './orders/add-edit-order-modal/add-edit-order-modal.component';
import { SingleBulkContainerComponent } from './bulk-containers/single-bulk-container/single-bulk-container.component';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { DeliveriesListComponent } from './bulk-containers/single-bulk-container/deliveries-list/deliveries-list.component';
import { ChangeLogListComponent } from './bulk-containers/single-bulk-container/change-log-list/change-log-list.component';
import { CorrectQuantityModalComponent } from './bulk-containers/single-bulk-container/correct-quantity-modal/correct-quantity-modal.component';
import { EditFormComponent } from './bulk-containers/single-bulk-container/edit-form/edit-form.component';
import { EditModalComponent } from './bulk-containers/single-bulk-container/edit-modal/edit-modal.component';
import { ProductionOrdersModule } from '../production-orders/production-orders.module';
import { NonPackingComponent } from './non-packing/non-packing.component';
import { NonPackingListComponent } from './non-packing/non-packing-list/non-packing-list.component';
import { SingleNonPackingComponent } from './non-packing/single-non-packing/single-non-packing.component';
import { NonPackChangeLogListComponent } from './non-packing/single-non-packing/nonpack-change-log-list/nonpack-change-log-list.component';
import { StorestocksModule } from '../stores-stocks/storestocks.module';
import { OwnProductionsListComponent } from './bulk-containers/single-bulk-container/own-productions-list/own-productions-list.component';
import { NewDeliveryModalComponent } from './bulk-containers/single-bulk-container/new-delivery-modal/new-delivery-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { CommentsListComponent } from './bulk-containers/single-bulk-container/comments-list/comments-list.component';

@NgModule({
    declarations: [
      BulkContainersComponent,
      ArticlesListComponent,
      OrdersListComponent,
      OrdersComponent,
      AddEditOrderModalComponent,
      AddEditOrderFormComponent,
      EditFormComponent,
      EditModalComponent,
      SingleBulkContainerComponent,
      DeliveriesListComponent,
      ChangeLogListComponent,
      CorrectQuantityModalComponent,
      NonPackingComponent,
      NonPackingListComponent,
      SingleNonPackingComponent,
      NonPackChangeLogListComponent,
      OwnProductionsListComponent,
      NewDeliveryModalComponent,
      CommentsListComponent,
    ],
  imports: [
    CommonModule,
    BulkContainersRoutingModule,
    CoreModule,
    UiModule,
    NzStatisticModule,
    NzTabsModule,
    ProductionOrdersModule,
    StorestocksModule,
    SharedModule,
    NzUploadModule,
  ],
    exports: [
        AddEditOrderModalComponent
    ]
})
export class BulkContainersModule { }
