import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToArray'
})
export class MapToArrayPipe implements PipeTransform {

  public transform(map: Map<any, any>): any[] {
    return Array.from(map).map(x => x[1]);
  }
}
