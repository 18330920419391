import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from './base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class ToolsErrorResolverService extends BaseServiceErrorResolverService {

  constructor() {
    super();
  }
}
