import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditArticleModalComponent } from './modals/add-edit-article-modal/add-edit-article-modal.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { AddEditArticleFormComponent } from './forms/add-edit-article-form/add-edit-article-form.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../ui/ui.module';
import { CoreModule } from '../core/core.module';
import { StoreStocksCorrectionsModalComponent } from './modals/store-stocks-corrections-modal/store-stocks-corrections-modal.component';
import { StoreStocksCorrectionsListComponent } from './lists/store-stocks-corrections-list/store-stocks-corrections-list.component';



@NgModule({
  declarations: [
    AddEditArticleModalComponent,
    AddEditArticleFormComponent,
    StoreStocksCorrectionsModalComponent,
    StoreStocksCorrectionsListComponent
  ],
  exports: [
    AddEditArticleModalComponent,
    StoreStocksCorrectionsModalComponent
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzIconModule,
    NzModalModule,
    NzWaveModule,
    NzCheckboxModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzListModule,
    NzSelectModule,
    NzSpinModule,
    NzTableModule,
    NzTabsModule,
    ReactiveFormsModule,
    UiModule,
    CoreModule
  ]
})
export class SharedModule { }
