import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NotificationService } from '../../../core/services';
import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { FlourErrorResolverService, FlourService } from '../../bulk-containers/core/services';
import { IFlourPackingListArticle, IFlourPackingListItem } from '../../flour/core/interfaces';
import { FlourStockTypes, FlourStockTypesTranslations } from '../../flour/core/enums';
import { IDeliveryNoteArticle } from '../../bulk-containers/core/interfaces/IDeliveryNoteArticle';
import { DocumentService } from '../../../core/services/DocumentService';
import Fields from '../../../core/utils/fields';
import { StockTransferWindows } from '../core/enums';

@Component({
  selector: 'app-stock-transfer-process-form',
  templateUrl: './stock-transfer-process-form.component.html',
  styleUrls: ['./stock-transfer-process-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockTransferProcessFormComponent implements OnInit {


  @ViewChild('eanField') eanField: ElementRef<HTMLInputElement>;

  @Input() windowType: StockTransferWindows;
  @Input() set packingListDocumentNumber(obj: { val: string }) {
    if (obj && obj.val !== '') {
      this.packingListItem = null;
      this.resetScannedArticles();
      this._packingListDocumentNumber = obj.val;
      this.loadPackingListDocument();
    }
  }

  @Output() clearScanField = new EventEmitter<void>();

  public currentEan: string;
  public loading = false;
  public packingListItem: IFlourPackingListItem;
  public scannedArticles: IFlourPackingListArticle[] = [];
  public currentVisibleArticleIndex: number = null;
  public articleQuantity = 0;
  public readonly flourStockTypes = FlourStockTypes;
  public readonly flourStockTypesTranslations = FlourStockTypesTranslations;
  public readonly scannedEanChanged$ = new Subject<string>();
  public isOutgoingDocChanging = false;
  public isVisibleModalError: boolean = false;
  public deliveryResponse: IFlourPackingListItem = null;

  private _packingListDocumentNumber: string;
  private _scannedEans: string[] = [];
  private _deletedArticles: IDeliveryNoteArticle[] = [];

  constructor(
    private readonly _flourService: FlourService<IFlourPackingListItem>,
    private readonly _notifyService: NotificationService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _flourErrorResolverService: FlourErrorResolverService,
    private readonly _documentService: DocumentService,
  ) {}

  public ngOnInit(): void {
    this.scannedEanChanged$.pipe(debounceTime(1000)).subscribe(ean => this.eanScanned(ean));
  }

  public eanScanned(ean: string) {
    if (this.packingListItem.isMainDoc && this.currentScannedArticle) {
      if (this.currentScannedArticle.delivery === null) {
        this._notifyService.pushError(
          'Fehler',
          'Artikelmenge ist nicht eingestellt, bitte korrigieren Sie den Wert und speichern Sie.'
        );
        this.clearEanScanField();
        return;
      }
    }

    if (ean !== '' && ean.length > 10) {
      if (this._scannedEans.includes(ean)) {
        this.setCurrentVisibleArticle(this.scannedArticles.findIndex(x => x.ean === ean));
        if (this.packingListItem.isMainDoc) {
          this._notifyService.pushWarning(
            'Achtung',
            'Beachten Sie, dass Sie keinen Artikel hinzufügen können, der nicht im ausgehenden Dokument enthalten war.'
          );
          Fields.PlayWrongScanSound();
        } else {
          this.isOutgoingDocChanging = true;
          this._cdr.detectChanges();
        }
      } else {
        this.startLoading();
        this._flourService.getPackingListArticleByEan(parseInt(this._packingListDocumentNumber, 10), ean).pipe(
          finalize(this.stopLoading.bind(this))
        ).subscribe(res => {
          if (this.packingListItem.isOutgoingDoc && res.id === -1) {
            this._notifyService.pushError(
              'Achtung',
              'Artikle er allerede scannet.'
            );
            Fields.PlayWrongScanSound();
          } else {
            this.scannedArticles = [...this.scannedArticles, res];
            this._scannedEans = [...this._scannedEans, ean];
            this.setCurrentVisibleArticle(this.scannedArticles.length - 1);
          }

        }, error => {
          this._notifyService.pushError('Fehlgeschlagen', 'Fehler beim Laden des Artikels.');
          this.clearEanScanField();
        });
      }
    } else {
      this.currentEan = '';
      this.eanField.nativeElement.focus();
      this._cdr.detectChanges();
    }
  }

  public backToPrevious(): void {
    this._scannedEans.pop();
    this.scannedArticles.pop();
    this.scannedArticles = [...this.scannedArticles];
    this._scannedEans = [...this._scannedEans];
    this.loadPreviousArticle();
  }

  public loadPreviousArticle(): void {
    this.setCurrentVisibleArticle(this.currentVisibleArticleIndex - 1);
  }

  public saveArticleQuantity(): void {
    if (this.packingListItem.isMainDoc || this.packingListItem.isOutgoingDoc) {
      this.currentScannedArticle.delivery = this.articleQuantity;
      this.eanField.nativeElement.focus();
    }
    this.isOutgoingDocChanging = false;
    this._cdr.detectChanges();
  }

  public get currentScannedArticle(): IFlourPackingListArticle {
    return this.scannedArticles[this.currentVisibleArticleIndex];
  }

  public deleteArticle(): void {
    const { id, delivery: quantity, ean } = this.currentScannedArticle;

    if (quantity !== null) {
      this._deletedArticles.push({
        id,
        quantity,
        ean,
        isDeleted: true,
        isNew: false
      });
    }

    this._scannedEans = this._scannedEans.filter(x => x !== this.currentScannedArticle.ean);
    this.scannedArticles = this.scannedArticles.filter(x => x.ean !== this.currentScannedArticle.ean);
    this.articleQuantity = null;
    this.loadPreviousArticle();
    this.eanField.nativeElement.focus();
  }

  public generateDeliveryNote(): void {
    const deliveryNoteArticles = [
      ...this._deletedArticles,
      ...this.scannedArticles.filter(x =>
        x.delivery !== null
      ).map((x) => {
        const { id, delivery: quantity, ean } = x;
        return {
          id,
          quantity,
          ean,
          isDeleted: false,
          isNew: id === -1
        } as IDeliveryNoteArticle;
      })
    ];

    this.startLoading();
    this._flourService.generateDeliveryNote(
      parseInt(this._packingListDocumentNumber, 10),
      deliveryNoteArticles
    ).pipe(
      finalize(this.stopLoading.bind(this))
    ).subscribe(res => {
      this.resetScannedArticles();
      this.clearScanField.emit();
      if (res.hasErrors) {
        this.isVisibleModalError = true;
        this.deliveryResponse = res;
        this._cdr.detectChanges();
      }
      window.open(this._documentService.getDownloadUrl(res.documentKey), '_blank');
    }, error => {
      this._notifyService.pushError(
        'Fehlgeschlagen',
        this._flourErrorResolverService.getMessage((error && error.InternalCode) ? error.InternalCode : '')
      );
    });
  }

  handleErrorModalClose() {
    this.isVisibleModalError = false;
    this.deliveryResponse = null;
    this._cdr.detectChanges();
  }

  private loadPackingListDocument(): void {
    this.startLoading();
    this._flourService.getPackingListById(parseInt(this._packingListDocumentNumber, 10)).pipe(
      finalize(this.stopLoading.bind(this))
    ).subscribe(packingList => {
      if (packingList.isIncomingDoc) {
        this.clearScanField.emit();
        this._notifyService.pushError('Fehlgeschlagen', 'Eingehende Faxe können nicht gescannt werden.');
        return;
      }
      if ((packingList.isMainDoc && this.windowType === StockTransferWindows.INCOMING) ||
        (packingList.isOutgoingDoc  && this.windowType === StockTransferWindows.OUTGOING)
      ) {
        this.clearScanField.emit();

        this._notifyService.pushError('Fehler', 'Sie können diese Art von Dokument hier einscannen');
        return;
      }

      this.packingListItem = packingList;
      if (packingList.isOutgoingDoc) {
        this._scannedEans = packingList.packingListDetails.map(detail => detail.ean);
        this.scannedArticles = packingList.packingListDetails;
      }
      setTimeout(this.clearEanScanField.bind(this));

    }, error => {
      this.clearScanField.emit();
      this._notifyService.pushError(
        'Fehlgeschlagen',
        this._flourErrorResolverService.getMessage((error && error.InternalCode) ? error.InternalCode : '')
      );
    });
  }

  private startLoading(): void {
    this.loading = true;
    this._cdr.detectChanges();
  }

  private stopLoading(): void {
    this.loading = false;
    this._cdr.detectChanges();
  }

  private clearEanScanField(): void {
    this.eanField.nativeElement.value = '';
    this.eanField.nativeElement.focus();
  }

  private setCurrentVisibleArticle(index: number): void {
    if (index < 0 ) {
      this.currentVisibleArticleIndex = null;
      this.articleQuantity = 0;
    } else {
      this.currentVisibleArticleIndex = index;
      this.articleQuantity = this.currentScannedArticle.delivery;
    }

    setTimeout(this.clearEanScanField.bind(this));
    this._cdr.detectChanges();
  }

  private resetScannedArticles(): void {
    this.currentVisibleArticleIndex = null;
    this.scannedArticles = [];
    this._deletedArticles = [];
    this._scannedEans = [];
  }

  eanScan(val: any): void {
    if ( /\r|\n/.exec(val)) {
      val = val.trim();
      this.scannedEanChanged$.next(val);
    }
  }

}
