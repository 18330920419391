import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService} from '../services';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class HttpApiVersionInterceptor implements HttpInterceptor {

  constructor(private _apiService: ApiService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // @ts-ignore
    return next.handle(request).pipe(
      catchError(data => {
        if (data instanceof HttpErrorResponse) {
          if (data.headers.has('x-response-api-version') && data.headers.has('x-response-api-version')) {
            this._apiService.setVersion(data.headers.get('x-response-api-version'));
          }
        }
        return observableThrowError(data);
      }),
      tap(data => {
        if (data instanceof HttpResponse) {
          if (data.headers.has('x-response-api-version') && data.headers.has('x-response-api-version')) {
            this._apiService.setVersion(data.headers.get('x-response-api-version'));
          }
        }
      }));
  }
}
