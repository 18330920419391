import { Injectable } from '@angular/core';
import { GenericService } from '../../../../core/services/GenericService';
import { ApiService } from '../../../../core/services';
import { Observable } from 'rxjs';
import { ITableResults } from '../../../../core/interfaces';
import { ExportTypesEnum } from '../../../tools/core/enums/ExportTypesEnum';

@Injectable({
  providedIn: 'root'
})
export class ReportsService<T> extends GenericService<T> {
  public baseUrl = '/reports';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  public generateSalesReport(exportType: ExportTypesEnum, operationId: string): Observable<any> {
    const body = {
      exportType,
      operationId
    };

    return this.apiService.post(`${ this.baseUrl }/generate-sale-report`, body);
  }

  getQueryableAll(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, filters: Array<{ key: string; value: string[] }>, search: Array<{ key: string; value: string[] }>): Observable<ITableResults<T>> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }

  delete(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }

  update(object: T, id: number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  create(object: T): Observable<any> {
    throw new Error('Method not implemented.');
  }
}

