import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderComponent implements OnInit {

  @Input() loading = false;
  @Input() withExportOption = false;

  @Output() reload = new EventEmitter<void>();
  @Output() exportBtnClicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
