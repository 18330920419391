export enum ExportTableTypes {
  Article = 'Article',
  BulkContainer = 'BulkContainer',
  StoreStocks = 'StoreStocks',
  Order = 'Order',
  OwnProduction = 'OwnProduction',
  ProductionOrder = 'ProductionOrder',
  DicStore = 'DicStore',
  Supplier = 'Supplier',
  User = 'User',
  Eans = 'Eans',
  PlanningProduction = 'PlanningProduction',
  PackingLists = 'PackingLists',
  StockTransfer = 'StockTransfer',
  ControllingStocks = 'ControllingStocks'
}
