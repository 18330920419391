import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef, Input
} from '@angular/core';
import { EditFormComponent } from '../edit-form/edit-form.component';
import { AddEditModalGeneric } from '../../../../../ui/generics';
import { IBulkContainer } from '../../../core/interfaces/IBulkContainer';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditModalComponent extends AddEditModalGeneric<IBulkContainer, EditFormComponent> implements OnInit {

  @Input() isVariant: boolean;

  constructor(
    protected cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  rebuildTitle(): void {
    this.title = `Bearbeiten Großgebinde: #${this.object.id}`;
  }
}
