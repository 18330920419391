<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
></app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th nzColumnKey="createdAt" [nzSortFn]="true">Datum</th>
    <th nzColumnKey="comment" [nzSortFn]="true">Kommentar</th>
    <th nzColumnKey="user.fullName" [nzSortFn]="true">Ersteller</th>
    <th nzRight></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let val of data.results">
    <td>
      {{ val.createdAt | datetimeFormat }}
    </td>
    <td>
      <ng-container *ngIf="!val.isEdited">
        {{ val.comment }}
      </ng-container>
      <textarea nz-input [(ngModel)]="val.comment" *ngIf="val.isEdited"></textarea>
    </td>
    <td>
      {{ val.user.fullName }}
    </td>
    <td nzRight>
      <div class="actions">
        <span (click)="updateComment(val)">
          <i nz-icon [nzType]="!val.isEdited ? 'edit' : 'check'" nzTheme="outline"></i>
        </span>
        <span *ngIf="val.isEdited" (click)="val.isEdited = false">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </span>
        <span>
          <i
            nz-icon
            nzType="delete"
            nz-popconfirm
            nzPopconfirmTitle="Sind Sie sicher?"
            nzTheme="outline"
            (nzOnConfirm)="deleteComment(val.id)"
          ></i>
        </span>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
