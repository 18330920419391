import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { IUser } from '../core/interfaces/IUser';
import { AddEditFormComponent } from '../add-edit-form/add-edit-form.component';
import { AddEditModalGeneric } from '../../../ui/generics';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditModalComponent extends AddEditModalGeneric<IUser, AddEditFormComponent> implements OnInit {

  constructor(
    protected cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  rebuildTitle(): void {
    this.title = this.isNew ? 'Create new user' : `Edit user: ${this.object.firstName} ${this.object.lastName}`;
  }

  handleResetPass(): void {
    this.form.resetPassword();
    this.cdr.markForCheck();
  }

  regenerateBatch() {
    this.form.regenerateBatch();
    this.cdr.markForCheck();
  }
}
