import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ModalGeneric } from '../../../ui/generics';
import { D_GLOBAL_MODAL } from '../../../core/directives';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IdentityService, JwtService } from '../../../core/services/auth';
import { MFAFormat } from '../../../core/validators';
import { NotificationService } from '../../../core/services';

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: TwoFactorAuthenticationComponent }]

})
export class TwoFactorAuthenticationComponent extends ModalGeneric implements OnInit {

  @Output() currentStatusChanged = new EventEmitter<boolean>();

  public currentStatus: boolean;
  public form: FormGroup;
  public manualCode: string;
  public qrCodeUrl: string;
  public title = 'Einstellungen Multi-Faktor-Authentifizierung';
  public isSubmitted: ValidationErrors;
  public isSubmitting: boolean;

  constructor(protected readonly cdr: ChangeDetectorRef,
              protected jwtService: JwtService,
              protected _identityService: IdentityService,
              private fb: FormBuilder,
              protected _notifyService: NotificationService,
  ) {
    super(cdr);
    this.form = this.fb.group({
      userId: ['', Validators.required],
      verificationCode: ['', Validators.compose([
        Validators.required,
        MFAFormat
      ])],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get fc() {
    return this.form.controls;
  }

  public showModal(userId: number, previous2FAuthState = false): void {
    this.currentStatus = previous2FAuthState;
    this.form.controls.userId.patchValue(userId);
    super.showModal();
  }

  afterModalOpen() {
    if (!this.currentStatus) {
      this._identityService.enable2FA().subscribe(any => {
        this.qrCodeUrl = any.qrCodeUrl;
        this.manualCode = any.manualCode;
        this.cdr.detectChanges();
      });
    }

  }

  submit() {
    this.isSubmitting = true;
    // stop here if form is invalid
    this.form.controls.verificationCode.markAsDirty();
    this.form.controls.verificationCode.updateValueAndValidity();

    if (this.form.invalid) {
      this.isSubmitting = false;
      return;
    }

    const data = this.form.value;
    if (!this.currentStatus) {
      this._identityService
        .confirm2FA(data)
        .subscribe(
          () => {
            this.currentStatusChanged.emit(true);
            this._notifyService.pushSuccess('Erfolgreichr', 'Die Multi-Faktor-Authentifizierung wurde erfolgreich aktiviert');
            this.closeModal();
            this.isSubmitting = false;
          },
          () => {
            this._notifyService.pushError('Fehlermeldung', `Verifizierungsfehler, versuchen Sie es erneut`);
            this.isSubmitting = false;
          }
        );
    } else {
      this._identityService
        .disable2FA(data)
        .subscribe(
          () => {
            this._notifyService.pushSuccess('Erfolgreich', 'Die Multi-Faktor-Authentifizierung wurde erfolgreich deaktivieren');
            this.closeModal();
            this.currentStatusChanged.emit(false);
            this.isSubmitting = false;
          },
          () => {
            this._notifyService.pushError('Fehlermeldung', `Verifizierungsfehler, versuchen Sie es erneut`);
            this.isSubmitting = false;
          }
        );
    }
  }
}
