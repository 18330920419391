import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ModalGeneric } from '../../generics';
import { D_GLOBAL_MODAL } from '../../../core/directives';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: SearchModalComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchModalComponent extends ModalGeneric implements OnInit {

  @Output() searchChanged = new EventEmitter<string>();

  public title = 'Scan barcode / ean';

  constructor(
    protected readonly cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public valueChanged(val: string): void {
    this.searchChanged.emit(val);
  }
}
