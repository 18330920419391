import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2,
  HostListener,
  ViewChildren, QueryList, ElementRef
} from '@angular/core';
import { AddEditFormGeneric } from '../../../ui/generics';
import { IStockTransfer } from '../core/interfaces/IStockTransfer';
import { FormBuilder, Validators } from '@angular/forms';
import { OrdersServiceErrorResolverService } from '../../bulk-containers/core/services';
import { NotificationService } from '../../../core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { StoresService } from '../../stores/core/services/StoresService';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { UsersService } from '../../users/core/services/UsersService';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { StockTransfersService } from '../core/services/StockTransfersService';
import { StockTransferStatusTranslationsTypes } from '../core/enums';
import { IStockTransferArticleVariant } from '../core/interfaces/IStockTransferArticleVariant';
import * as cloneDeep from 'lodash/cloneDeep';
import { disableDatesBeforeNow } from '../../../core/configs';

@Component({
  selector: 'app-add-edit-form',
  templateUrl: './add-edit-form.component.html',
  styleUrls: ['./add-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditFormComponent extends AddEditFormGeneric<IStockTransfer> implements OnInit {

  @ViewChildren('eanInputRef') eanInputsQueryList: QueryList<ElementRef<HTMLInputElement>>;

  public storesList$: Observable<ISimpleListItem[]>;
  public storesList: ISimpleListItem[] = [];
  public targetStoresList: ISimpleListItem[] = [];
  public usersList$: Observable<ISimpleListItem[]>;
  public editedRowId: number;
  public readonly statusTypes = StockTransferStatusTranslationsTypes;
  public stockTransferArticleVariants: IStockTransferArticleVariant[] = [];
  public article: string;
  public readonly disableDatesBeforeNow = disableDatesBeforeNow;

  private _stockTransferArticleVariantsClone: IStockTransferArticleVariant[] = [];
  private _stockTransfer: IStockTransfer;

  constructor(
    protected _fb: FormBuilder,
    protected _storeService: StoresService,
    protected _usersService: UsersService,
    protected _service: StockTransfersService,
    protected _notifyService: NotificationService,
    protected _errorResolver: OrdersServiceErrorResolverService,
    private _modal: NzModalService,
    private _cdr: ChangeDetectorRef,
    private _renderer: Renderer2
  ) {
    super(_fb, _service, _notifyService, _errorResolver);
    this.form = this._fb.group({
      originStoreId: ['', Validators.required],
      targetStoreId: ['', Validators.required],
      worker: ['', Validators.required],
      deliveryDate: ['', Validators.required],
      isPriority: [false],
      status: [this.statusTypes.Open, Validators.required],
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.form.get('status').disable();
    this.storesList$ = this._storeService.getSimple().pipe(
      tap(stores => {
        this.storesList = stores;
        this.targetStoresList = [...this.storesList];
      })
    );

    this.usersList$ = this._usersService.getSimpleList();
  }

  @HostListener('window:click', ['$event.target'])
  public onClickAnywhere(target: HTMLElement): void {
    const fId = this._renderer.selectRootElement('.cdk-global-overlay-wrapper > div', true).getAttribute('id');
    if (
      target.closest('.editable-row') === null &&
      target.closest('#add-product-row') === null &&
      target.closest('.cdk-overlay-pane')?.getAttribute('id') === fId
    ) {
      this.editedRowId = null;
      this._cdr.detectChanges();
    }
  }

  public startEdit(i: number): void {
    if (!this.editObject && this.form.get('status').value === this.statusTypes.Open) {
      this.editedRowId = i;
    }
  }

  public addVariant(): void {
    this.stockTransferArticleVariants = [
      ...this.stockTransferArticleVariants,
      {
        isNew: true
      }
    ];

    this.editedRowId = this.stockTransferArticleVariants.length - 1;
    setTimeout(() => Array.from(this.eanInputsQueryList)[this.editedRowId].nativeElement.focus());
    this._cdr.detectChanges();
  }

  public originStoreChanged(): void {
    this.targetStoresList = this.storesList.filter(store => store.id !== this.form.get('originStoreId').value);

    if (this.form.get('originStoreId').value === this.form.get('targetStoreId').value) {
      this.form.get('targetStoreId').patchValue(null);
    }

    this.reloadArticles();
  }

  public quantityChanged(inputElement: HTMLInputElement): void {
    let value = inputElement.value;
    const editedRow = this.stockTransferArticleVariants[this.editedRowId];
    const editedRowClone = this._stockTransferArticleVariantsClone[this.editedRowId];
    const oldOriginStockValue = editedRowClone.originInStock;
    const oldTargetStockValue = editedRowClone.targetInStock;
    const maxQuantity = (oldOriginStockValue + editedRowClone.quantity);
    inputElement.max = maxQuantity.toString();

    if (parseInt(value, 10) > maxQuantity) {
      value = (oldOriginStockValue + editedRowClone.quantity).toString();
    } else if (value === '' || parseInt(value, 10) <= 0) {
      value = '0';
    }

    editedRow.quantity = parseInt(value, 10);
    editedRow.originInStock = maxQuantity - parseInt(value, 10);
    editedRow.targetInStock = parseInt(oldTargetStockValue.toString(), 10) + ((parseInt(value, 10) - editedRowClone.quantity));
  }

  public reloadArticles(): void {
    this.stockTransferArticleVariants.forEach((x, index) => this.eanChanged(x.ean, index));
  }

  public removeRow(i: number): void {
    const removeVariant = () => this.stockTransferArticleVariants.filter((el, index) => index !== i);

    if (this.stockTransferArticleVariants[i].isNew) {
      this.stockTransferArticleVariants = removeVariant();
    } else {
      const stockTransferVariant = this.stockTransferArticleVariants[i];
      this.loading = true;
      this._service.removeArticle(this.editObject.id, stockTransferVariant.articleVariantId).pipe(
        finalize(() => {
          this.loading = false;
          this._cdr.detectChanges();
        })
      ).subscribe(() => {
        this.stockTransferArticleVariants = removeVariant();
      }, this.parseError.bind(this));
    }
  }

  public saveEdit(): void {
    this.stockTransferValuesActions(() => {
      this._formService.update(this._stockTransfer, this.editObject['id']).subscribe(() => {
        this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt aktualisiert.`);
        this.formFinished.emit(true);
      }, err => {
        this.parseError(err);
        this.formFinished.emit(false);
      });
    });
  }

  public saveNew(): void {
    this.stockTransferValuesActions(() => {
      this._formService.create(this._stockTransfer).subscribe(() => {
        this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt erstellt`);
        this.formFinished.emit(true);
      }, err => {
        this.parseError(err);
        this.formFinished.emit(false);
      });
    });
  }

  public eanChanged(ean: string, rowIndex: number): void {
    ean = ean.trim();

    if (ean) {
      if (this.form.get('originStoreId').value && this.form.get('targetStoreId').value) {
        this.loading = true;

        this._service.getArticleVariantByEanAndOriginStoreIdAndTargetStoreId(
          ean,
          this.form.get('originStoreId').value,
          this.form.get('targetStoreId').value
        ).pipe(
          finalize(() => {
            this.loading = false;
            this._cdr.detectChanges();
          })
        ).subscribe(article => {
          this.stockTransferArticleVariants[rowIndex] = {
            ...this.stockTransferArticleVariants[rowIndex],
            ...article
          };

          this.stockTransferArticleVariants = [...this.stockTransferArticleVariants];
          this._stockTransferArticleVariantsClone = cloneDeep(this.stockTransferArticleVariants);
          this._cdr.detectChanges();
        }, error => this._notifyService.pushError('Fehler', 'Artikelvariante für angegebene EAN nicht gefunden.'));
      }
    } else {
      this.stockTransferArticleVariants[rowIndex] = {
        isNew: true
      };

      this._cdr.detectChanges();
    }
  }

  protected afterLoadForm(): void {
    super.afterLoadForm();
    if (this.editObject) {
      this.editObject.stockTransferArticleVariants.forEach(el => {
        el.targetInStock += el.quantity;
        el.originInStock -= el.quantity;
      });
      this.stockTransferArticleVariants = this.editObject.stockTransferArticleVariants;
      this._stockTransferArticleVariantsClone = cloneDeep(this.stockTransferArticleVariants);
      this.form.get('status').patchValue(this.editObject.status);
      this.form.get('originStoreId').patchValue(this.editObject.originStore.id.toString());
      this.form.get('targetStoreId').patchValue(this.editObject.targetStore.id.toString());
      this.form.get('worker').patchValue(this.editObject.worker.id.toString());
      this.form.disable();
    }
  }

  private stockTransferValuesActions(cb: () => void): void {
    const stockTransfer = {
      ...this.form.value,
      workerId: parseInt(this.form.get('worker').value, 10),
      targetStoreId: parseInt(this.form.get('targetStoreId').value, 10),
      originStoreId: parseInt(this.form.get('originStoreId').value, 10),
      stockTransferId: this.editObject ? this.editObject.id : null,
      stockTransferArticleVariants: this.stockTransferArticleVariants.map(variant => {
        return {
          ...variant,
          quantity: variant.quantity
        };
      })
    };

    delete stockTransfer.worker;

    this._stockTransfer = stockTransfer;

    if (
      this._stockTransfer.stockTransferArticleVariants.length > 0 &&
      this._stockTransfer.stockTransferArticleVariants.every(el => el.ean)
    ) {
      cb();
    } else {
      this._notifyService.pushError('Fehler', 'Sie müssen mindestens eine Artikelvariante hinzufügen.');
      this.formFinished.emit(false);
    }
  }
}
