import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ModalGeneric } from '../../generics';
import { IExportTable } from '../../../pages/bulk-containers/core/interfaces/IExportTable';
import { DocumentTypes } from '../../../core/enums';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ExportService, JwtService, NotificationService } from '../../../core/services';
import { ToolsService } from '../../../core/services/ToolsService';
import { ToolsErrorResolverService } from '../../../core/services/error-resolvers';

@Component({
  selector: 'app-export-table-modal',
  templateUrl: './export-table-modal.component.html',
  styleUrls: ['./export-table-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportTableModalComponent extends ModalGeneric implements OnInit {

  public title = 'Export';
  public readonly documentTypes = DocumentTypes;
  public fields: NzSelectOptionInterface[];
  public form: FormGroup;

  private readonly _formFields = {
    'selectedFields': [[]],
    'targetType': [this.documentTypes.Csv],
    'withTableParams': [false]
  };
  private _tableDetails: IExportTable;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly _formBuilder: FormBuilder,
    private readonly _jwtService: JwtService,
    private readonly _toolsService: ToolsService,
    private readonly _exportService: ExportService,
    private readonly _notificationService: NotificationService,
    private readonly _errorResolverService: ToolsErrorResolverService,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
  }

  private initForm(): void {
    this.form = this._formBuilder.group(this._formFields);
    this.form.get('selectedFields').patchValue(this.fields.map(({ value }) => value));
  }

  public showModal(tableDetails: IExportTable): void {
    this._tableDetails = tableDetails;
    this.fields = tableDetails.fields;
    this.initForm();
    super.showModal();
  }

  public exportTable(): void {
    const { selectedFields, withTableParams, targetType } = this.form.value;
    const { exportTableName, sortField, sortOrder, filter, search, visibleName, additionalFilters } = this._tableDetails;
    const tableDetails: IExportTable = {
      visibleName,
      exportTableName,
      selectedFields: this.fields.filter(({ value }) => selectedFields.includes(value)).map((field) => {
        return {
          field: field.value,
          label: field.label
        } as { label: string; field: string; };
      }),
      operationId: Guid.create().toString(),
      userId: this._jwtService.getIdentity().claims.userID,
      targetType,
      properties: {}
    };

    if (withTableParams) {
      tableDetails.properties = {
        sortField,
        sortOrder: sortOrder ? (sortOrder[0].toUpperCase() + sortOrder.slice(1)) : null,
        searches: search.join(','),
        filters: filter.map(x => `${ x.operator ? `${ x.operator }:` : '' }${ x.key }:${ x.value[0] }`).join(','),
      };
    }

    if (additionalFilters) {
      additionalFilters.forEach(x => tableDetails.properties[x.key] = x.value[0]);
    }

    for (const key in tableDetails.properties) {
      if (tableDetails.properties.hasOwnProperty(key) && tableDetails.properties[key] === null) {
        delete tableDetails.properties[key];
      }
    }

    this._toolsService.exportTable(tableDetails).subscribe(() => {
      this._exportService.operationId = tableDetails.operationId;
      this._notificationService.pushSuccess('Erfolg', 'Dokument wird generiert');
      super.closeModal();
    }, ({ errorCode }) => this._notificationService.pushError('Fehler', this._errorResolverService.getMessage(errorCode ?? '')));
  }
}
