import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { DocumentTypes } from '../../../../core/enums';
import { NotificationService } from '../../../../core/services';
import { UsersServiceErrorResolverService } from '../../../users/core/services/error-resolvers/users-service-error-resolver.service';
import { ListComponentGeneric } from '../../../../ui/generics';
import { IGeneratedDocument } from '../../core/interfaces/IGeneratedDocument';
import { GeneratedExportsService } from '../../core/services/GeneratedExportsService';

@Component({
  selector: 'app-export-list-view',
  templateUrl: './export-list-view.component.html',
  styleUrls: ['./export-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportListViewComponent extends ListComponentGeneric<IGeneratedDocument> implements OnInit {

  public readonly documentTypes = DocumentTypes;

  protected readonly exportedTableVisibleName = 'Export list';
  protected searchColumns = [
    'type',
    'outputType',
    'comment',
    'createdAt',
    'user.fullName',
    'visibleName'
  ];

  constructor(
    protected _service: GeneratedExportsService<IGeneratedDocument>,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: UsersServiceErrorResolverService,
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public callService(
    pageIndex: number,
    pageSize: number,
    sortField: string | null, sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    search: Array<{ key: string; value: string[] }>,
    additionalFilters: Array<{ key: string; value: string[] }>,
  ) {
    this.loading = true;
    this._service.getGeneratedDocuments(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters).pipe(
    ).subscribe(res => {
      this.loading = false;
      this.data = res;
      this._cdr.detectChanges();
    }, this.parseError);
  }
}
