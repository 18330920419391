<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>
<nz-table
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="batches-list-table"
  #tableRef
>
  <thead>
  <tr>
    <th nzColumnKey="id" [nzSortFn]="true">Datum</th>
    <th nzColumnKey="user.fullName" [nzSortFn]="true">Mitarbeiter</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let document of data.results">
    <td>{{ document.createdAt | datetimeFormat }}</td>
    <td>{{ document.user.fullName }}</td>
    <td>
      <a [href]="document.documentKey | downloadFileUrl" target="_blank">
        <i nz-icon nzType="cloud-download"  nzTheme="outline"></i>
      </a>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
