import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { D_GLOBAL_MODAL } from '../../core/directives';
import { NotificationService } from '../../core/services';

@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: QrcodeScannerComponent }]

})
export class QrcodeScannerComponent implements OnInit {

  @ViewChild('qrscanner', { static: false }) qrscannerContainer: ZXingScannerComponent;

  selectedDevice: MediaDeviceInfo;
  scannerEnable = false;
  isLoading = true;

  constructor(private modal: NzModalRef,
              private _cdr: ChangeDetectorRef,
              private _notifyService: NotificationService,
  ) { }

  ngOnInit(): void {
  }

  camerasFoundHandler($event: MediaDeviceInfo[]) {
    this.selectedDevice = $event[0];

    this.qrscannerContainer.askForPermission().then(
      res => {
        this.qrscannerContainer.device = this.selectedDevice;
        this.isLoading = false;
        this.qrscannerContainer.enable = true;
        this._cdr.detectChanges();
      },
      err => {
        this._notifyService.pushError('', 'Die Erlaubnis zur Verwendung des Scanners wurde verweigert');
      }
      );
  }

  camerasNotFoundHandler($event: any) {
    this._notifyService.pushError('', 'Keine Scan-Geräte gefunden');
  }

  scanSuccessHandler($event: string) {
    this.scannerEnable = false;
    this.qrscannerContainer.enable = false;
    this._cdr.detectChanges();
    this.modal.destroy({ code: $event});
  }
}
