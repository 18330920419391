import { FormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordValidator {

  public static strong(control: FormControl): ValidationResult {

    const hasNumber = /[0-9]+/.test(control.value);
    const hasUpper = /[A-Z]+/.test(control.value);
    const hasLower = /[a-z]+/.test(control.value);
    const hasMiniMaxChars = /.{5,100}/.test(control.value);
    const hasSymbols = /[!@#$%^&*()_+=\[{\]};:<>|./?,-]/.test(control.value);
    const valid = hasNumber && hasUpper && hasLower && hasMiniMaxChars && hasSymbols;

    if (!valid) {
      // return what´s not valid
      return { strong: true };
    }
    return null;
  }
}