import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar/avatar.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { SubpageHeaderComponent } from './headers/subpage-header/subpage-header.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { TableSearchBarComponent } from './table-search-bar/table-search-bar.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TableHeaderComponent } from './headers/table-header/table-header.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { DownloadDocumentComponent } from './download-document/download-document.component';
import { DownloadDocumentsComponent } from './download-documents/download-documents.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropdownMenuComponent, NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { BooleanIconComponent } from './boolean-icon/boolean-icon.component';
import { DocumentPdfSafariModalComponent } from './document-pdf-safari-modal/document-pdf-safari-modal.component';
import { InnerBoxComponent } from './inner-box/inner-box.component';
import { HintIconComponent } from './hint-icon/hint-icon.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ExportTableModalComponent } from './modals/export-table-modal/export-table-modal.component';
import { DownloadCsvComponent } from './download-csv/download-csv.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SearchComponent } from './search/search.component';
import { SearchModalComponent } from './modals/search-modal/search-modal.component';
import { CoreModule } from '../core/core.module';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { CountdownComponent } from './countdown/countdown.component';
import { QrcodeScannerComponent } from './qrcode-scanner/qrcode-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

@NgModule({
  declarations: [
    AvatarComponent,
    SubpageHeaderComponent,
    TableSearchBarComponent,
    TableHeaderComponent,
    DownloadDocumentComponent,
    DownloadDocumentsComponent,
    BooleanIconComponent,
    DocumentPdfSafariModalComponent,
    InnerBoxComponent,
    HintIconComponent,
    ExportTableModalComponent,
    DownloadCsvComponent,
    RichTextEditorComponent,
    CountdownComponent,
    SearchComponent,
    SearchModalComponent,
    QrcodeScannerComponent,
    ConfirmationModalComponent
  ],
    imports: [
      CommonModule,
      NzAvatarModule,
      NzDividerModule,
      NzTableModule,
      NzSpinModule,
      NzFormModule,
      NzInputModule,
      ReactiveFormsModule,
      NzModalModule,
      NzButtonModule,
      NzIconModule,
      NzCheckboxModule,
      FormsModule,
      NzInputNumberModule,
      NzToolTipModule,
      NzSelectModule,
      AngularEditorModule,
      CoreModule,
      ZXingScannerModule,
      NzMessageModule,
      NzAlertModule,
      NzAutocompleteModule,
    ],
  exports: [
    AvatarComponent,
    SubpageHeaderComponent,
    TableSearchBarComponent,
    TableHeaderComponent,
    DownloadDocumentComponent,
    DownloadDocumentsComponent,
    NzTableModule,
    NzSpinModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzModalModule,
    NzButtonModule,
    NzIconModule,
    NzCheckboxModule,
    FormsModule,
    NzDropDownModule,
    NzBreadCrumbModule,
    NzDividerModule,
    NzSelectModule,
    NzDatePickerModule,
    NzPopconfirmModule,
    NzTypographyModule,
    NzRadioModule,
    BooleanIconComponent,
    DocumentPdfSafariModalComponent,
    InnerBoxComponent,
    ExportTableModalComponent,
    DownloadCsvComponent,
    RichTextEditorComponent,
    NzSwitchModule,
    CountdownComponent,
    QrcodeScannerComponent,
    SearchModalComponent,
    SearchComponent,
    ConfirmationModalComponent,
  ]
})
export class UiModule { }
