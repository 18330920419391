<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="450"
>
  <ng-template #modalTitle>
    New delivery
  </ng-template>

  <ng-template #modalContent>
    <nz-row>
      <form nz-form [formGroup]="form" (ngSubmit)="submitForm()" style="width: 100%;">
        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="price">
            <span>Preis</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input id="price" type="number" min="0" formControlName="price" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="quantity" nzRequired>
            <span>Quantity</span>
          </nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <div class="d-flex align-items-center">
              <input nz-input id="quantity" type="number" min="0" formControlName="quantity" />&nbsp;kg
            </div>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="deliveryDate" nzRequired>
            <span>Liefertermin</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <nz-date-picker
              [nzFormat]="dateFormat"
              formControlName="deliveryDate"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="charge" nzRequired>
            <span>Charge</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <input nz-input id="charge" formControlName="charge" appChargeInput [articleId]="articleId" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="mhd" nzRequired>
            <span>MHD</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <nz-date-picker
              [nzFormat]="dateFormat"
              formControlName="mhd"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="supplierId" nzRequired>
            <span>Lieferant</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">

            <nz-select
              nzPlaceHolder="Wählen Sie Lieferant"
              nzShowSearch
              nzServerSearch
              formControlName="supplier"
              (nzOnSearch)="onSearchSupplier($event)"
              [compareWith]="compareSimpleList"
            >
              <ng-container *ngFor="let o of suppliersList">
                <nz-option *ngIf="!isSuppliersLoading" [nzValue]="o" [nzLabel]="o.value"></nz-option>
              </ng-container>
              <nz-option *ngIf="isSuppliersLoading" nzDisabled nzCustomContent>
                <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="name">
            <span>Kommentar</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <textarea nz-input id="comment" type=number formControlName="comment"></textarea>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-row>

  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">Speichern</button>
  </ng-template>
</nz-modal>
