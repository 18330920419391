import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from './base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityServiceErrorResolverService extends BaseServiceErrorResolverService {

  defaultMessage = 'Problem while operation, please try again';

  codes = {

  };

  public getMessage(reason: string) {
    if (reason === null || reason === undefined) {
      return this.defaultMessage;
    }

    if (this.codes.hasOwnProperty(reason)) {
      return this.codes[reason];
    } else {
      return this.defaultMessage;
    }
  }
}
