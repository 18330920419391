import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input, OnDestroy
} from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-table-search-bar',
  templateUrl: './table-search-bar.component.html',
  styleUrls: ['./table-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSearchBarComponent implements OnInit, OnDestroy {
  @Input() searchValue = '';
  @Input() minLength = 0 ;
  @Input() searchAfterSubmit = false;
  @Output() search = new EventEmitter<string>();
  debounceTime = 500;
  debouncer = new Subject<string>();
  subs = new Array<Subscription>();

  constructor() {
    this.subs.push(
      this.debouncer.pipe(debounceTime(500)).subscribe(res => {
        if (this.searchValue.length >= this.minLength || this.searchValue.length === 0) {
          this.search.emit(res);
        }
      })
    );
  }

  ngOnInit(): void {
  }

  submit() {
    this.search.emit(this.searchValue);
  }

  searchValueChanged() {
    this.debouncer.next(this.searchValue);
  }

  removeSubscription() {
    if (this.subs.length > 0) {
      for (const sub of this.subs) {
        sub.unsubscribe();
      }
    }
  }

  ngOnDestroy() {
    if (!this.searchAfterSubmit) {
      this.removeSubscription();
    }
  }
}
