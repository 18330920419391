import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BulkContainersService } from '../../core/services';
import { IBulkContainerArticle } from '../../core/interfaces/IBulkContainerArticle';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { NotificationService } from '../../../../core/services';
import { CommentsListComponent } from './comments-list/comments-list.component';
import { ArticlesCommentsService } from '../../../articles/core/ArticlesCommentsService';
import { ArticlesService } from '../../../articles/core/ArticlesService';

@Component({
  selector: 'app-single-bulk-container',
  templateUrl: './single-bulk-container.component.html',
  styleUrls: ['./single-bulk-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleBulkContainerComponent implements OnInit, OnDestroy {

  @ViewChild('commentsListComponent') commentsListComponent: CommentsListComponent;

  public title: string;
  public parentTitle = 'Großgebinde';
  public parentLink = '/bulk-containers/overview';
  private _subs: Subscription[] = [];
  articleId$ = new BehaviorSubject<number>(null);
  bulkContainer$: Observable<IBulkContainerArticle> = null;
  public articleName: string;
  public showCommentControl = false;
  public newComment: string;

  constructor(
    private _bulkContainerService: BulkContainersService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _notificationService: NotificationService,
    private _articlesCommentsService: ArticlesCommentsService,
    private _articlesService: ArticlesService,
  ) { }

  ngOnInit(): void {
      this._subs.push(
        this._route.params.subscribe(params => {
          this.articleId$.next(+params['id']);
        }),
        this._route.queryParams.subscribe(params => {
          if (!params.hasOwnProperty('tab')) {
            this._router.navigateByUrl(this._router.url + '?tab=deliveries');
          }
        })
      );
      this.articleId$.asObservable().subscribe(() => this.loadContent());
  }

  public loadContent(): void {
    this.bulkContainer$ = this._bulkContainerService.getBulkContainerArticleById(this.articleId$.getValue()).pipe(
      map(x => {
        x.minStock = x.minStock / 1000;

        return x;
      }),
      tap(res => {
        this.articleName = res.article.name;
        this.title = this.parentTitle + ': ' + this.articleName;
      }),
      catchError(err => {
        if (err.request.status === 404) {
          this._router.navigateByUrl('/bulk-containers/overview');
        }

        return observableThrowError(err);
      })
    );
  }

  public saveComment(): void {
    if (!this.newComment) {
      this._notificationService.pushError('Fehler', 'Comment cannot be empty.');
    } else {
      this._articlesCommentsService.create({
        articleId: this.articleId$.getValue(),
        comment: this.newComment
      }).subscribe({
        next: () => {
          this.loadContent();
          this.newComment = null;
          this.showCommentControl = false;
          this.commentsListComponent.onRefreshList(true);
        }
      });
    }
  }

  public updateMinStock(minStock: number): void {
    this._articlesService.updateArticleMinStock(this.articleId$.getValue(), null, minStock * 1000).subscribe({
      next: () => this._notificationService.pushSuccess('Erfolgreich', `Der Mindestbestand wurde korrekt angelegt`),
      error: () => this._notificationService.pushError('Fehler', `Fehler beim Speichern des Mindestbestands`),
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
