import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListForArticleComponent } from './list-for-article/list-for-article.component';
import { CoreModule } from '../../core/core.module';
import { AddEditModalComponent } from './add-edit-modal/add-edit-modal.component';
import { AddEditFormComponent } from './add-edit-form/add-edit-form.component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { UiModule } from '../../ui/ui.module';
import { ProductionOrdersComponent } from './production-orders/production-orders.component';
import { ListComponent } from './list/list.component';
import { ProductionOrdersRoutingModule } from './production-orders-routing.module';
import { ProcessModalComponent } from './process-modal/process-modal.component';
import { ProcessFormComponent } from './process-form/process-form.component';

@NgModule({
  declarations: [
    ListForArticleComponent,
    AddEditModalComponent,
    AddEditFormComponent,
    ListComponent,
    ProductionOrdersComponent,
    ProcessModalComponent,
    ProcessFormComponent
  ],
  imports: [
    CommonModule,
    ProductionOrdersRoutingModule,
    CoreModule,
    NzInputNumberModule,
    UiModule,
  ],
  exports: [
    ListForArticleComponent,
    AddEditModalComponent,
    ProcessFormComponent,
    ProcessModalComponent
  ]
})
export class ProductionOrdersModule { }
