import { IScanableItem } from '../../../../core/interfaces';
import * as dayjs from 'dayjs';

export class DeliveryNote implements IDeliveryNote {

  constructor(data: IDeliveryNote) {
    Object.assign(this, data);
    this.checkESD();
  }


  id: number;
  number: string;
  fullDate: dayjs.Dayjs;
  shopProviderKey: string;
  deliveryMethod: string;
  deliverySlip: string;
  articles: DeliveryNoteArticle[];
  orderCompleted: boolean;
  trackingCode: string;
  comment: string;
  customerComment: string;
  internalComment: string;

  checkIsOrderCompleted() {
    if (!this.orderCompleted) {
      const isMinimumOneOpen = this.articles.find(article => !article.isFull());
      if (!isMinimumOneOpen) {
        this.orderCompleted = true;
      }
    }
    return this.orderCompleted;
  }

  checkTrackingNumber(value: string): boolean {
    return (this.trackingCode.includes(value));
  }

  private checkESD () {
    this.articles.every(value => {
      if (value.isEsd) {
        value.makeFull();
        this.checkIsOrderCompleted();
      }
    });
  }

}

export interface IDeliveryNote {
  id: number;
  number: string;
  fullDate: dayjs.Dayjs;
  shopProviderKey: string;
  deliveryMethod: string;
  deliverySlip: string;
  articles: DeliveryNoteArticle[];
  orderCompleted: boolean;
  trackingCode: string;
  comment: string;
  customerComment: string;
  internalComment: string;
  checkIsOrderCompleted(): boolean;
  checkTrackingNumber(val: string): boolean;

}

export class DeliveryNoteArticle implements IScanableItem<DeliveryNoteArticle> {
  constructor(data: DeliveryNoteArticle ) {
    Object.assign(this, data);
  }
  id: number;
  ean: string;
  name: string;
  orderNumber: string;
  quantity: number;
  isEsd: boolean;
  packUnit: string;
  scanned = 0;
  storageLocationNumber: string;

  incrementNumberOfScan(): boolean {
    if ( !this.isFull() ) {
      this.scanned++;
      return true;
    }

    return false;
  }

  isFull() {
    return (this.quantity === this.scanned);
  }

  makeFull() {
    this.scanned = this.quantity;
  }
}
