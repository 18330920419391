import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEditFormGeneric } from '../../../../ui/generics';
import { OrdersService } from '../../core/services';
import { NotificationService } from '../../../../core/services';
import { OrdersServiceErrorResolverService } from '../../core/services/error-resolvers';
import { ISimpleListItem } from '../../../../core/interfaces/ISimpleListItem';
import { IDeliveryOrder } from '../../core/interfaces/IDeliveryOrder';
import { SuppliersService } from '../../../suppliers/core/services/SuppliersService';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import { IDeliveryOrderProduct } from '../../core/interfaces/IDeliveryOrderProduct';
import * as moment from 'moment';
import { OrderTypes } from '../../core/enums';
import { UsersService } from '../../../users/core/services/UsersService';
import { environment } from '../../../../../environments/environment';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { ToolsService } from '../../../../core/services/ToolsService';
import { BulkContainerStatuses, UploadDocumentTypes } from '../../../../core/enums';
import { InvoiceTypes } from '../../../../core/enums/InvoiceTypes';

@Component({
  selector: 'app-add-edit-order-form',
  templateUrl: './add-edit-order-form.component.html',
  styleUrls: ['./add-edit-order-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditOrderFormComponent extends AddEditFormGeneric<IDeliveryOrder> implements OnInit {

  @Input() orderType = OrderTypes.MIXED;

  isUsersLoading = false;
  isInvoiceApproversLoading = false;
  isHandlersLoading = false;
  isSuppliersLoading: boolean;
  usersList: ISimpleListItem[];
  isInvoiceApproversList: ISimpleListItem[];
  handlersList: ISimpleListItem[];
  isArticlesLoading: boolean;
  suppliersList: ISimpleListItem[];
  articlesList: ISimpleListItem[];
  productFormList: FormArray;
  editRow: number;
  searchSupplierChange$ = new Subject();
  searchUsersChange$ = new Subject();
  searchHandlerChange$ = new Subject();
  searchInvoiceApproverChange$ = new Subject();
  searchArticleChange$ = new Subject();
  public additionalProducts: ISimpleListItem[] = [];
  public isAdditionalProductsModalVisible = false;
  public isFileUploading = false;
  public readonly environment = environment;
  public readonly orderTypes = OrderTypes;
  public readonly uploadDocumentTypes = UploadDocumentTypes;
  public readonly invoiceTypes = InvoiceTypes;

  constructor(
    public readonly toolsService: ToolsService,
    protected _fb: FormBuilder,
    protected _service: OrdersService,
    protected _supplierService: SuppliersService,
    protected _notifyService: NotificationService,
    protected _errorResolver: OrdersServiceErrorResolverService,
    private _cdr: ChangeDetectorRef,
    private _renderer: Renderer2,
    private _usersService: UsersService,
  ) {
    super(_fb, _service, _notifyService, _errorResolver);
  }

  loadForm(): void {
    if (this.editObject) {
      this.loading = true;
      this.isEdit = true;
      this._formService.getById(this.editObject['id'].toString()).subscribe(value => {
        if (value.orderer) {
          value.orderer.name = value.orderer.fullName;
        }

        value.orderProducts.forEach(x => x.isBagable ? x.quantity = +(x.quantity / 1000).toFixed(2) : x.quantity);
        this._editObject = value;
        this.beforeLoadForm();
        this.form.patchValue(this.editObject);
        this.afterLoadForm();
        this.loading = false;
      });
    }
  }

  @HostListener('window:click', ['$event.target'])
  onClickAnywhere(target: HTMLElement) {
    const fId = this._renderer.selectRootElement('.cdk-global-overlay-wrapper > div', true).getAttribute('id');
    if (
      target.closest('.editable-row') === null &&
      target.closest('#add-product-row') === null &&
      target.closest('.cdk-overlay-pane')?.getAttribute('id') === fId
    ) {
      this.editRow = null;
      this._cdr.detectChanges();
    }
  }

  createProductItem(isNew: boolean): FormGroup {
    const obj = {
      articleId: '',
      id: -1,
      articleVariantId: '',
      deliveryDate: null,
      mhd: null,
      isBagable: null,
      number: '',
      name: '',
      charge: '',
      quantity: [null, Validators.required],
      price: null,
      checked: false,
      status: 'Open',
      comment: '',
      isNew,
      isBio: null,
      product: [null, Validators.required],
    };

    if (this.orderType === OrderTypes.Bagable) {
      delete obj.price;
    }

    if (this.orderType === OrderTypes.NonPacking) {
      delete obj.charge;
      delete obj.mhd;
      delete obj.isBio;
    }

    return  this._fb.group(obj);
  }

  ngOnInit() {
    const obj = {
      supplier: ['', Validators.required],
      orderer: ['', Validators.required],
      handler: [null],
      invoiceApprover: [null],
      invoiceType: [null],
      invoiceDate: [null],
      orderDate: ['', Validators.required],
      orderDocumentKey: [null],
      orderType: [this.orderType, Validators.required],
      orderProducts: this._fb.array([])
    };

    if (this.orderType === OrderTypes.MIXED) {
      delete obj.orderer;
    }

    if (this.orderType !== OrderTypes.NonPacking) {
      delete obj.orderDocumentKey;
    }

    this.form = this._fb.group(obj);

    this.productFormList = this.form.get('orderProducts') as FormArray;

    super.ngOnInit();

    const getUsers = (name: string) =>
      this._usersService.getSimpleList(name, true);

    const getSuppliers = (name: string) =>
      this._supplierService.getSimpleList(name);

    const getArticles = (name: string) =>
      this._service.getArticlesSimpleList(name, this.orderType === OrderTypes.NonPacking);

    const usersOptionList$: Observable<ISimpleListItem[]> = this.searchUsersChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getUsers));
    usersOptionList$.subscribe(data => {
      this.usersList = data;
      this.isUsersLoading = false;
      this._cdr.detectChanges();
    });

    const handlersOptionList$: Observable<ISimpleListItem[]> = this.searchHandlerChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getUsers));
    handlersOptionList$.subscribe(data => {
      this.handlersList = data;
      this.isHandlersLoading = false;
      this._cdr.detectChanges();
    });

    const invoiceApproverOptionList$: Observable<ISimpleListItem[]> = this.searchInvoiceApproverChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getUsers));
    invoiceApproverOptionList$.subscribe(data => {
      this.isInvoiceApproversList = data;
      this.isInvoiceApproversLoading = false;
      this._cdr.detectChanges();
    });

    const suppliersOptionList$: Observable<ISimpleListItem[]> = this.searchSupplierChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getSuppliers));
    suppliersOptionList$.subscribe(data => {
      this.suppliersList = data;
      this.isSuppliersLoading = false;
      this._cdr.detectChanges();
    });

    const articlesOptionList$: Observable<ISimpleListItem[]> = this.searchArticleChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getArticles));
    articlesOptionList$.subscribe(data => {
      this.articlesList = data;
      this.isArticlesLoading = false;
      this._cdr.detectChanges();
    });
  }

  onSearchUsers(value: string) {
    this.isUsersLoading = true;
    this.searchUsersChange$.next(value);
  }

  onSearchInvoiceApprovers(value: string) {
    this.isInvoiceApproversLoading = true;
    this.searchInvoiceApproverChange$.next(value);
  }

  onSearchHandlers(value: string) {
    this.isHandlersLoading = true;
    this.searchHandlerChange$.next(value);
  }

  onSearchSupplier(value: string) {
    this.isSuppliersLoading = true;
    this.searchSupplierChange$.next(value);
  }

  onArticleSearch(value: string) {
    this.isArticlesLoading = true;
    this.searchArticleChange$.next(value);
  }

  startEdit(id: number): void {
    this.editRow = id;
  }

  deleteRow(id: number): void {
    this.productFormList.removeAt(id);
  }

  addProductRow(isNew = true) {
    this.productFormList.push(this.createProductItem(isNew));
    if (isNew) {
      this.editRow = this.productFormList.length - 1;
    }
  }

  productChange(id: number | string) {
    const article = this.articlesList.find(value => value.id === id.toString());

    this.productFormList.at(this.editRow).patchValue({
      product: article.id,
      name: article.value,
      number: article.additionalParameters.Number,
      isBagable: article.additionalParameters.isBagable === 'True',
      articleId: (article.additionalParameters.ArticleId ? article.additionalParameters.ArticleId : null),
      articleVariantId: (article.additionalParameters.ArticleVariantId ? article.additionalParameters.ArticleVariantId : null),
      isBio: article.additionalParameters.IsBio !== null,
    });
  }

  copyRow(rowId: number) {
    const row: FormGroup = cloneDeep(this.productFormList.at(rowId));
    row.patchValue({ id: -1, status: 'Open' });
    this.productFormList.insert(rowId + 1, row);
  }

  bookRow(rowId: number): void {
    this.isSubmitted = true;
    let fg = this.productFormList.at(rowId) as FormGroup;
    fg = this.setValidatorsForBook(fg);

    for (const key in fg.controls) {
      if (fg.controls.hasOwnProperty(key)) {
        fg.controls[key].markAsDirty();
        fg.controls[key].updateValueAndValidity();
      }
    }

    if (!fg.valid) {
      this._cdr.detectChanges();
      this.clearValidators(fg);
      return;
    }
    const orderProduct =  fg.value as IDeliveryOrderProduct;

    if (orderProduct.isBagable) {
      orderProduct.quantity = orderProduct.quantity * 1000;
    }

    this._service.bookProductOrder(orderProduct).subscribe(() => {
        this._notifyService.pushSuccess('Erfolgreich', `Produkt korrekt gebucht`);
        fg.patchValue({
          status: 'Finished'
        });
        this._cdr.detectChanges();
      },
      this.parseError);
    this.clearValidators(fg);
  }

  cancelRow(rowId: number): void {
    const fg = this.productFormList.at(rowId) as FormGroup;

    this._service.cancelProductOrder(fg.value.id).subscribe(() => {
        this._notifyService.pushSuccess('Erfolgreich', `Produkt korrekt storniert`);
        fg.patchValue({
          status: 'Canceled'
        });
        this._cdr.detectChanges();
      },
      this.parseError);
  }

  beforeLoadForm() {
    if (this.editObject.orderProducts) {
      this.editObject.orderProducts.forEach(() => this.addProductRow(false));
    }

    if (this.editObject.supplier) {
      this.searchSupplierChange$.next(this.editObject.supplier.name);
    }

    if (this.editObject.orderer) {
      this.searchUsersChange$.next(this.editObject.orderer.fullName);
    }

    if (this.editObject.handler) {
      this.searchHandlerChange$.next(this.editObject.handler.fullName);
    }

    if (this.editObject.invoiceApprover) {
      this.searchInvoiceApproverChange$.next(this.editObject.invoiceApprover.fullName);
    }
  }

  setValidatorsForBook(fg: FormGroup) {
    fg.get('checked').setValidators(Validators.required);
    fg.get('deliveryDate').setValidators(Validators.required);

    if (this.orderType !== OrderTypes.Bagable) {
      fg.get('price').setValidators(Validators.required);
    }

    if (this.orderType !== OrderTypes.NonPacking) {
      fg.get('charge').setValidators(Validators.required);
      fg.get('mhd').setValidators(Validators.required);
    }

    fg.get('quantity').setValidators([Validators.required, Validators.min(1)]);

    return fg;
  }

  clearValidators(fg: FormGroup) {
    fg.clearValidators();
    return fg;
  }

  isAllProductsOpen() {
    return this.productFormList.value.find(value => (value.status !== 'Open'));
  }

  saveEdit(): void {
    this.countQuantities(this.form.value.orderProducts);
    this.updateDatsFormat();
    super.saveEdit();
  }

  saveNew(): void {
    this.countQuantities(this.form.value.orderProducts);
    this.updateDatsFormat();
    super.saveNew();
  }

  public handleChange(info: NzUploadChangeParam): void {
    this.isFileUploading = true;

    switch (info.type) {
      case 'start': {
        this.isFileUploading = true;
        this._notifyService.pushInfo('Infos', 'Dokument wird hochgeladen, das kann einige Zeit dauern');

        break;
      }

      case 'success': {
        this._notifyService.pushSuccess('Erfolg', 'Dokument wurde hochgeladen');
        this.isFileUploading = false;
        this.form.get('orderDocumentKey').patchValue(info.file.response['key']);

        break;
      }
    }
  }

  public addSuggestedArticleToOrder(val: ISimpleListItem): void {
    this._service.getArticlesSimpleList(val.value, this.orderType === OrderTypes.NonPacking)
      .subscribe(res => {
        this.articlesList = res;
        this.addProductRow();
        this.productChange(val.id);
        this.editRow = null;
        this._cdr.detectChanges();
      });
  }

  public onSupplierChange(): void {
    this._service.getArticlesSimpleList(
      '',
      this.orderType === OrderTypes.NonPacking,
      this.form.get('supplier').value.id
    )
      .subscribe(res => {
        if (res.length > 0) {
          this.additionalProducts = res;
          this.isAdditionalProductsModalVisible = true;
          this._cdr.detectChanges();
        }
      });
  }

  private countQuantities(arr: IDeliveryOrderProduct[]): void {
    arr.forEach(x => x.isBagable ? x.quantity = x.quantity * 1000 : x.quantity);
  }

  private updateDatsFormat(): void {
    this.form.value['orderDate'] = moment(this.form.value['orderDate']).format('YYYY-MM-DD');

    if (this.form.value['invoiceDate']) {
      this.form.value['invoiceDate'] = moment(this.form.value['invoiceDate']).format('YYYY-MM-DD');
    }

    this.form.value['orderProducts'].forEach(x => {
      x.deliveryDate = x.deliveryDate ? moment(x.deliveryDate).format('YYYY-MM-DD') : null;
      x.mhd = x.mhd ? moment(x.mhd).format('YYYY-MM-DD') : null;
    });
  }
}
