<app-inner-box>
  <ng-container [ngSwitch]="selectedOrderType">
    <app-subpage-header
      title="Bestellungen"
      *ngSwitchCase="orderTypes.MIXED"
    ></app-subpage-header>
    <app-subpage-header
      title="Bestellungen - Packaging"
      *ngSwitchCase="orderTypes.Bagable"
    ></app-subpage-header>
    <app-subpage-header
      title="Bestellungen - Non-packaging"
      *ngSwitchCase="orderTypes.NonPacking"
    ></app-subpage-header>
  </ng-container>

  <button nz-button nzType="primary" (click)="addEditOrderModalComponent.showModal(null)">
    <i nz-icon nzType="plus-circle" nzTheme="outline"></i><span>Neu erstellen</span>
  </button>
  <br>
  <br>
  <ng-container [ngSwitch]="selectedOrderType">
    <app-orders-list
      #listComponent
      *ngSwitchCase="orderTypes.MIXED"
      [orderType]="selectedOrderType"
    ></app-orders-list>

    <app-orders-list
      #listComponent
      *ngSwitchCase="orderTypes.Bagable"
      [orderType]="selectedOrderType"
    ></app-orders-list>

    <app-orders-list
      #listComponent
      *ngSwitchCase="orderTypes.NonPacking"
      [orderType]="selectedOrderType"
    ></app-orders-list>
  </ng-container>
</app-inner-box>

<app-add-edit-order-modal
  (refresh)="listComponent.onRefreshList()"
  #addEditOrderModalComponent
  [orderType]="selectedOrderType"
></app-add-edit-order-modal>

