import { Component, OnInit, ChangeDetectionStrategy, Input, Renderer2 } from '@angular/core';
import { ToolsService } from '../../core/services/ToolsService';

@Component({
  selector: 'app-download-csv',
  templateUrl: './download-csv.component.html',
  styleUrls: ['./download-csv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadCsvComponent implements OnInit {

  @Input() icon = 'file';
  @Input() documentKey: string;
  @Input() fileName = 'document';

  constructor(
    private readonly _toolsService: ToolsService,
    private readonly _renderer: Renderer2,
  ) { }

  ngOnInit(): void {
  }

  public download(): void {
    this._toolsService.getFile(this.documentKey, 'text/csv').subscribe( file => {
      const hiddenElement = this._renderer.createElement('a');
      hiddenElement.href = window.URL.createObjectURL(file);
      const splitFileName = this.fileName.split('/');
      hiddenElement.download = (this.fileName.includes('/') ? splitFileName[splitFileName.length - 1] : this.fileName);
      hiddenElement.click();
    });
  }
}
