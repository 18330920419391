import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-non-packing',
  templateUrl: './non-packing.component.html',
  styleUrls: ['./non-packing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NonPackingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
