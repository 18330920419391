<span #refModalTrigger><ng-content select="[trigger]"></ng-content></span>
<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1200"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <app-store-stocks-corrections-list [object]="object"></app-store-stocks-corrections-list>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
  </ng-template>
</nz-modal>
