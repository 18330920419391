import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'unitFormat'
})
export class ProductionUnitsFormatPipe implements PipeTransform {

  public transform(value: number, unit: string, isPcs = false): string {
    if (isPcs) {
      return `${ value } Stück`;
    }

    if (value && (unit.toLowerCase().trim() === 'kg' || unit === '') ) {
      value = (value * 1000);
      unit = 'g';
    } else if (value && unit && unit.toLowerCase().trim() === 'l') {
      value = value * 1000;
      unit = 'ml';
    }

    return `${value} ${unit}`;
  }
}
