import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { ModalGeneric } from '../../../ui/generics';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { StoreStocksErrorResolverService } from '../core/error-resolvers/store-stocks-error-resolver.service';
import { NotificationService } from '../../../core/services';
import { IStoreStocksListItem } from '../core/interfaces/IStoreStocksListItem';
import { StoreStocksService } from '../core/services/StoreStocks.service';

@Component({
  selector: 'app-add-edit-stock-modal',
  templateUrl: './add-edit-stock-modal.component.html',
  styleUrls: ['./add-edit-stock-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditStockModalComponent extends ModalGeneric implements OnInit {

  @Input() title = 'Stock correction';

  @Output() refresh  = new EventEmitter<void>();

  public object: {articleVariantId: number, storeId: number, quantity?: number, comment?: string};
  public form: FormGroup;
  public isConfirmLoading = false;
  public loading = false;
  public constructor(
    private _fb: FormBuilder,
    protected cdr: ChangeDetectorRef,
    private _storeStockService: StoreStocksService<IStoreStocksListItem>,
    private _storeStockErrorResolverService: StoreStocksErrorResolverService,
    protected _notifyService: NotificationService,
  ) {
    super(cdr);
    this.form = this._fb.group({
      articleVariantId: [null, Validators.required],
      storeId: [null, Validators.required],
      quantity: [null, [Validators.required, Validators.pattern(/[0-9]+/)]],
      comment: [null, Validators.required],
    });
  }

  ngOnInit(): void {
  }

  showModal(object: {articleVariantId: number, storeId: number}): void {
    this.object = object;
    this.form.patchValue(object);
    super.showModal();
  }

  submitForm() {
    if (!this.form.dirty) {
      this.closeModal();
      return;
    }
    this.isConfirmLoading = true;
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      this.isConfirmLoading = false;
      return;
    }
    this._storeStockService.storeStockCorrection(this.form.value).pipe(catchError(this.handleError)).subscribe(
      res => {
        this._notifyService.pushSuccess('Erfolg', 'Lagerbestand wurde geändert');
        this.closeModal();
        this.refresh.emit();
      }, error => {
        this._notifyService.pushError('Fehlermeldung', this._storeStockErrorResolverService.getMessage(error.InternalCode));
        this.isConfirmLoading = false;
        this.cdr.detectChanges();
      }
    );
  }

  handleError(error) {
    return throwError(error);
  }

  handleOk() {
    if (this.form.invalid) {
      return;
    }
    this.submitForm();
  }

  public closeModal(): void {
    this.object = null;
    this.form.reset();
    super.closeModal();
  }
}
