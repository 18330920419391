<nz-spin [nzSpinning]="loading">
  <nz-tabset>
    <nz-tab nzTitle="Übersicht">
      <nz-row>
        <form nz-form [formGroup]="form" (ngSubmit)="submitForm()" style="width: 100%">
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name">
              <span>Name</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie den Namen des Lieferanten ein!">
              <input nz-input id="name" formControlName="name" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="orderNumber">
              <span>Bestellnummer</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte Bestellnummer eingeben!">
              <input nz-input id="orderNumber" formControlName="orderNumber">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="shortDescription">
              <span>Short Description</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie eine kurze Beschreibung ein!">
              <textarea nz-input id="shortDescription" formControlName="shortDescription"></textarea>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="supplierId">
              <span>Supplier</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <nz-select formControlName="supplierId">
                <nz-option *ngFor="let type of (suppliers$ | async)" [nzLabel]="type.value" [nzValue]="type.id"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="ean">
              <span>EAN</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte geben Sie EAN ein!">
              <input nz-input id="ean" formControlName="ean" type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="weight">
              <span>Gewicht</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input id="weight" formControlName="weight" type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="purchaseUnit">
              <span>Kaufwert</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input id="purchaseUnit" formControlName="purchaseUnit" type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="packUnit">
              <span>Kaufeinheit</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input id="packUnit" formControlName="packUnit" type="text" />
            </nz-form-control>
          </nz-form-item>
          <nz-row nzJustify="center" *ngIf="false">
            <nz-col [nzSm]="6" [nzXs]="12">
              <nz-form-item>
                <nz-form-label nzFor="checked">
                  <span>ist Variant</span>
                </nz-form-label>
                <nz-form-control>
                  <label
                    class="verify-checkbox"
                    nz-checkbox
                    formControlName="hasVariants"
                    (nzCheckedChange)="checkedChanged()"
                  >
                    Ja/Nein
                  </label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col [nzSm]="6" [nzXs]="12">
              <nz-form-item>
                <nz-form-label nzFor="isGiftbox">
                  <span>Geschenkset</span>
                </nz-form-label>
                <nz-form-control>
                  <label
                    class="verify-checkbox"
                    nz-checkbox formControlName="isGiftbox" (nzCheckedChange)="selectedGiftboxArticles = []">Ja/Nein</label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>

          <nz-row nzJustify="center" *ngIf="false">
            <nz-col [nzSm]="6" [nzXs]="12">
              <nz-form-item>
                <nz-form-label nzFor="checked">
                  <span>ist Bio</span>
                </nz-form-label>
                <nz-form-control>
                  <label
                    class="verify-checkbox"
                    nz-checkbox formControlName="isBio">Ja/Nein</label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col [nzSm]="6" [nzXs]="12"></nz-col>
          </nz-row>
          <nz-form-item *ngIf="form.controls['isGiftbox'].value && (!form.controls.hasVariants.value)" class="formula">
            <nz-form-label [nzXs]="24" style="text-align: left">
              <span>Formula Geschenkset</span>
            </nz-form-label>
            <nz-form-control [nzXs]="24" [nzSm]="12">
              <ul nz-list [nzDataSource]="selectedGiftboxArticles" nzBordered nzSize="large" class="form-control form-control--selected-articles">
                <nz-list-header>ausgewählte Artikel</nz-list-header>
                <li nz-list-item *ngFor="let item of selectedGiftboxArticles; let i = index;" nzNoFlex class="article-item">
                  <ul nz-list-item-actions>
                    <nz-list-item-action>
                      <i nz-icon nzType="close" nzTheme="outline" (click)="removeGiftboxFromList(i)"></i>
                    </nz-list-item-action>
                  </ul>
                  {{ item.fullName }}
                </li>
              </ul>
            </nz-form-control>
            <nz-form-control [nzXs]="24" [nzSm]="12">
              <ul nz-list [nzDataSource]="articles" nzBordered nzSize="large" *ngIf="giftboxFormulaArticles$ | async as articles">
                <nz-list-header>alle Artikle <app-table-search-bar (search)="searchGiftboxArticles($event)" [searchValue]="gitboxSearch"></app-table-search-bar></nz-list-header>
                <li nz-list-item *ngFor="let item of articles" nzNoFlex class="article-item">
                  <ul nz-list-item-actions>
                    <nz-list-item-action>
                      <i nz-icon nzType="arrow-left" nzTheme="outline" (click)="addGiftboxToList(item)"></i>
                    </nz-list-item-action>
                  </ul>
                  {{ item.fullName }}
                </li>
              </ul>
            </nz-form-control>
          </nz-form-item>
        </form>
      </nz-row>
    </nz-tab>

    <nz-tab nzTitle="Varianten" *ngIf="isEdit">
<!--      <app-add-edit-variant-modal-->
<!--        #modal-->
<!--        [isGiftbox]="this.form.get('isGiftbox').value"-->
<!--        [articleId]="editObject.id"-->
<!--      ></app-add-edit-variant-modal>-->

      <nz-row style="margin-top: 16px;">
        <nz-table
          #tableRef
          style="width: 100%"
          nzShowSizeChanger
          [nzFrontPagination]="false"
          [nzData]="editObject.articleVariants"
        >
          <thead>
          <tr>
            <th nzColumnKey="id" nzWidth="75px">#ID</th>
            <th nzColumnKey="name">Name</th>
            <th nzColumnKey="orderNumber">Nummer</th>
            <th nzColumnKey="ean">EAN</th>
            <th nzColumnKey="isDefault">Ist Standard</th>
            <th nzColumnKey="isActive">Ist Aktiv in Shopware</th>
            <th nzColumnKey="isActiveForProduction">ist aktiv für die Produktion</th>
            <th nzColumnKey="isNotRelevantForPacking">Nicht relevant für Abfüllliste</th>
            <th nzColumnKey="customWeight">überschriebenes Produktionsgewicht</th>
            <th nzColumnKey="sln">SLN</th>
            <th nzColumnKey="price">Price</th>
            <th nzColumnKey="pseudoPrice">Pseudo price</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let variant of editObject.articleVariants; let i = index;">
            <td>{{ variant.id }}</td>
            <td>{{ variant.name }}</td>
            <td>{{ variant.orderNumber }}</td>
            <td>{{ variant.ean }}</td>
            <td>
              <label
                nz-checkbox
                [(ngModel)]="variant.isDefault"
                [nzDisabled]="true"
              >Ja/Nein</label>
            </td>
            <td>
              <label
                nz-checkbox
                [(ngModel)]="variant.isActive"
                [nzDisabled]="true"
              >Ja/Nein</label>
            </td>
            <td>
              <label
                [disabled]="!variant.isEdited"
                nz-checkbox
                [(ngModel)]="variant.isActiveForProduction"
              >Ja/Nein</label>
            </td>
            <td>
              <label
                [disabled]="!variant.isEdited"
                nz-checkbox
                [(ngModel)]="variant.isNotRelevantForPacking"
              >Ja/Nein</label>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <input
                  type="number"
                  nz-input
                  *ngIf="variant.isEdited"
                  [(ngModel)]="variant.customWeight"
                  appPossitiveNumbers
                >
                <ng-container *ngIf="!variant.isEdited">
                  {{ variant.customWeight }}
                </ng-container>
                &nbsp;g
              </div>
            </td>
            <td>
              <input type="text" nz-input [(ngModel)]="variant.storageLocationNumber" *ngIf="variant.isEdited">
              <ng-container *ngIf="!variant.isEdited">{{ variant.storageLocationNumber }}</ng-container>
            </td>
            <td>
              <div class="d-flex align-items-center white-space-no-wrap" [class.has-discount]="variant.price.pseudoPrice">
                <input
                  nz-input
                  type="number"
                  min="0"
                  style="max-width: 100px;"
                  *ngIf="variant.isEdited && !variant.price.pseudoPrice"
                  [(ngModel)]="variant.price.price"
                />
                <ng-container *ngIf="!variant.isEdited || (variant.isEdited && variant.price.pseudoPrice)">
                  {{ variant.price.price }}
                </ng-container>
                <ng-container *ngIf="variant.isEdited || variant.price.price">
                  &nbsp;€
                </ng-container>
                <button
                  nz-button
                  style="margin-left: 15px;"
                  nzType="default"
                  nzShape="circle"
                  *ngIf="variant.isEdited && !variant.price.pseudoPrice"
                  (click)="showSetDiscountPriceModal(variant)"
                >
                  <i nz-icon nzType="percentage"></i>
                </button>
                <button
                  nz-button
                  style="margin-left: 15px;"
                  nzType="default"
                  nzShape="circle"
                  *ngIf="variant.isEdited && variant.price.pseudoPrice"
                  (click)="variant.price.price = variant.price.pseudoPrice; variant.price.pseudoPrice = null;"
                >
                  <i nz-icon nzType="close"></i>
                </button>
              </div>
            </td>
            <td>
              <ng-container *ngIf="variant.price.pseudoPrice">
                {{ variant.price.pseudoPrice }}&nbsp;€
              </ng-container>
            </td>
            <td>
              <div class="d-flex" style="gap: 5px;">
                <button nz-button nzType="primary" (click)="editSaveButtonClick(variant)">
                  <i nz-icon [nzType]="!variant.isEdited ? 'edit' : 'check'" nzTheme="outline"></i>
                </button>
                <button nz-button nzType="default" *ngIf="variant.isEdited" (click)="variant.isEdited = false">
                  <i nz-icon nzType="close" nzTheme="outline"></i>
                </button>
              </div>

              <div *ngIf="false" class="actions" style="align-items: center;">
<!--                <app-add-edit-variant-modal-->
<!--                  #modal-->
<!--                  [isGiftbox]="this.form.get('isGiftbox').value"-->
<!--                  [articleId]="editObject.id"-->
<!--                >-->
<!--                    <span trigger>-->
<!--                      <i nz-icon nzType="edit"  nzTheme="outline" (click)="modal.showModal(variant)"></i>-->
<!--                    </span>-->
<!--                </app-add-edit-variant-modal>-->

                <app-download-document
                  *ngIf="variant.labelKey"
                  class="print-label-btn"
                  [documentKey]="variant.labelKey"
                  icon="printer"
                ></app-download-document>
              </div>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </nz-row>
    </nz-tab>

    <nz-tab nzTitle="Großgebinde / Production">
      <nz-row>
        <form nz-form [formGroup]="form" style="width: 100%">
          <nz-row nzJustify="center">
            <nz-col [nzSm]="6" [nzXs]="12">
              <nz-form-item>
                <nz-form-label nzFor="isNonPackable">
                  <span>is nonpacking</span>
                </nz-form-label>
                <nz-form-control>
                  <label
                    class="verify-checkbox"
                    nz-checkbox
                    formControlName="isNonPackable"
                    (ngModelChange)="isNonPackableChange()"
                  >
                    Ja/Nein
                  </label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col [nzSm]="6" [nzXs]="12">
              <nz-form-item>
                <nz-form-label nzFor="isBagable">
                  <span>is bagable</span>
                </nz-form-label>
                <nz-form-control>
                  <label
                    class="verify-checkbox"
                    nz-checkbox
                    formControlName="isBagable"
                    (nzCheckedChange)="disableIsNonPackable()"
                  >Ja/Nein</label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
            <nz-col [nzSm]="6" [nzXs]="12">
              <nz-form-item>
                <nz-form-label nzFor="isOwnProduction">
                  <span>eigener Production</span>
                </nz-form-label>
                <nz-form-control>
                  <label
                    class="verify-checkbox"
                    nz-checkbox
                    formControlName="isOwnProduction"
                    (nzCheckedChange)="selectedArticles = []; disableIsNonPackable();"
                  >
                    Ja/Nein
                  </label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>

            <nz-col [nzSm]="6" [nzXs]="12">
              <nz-form-item>
                <nz-form-label nzFor="noWeightForProduction">
                  <span>kein Gewicht für die Produktion</span>
                </nz-form-label>
                <nz-form-control>
                  <label
                    class="verify-checkbox"
                    nz-checkbox
                    formControlName="noWeightForProduction"
                  >
                    Ja/Nein
                  </label>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>

          <nz-form-item *ngIf="this.form.controls['isOwnProduction'].value" class="formula">
            <nz-form-label [nzXs]="24" style="text-align: left">
              <span>Formula eigener Production</span>
            </nz-form-label>
            <nz-form-control [nzXs]="24" [nzSm]="12">
              <ul nz-list [nzDataSource]="selectedArticles" nzBordered nzSize="large" class="form-control form-control--selected-articles">
                <nz-list-header>ausgewählte Artikel</nz-list-header>
                <li nz-list-item *ngFor="let item of selectedArticles; let i = index;" nzNoFlex class="article-item">
                  <ul nz-list-item-actions>
                    <nz-list-item-action>
                      <i nz-icon nzType="close" nzTheme="outline" (click)="removeOwnProductionFromList(i)"></i>
                    </nz-list-item-action>
                  </ul>
                  {{ item.ingredientArticle.value }}
                </li>
              </ul>
            </nz-form-control>
            <nz-form-control [nzXs]="24" [nzSm]="12">
              <ul nz-list [nzDataSource]="articles" nzBordered nzSize="large" *ngIf="articles$ | async as articles">
                <nz-list-header>
                  alle Artikle <app-table-search-bar (search)="searchOwnProduction($event)" [searchValue]="ownProductionSearch" [minLength]="3"></app-table-search-bar>
                </nz-list-header>
                <li nz-list-item *ngFor="let item of articles" nzNoFlex class="article-item">
                  <ul nz-list-item-actions>
                    <nz-list-item-action>
                      <i nz-icon nzType="arrow-left" nzTheme="outline" (click)="addOwnProductionToList(item)"></i>
                    </nz-list-item-action>
                  </ul>
                  {{ item.ingredientArticle.value }}
                </li>
              </ul>
            </nz-form-control>
          </nz-form-item>
        </form>
      </nz-row>
    </nz-tab>

    <nz-tab nzTitle="Stores" *ngIf="isEdit">
      <nz-row>
        <nz-table
          *ngIf="stores$ | async as stores"
          #tableRef
          style="width: 100%"
          nzShowSizeChanger
          [nzFrontPagination]="false"
          [nzData]="editObject.articleVariants"
        >
          <thead>
          <tr>
            <th nzColumnKey="id" nzWidth="75px">#ID</th>
            <th nzColumnKey="name">Name</th>
            <th nzColumnKey="orderNumber">Nummer</th>
            <th nzColumnKey="ean">EAN</th>
            <th *ngFor="let store of stores">{{ store.value }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let variant of editObject.articleVariants">
            <td>{{ variant.id }}</td>
            <td>{{ variant.name }}</td>
            <td>{{ variant.orderNumber }}</td>
            <td>{{ variant.ean }}</td>
            <td *ngFor="let store of stores">
              {{ variant.stocks | stockByStore:store.value }}
            </td>
          </tr>
          </tbody>
        </nz-table>
      </nz-row>
    </nz-tab>
  </nz-tabset>
</nz-spin>

<app-store-stocks-corrections-modal #correctionsModalComponent></app-store-stocks-corrections-modal>

<app-confirmation-modal
  #confirmationModalComponent
  (choice)="removeVariantsChoice($event)"
  contentText="Die Artikelvarianten werden gelöscht. Bist du dir da sicher?"
></app-confirmation-modal>

<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isSetDiscountPriceModalVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="cancelSetDiscountPrice()"
  [nzWidth]="300"
>
  <ng-template #modalTitle>
    Rabattpreis festlegen
  </ng-template>

  <ng-template #modalContent>
    <nz-form-item>
      <div class="d-flex align-items-center" style="gap: 10px;">
        Price in %
        <nz-switch [(ngModel)]="showManualPrice"></nz-switch>
        Manual price
      </div>
    </nz-form-item>
    <nz-form-item>
      <input nz-input [(ngModel)]="selectedDiscountValue">
    </nz-form-item>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="cancelSetDiscountPrice()">Schließen</button>
    <button
      nz-button
      [disabled]="!selectedDiscountValue"
      nzType="primary"
      (click)="submitDiscountPrice()"
    >Speichern</button>
  </ng-template>
</nz-modal>
