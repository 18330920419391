export class BaseServiceErrorResolverService {

  constructor() { }

  defaultMessage = 'Problem bei der Bedienung, bitte versuchen Sie es erneut';

  codes = {

  };

  public getMessage(reason: string) {
    if (reason === null || reason === undefined) {
      return this.defaultMessage;
    }

    if (this.codes.hasOwnProperty(reason)) {
      return this.codes[reason];
    } else {
      return this.defaultMessage;
    }
  }
}

