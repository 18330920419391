import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '../services/auth/PermissionService';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {

  constructor(
    private router: Router,
    private permissionsService: PermissionService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const action = route.data['action'];
    if (this.permissionsService.hasAccess(action)) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }

}
