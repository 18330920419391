import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IdentityService } from './../services/auth/identity.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {}

  canActivate(): Observable<boolean> {
   return this.canActiveAnything();
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActiveAnything();
  }

  private canActiveAnything() {
    return this.identityService.isAuthenticated.pipe(
      take(1),
      tap(auth => {
        if (!auth) {
          this.router.navigateByUrl('/auth/login');
        }
      })
    );
  }

}
