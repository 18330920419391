<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="350"
>
  <ng-template #modalTitle>Datei öffnen</ng-template>

  <ng-template #modalContent>
    <p style="text-align: center">
      Ihre Datei steht zum Herunterladen bereit,
      <br>
      <a [href]="pdfLink" (click)="openLink($event)">klicken Sie hier zum Herunterladen</a>
    </p>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
  </ng-template>
</nz-modal>
