<app-inner-box>
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="/">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="parentLink">{{ parentTitle }}</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      {{ articleName }}
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <div class="header d-flex align-items-center">
    <h1 style="margin-bottom: 0; margin-right: 12px;">{{title}}</h1>
    <div class="actions" *ngIf="articleId">
      <app-add-edit-article-modal #modal (refresh)="loadContent()">
        <span trigger>
          <i
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="modal.showModal($any({ id: articleId, name: articleName }))"
          ></i>
        </span>
      </app-add-edit-article-modal>
    </div>
  </div>

  <nz-divider></nz-divider>

  <ng-container *ngIf="nonPack$|async as nonPack; else loading">
    <nz-row [nzGutter]="16" nzJustify="center">
      <ng-container>
        <nz-col [nzSpan]="8">
          <nz-row [nzGutter]="50">
            <nz-col>
              <nz-statistic
                [nzValue]="nonPack.nonPackableState | productionUnit:false"
                [nzTitle]="'Aktueller Stand'"
              ></nz-statistic>
            </nz-col>
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">Baobab aus eigener Production: </div>
                <div class="ant-statistic-content">
                <span class="ant-statistic-content-value">
                  {{nonPack.isBio|booleanFormat}}
                </span>
                </div>
              </div>
            </nz-col>
            <nz-col>
              <div class="ant-statistic">
                <div class="ant-statistic-title">Bio </div>
                <div class="ant-statistic-content">
                  <span class="ant-statistic-content-value">{{nonPack.isBio|booleanFormat}}</span>
                </div>
              </div>
            </nz-col>
          </nz-row>
        </nz-col>
        <nz-col [nzSpan]="8">
          <ng-container *ngIf="nonPack.orderQuantitySum === null">
            <h2>Keine weitere Lieferung geplant</h2>
          </ng-container>
          <ng-container *ngIf="nonPack.orderQuantitySum !== null">
            <h2>Nächste Lieferung</h2>
            <nz-divider></nz-divider>
            <nz-row [nzGutter]="50">
              <nz-col>
                <div class="ant-statistic">
                  <div class="ant-statistic-title">Bestelltermin</div>
                  <div class="ant-statistic-content">
                    <span class="ant-statistic-content-value">{{nonPack.orderDate|dateFormat}}</span>
                  </div>
                </div>
              </nz-col>
              <nz-col><nz-statistic [nzValue]="(nonPack.orderSupplier?.name ?? '')" [nzTitle]="'Lieferant'"></nz-statistic></nz-col>
              <nz-col><nz-statistic [nzValue]="nonPack.orderQuantitySum | productionUnit:false" [nzTitle]="'Menge'"></nz-statistic></nz-col>
            </nz-row>
          </ng-container>
        </nz-col>
        <nz-col [nzSpan]="8">
          <h2>Kommentar</h2>
          <nz-divider></nz-divider>
          <nz-row [nzGutter]="50">
            <nz-col [nzSpan]="24">
              <div class="ant-statistic">
                <div class="ant-statistic-title">letzter Kommentar</div>
                <div class="ant-statistic-content">
                  <div class="comment">
                    {{ nonPack.articleComment?.comment ?? '-' }}
                  </div>
                  <br>
                  <button nz-button nzType="primary" (click)="showCommentControl = !showCommentControl">
                    Neuer Kommentar
                  </button>
                  <ng-container *ngIf="showCommentControl">
                    <textarea nz-input style="margin: 8px 0; height: 150px;" [(ngModel)]="newComment"></textarea>
                    <div class="d-flex justify-content-end">
                      <button nz-button nzType="default" (click)="saveComment()">
                        Neuer Kommentar speichern
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
            </nz-col>
          </nz-row>
        </nz-col>
      </ng-container>

    </nz-row>

    <nz-row>
      <nz-col [nzSpan]="6">
        <div class="ant-statistic">
          <div class="ant-statistic-title">
            Mindestlagerbestand festgelegt
          </div>
          <div class="ant-statistic-content">
            <div class="d-flex align-items-center">
              <input type="number" nz-input [(ngModel)]="nonPack.minStock">&nbsp;Stück
              <button
                nz-button
                nzType="primary"
                nzShape="circle"
                style="margin-left: 16px;"
                (click)="updateMinStock(nonPack.minStock)"
              >
                <span nz-icon nzType="check"></span>
              </button>
            </div>
          </div>
        </div>
      </nz-col>
    </nz-row>

    <div class="card-container">
      <nz-tabset nzType="card" nzLinkRouter>
      <nz-tab (nzSelect)="true">
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'deliveries' }"
           queryParamsHandling="merge">
          Lieferungen
        </a>
        <app-deliveries-list
          [isVariant]="true"
          [articleId]="articleId$|async"
        ></app-deliveries-list>
      </nz-tab>
      <nz-tab >
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'stock-stores' }"
           queryParamsHandling="merge">
          Lagerhaus übersicht
        </a>
        <app-article-variants-stores-stocks
          style="width: 100%"
          [articleId]="nonPack.articleVariant.articleId"
        ></app-article-variants-stores-stocks>
      </nz-tab>
      <nz-tab>
        <a *nzTabLink nz-tab-link
           [routerLink]="['.']"
           [queryParams]="{ tab: 'comments' }"
           queryParamsHandling="merge">
          Kommentare Geschichte
        </a>
        <app-comments-list
          [isVariant]="true"
          #commentsListComponent
          style="width: 100%"
          [articleId]="articleId$|async"
          (refreshArticle)="loadContent()"
        ></app-comments-list>
      </nz-tab>
    </nz-tabset>
    </div>
  </ng-container>
  <ng-template #loading>
    <nz-row [nzGutter]="16" nzJustify="center">
      <nz-col [nzXs]="24" [nzSm]="2">
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
      </nz-col>
    </nz-row>
  </ng-template>
</app-inner-box>
