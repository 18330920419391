import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IOpenOrder } from '../../core/interfaces';

@Component({
  selector: 'app-order-comments',
  templateUrl: './order-comments.component.html',
  styleUrls: ['./order-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderCommentsComponent implements OnInit {

  @Input() order: IOpenOrder;
  constructor() { }

  ngOnInit(): void {
  }

}
