import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-hint-icon',
  templateUrl: './hint-icon.component.html',
  styleUrls: ['./hint-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HintIconComponent implements OnInit {

  private _tooltipText

  @Input() set tooltipText(text: string) {
    this._tooltipText = text;
  }

  get tooltipText() {
    return this._tooltipText;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
