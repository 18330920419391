import { IUnit } from './IUnit';
import { IStock } from './IStock';
import { IPrice } from './IPrice';

export interface IArticleVariant {
  articleId?: number;
  articleName?: string;
  articleVariantConfigurationId?: number;
  ean?: string;
  id?: number;
  isDefault?: boolean;
  labelKey?: string;
  name?: string;
  orderNumber?: string;
  fullName?: string;
  packUnit?: string;
  purchaseUnit?: number;
  stockMin?: number;
  isActive?: boolean;
  isActiveForProduction?: boolean;
  unit?: IUnit;
  weight?: number;
  stocks?: IStock[];
  isEdited?: boolean;
  customWeight?: number;
  storageLocationNumber?: string;
  price?: IPrice;
  isNotRelevantForPacking: boolean;
}
