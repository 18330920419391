import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef, Renderer2, Output, EventEmitter
} from '@angular/core';
import { ListComponentGeneric } from '../../../../../ui/generics';
import { IBulkContainer } from '../../../core/interfaces/IBulkContainer';
import { NotificationService } from '../../../../../core/services';
import { BulkContainersService, BulkContainersServiceErrorResolverService } from '../../../core/services';

@Component({
  selector: 'app-deliveries-list',
  templateUrl: './deliveries-list.component.html',
  styleUrls: ['./deliveries-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveriesListComponent extends ListComponentGeneric<IBulkContainer> {
  @Input() isVariant = false;
  @Input() set articleId(val: number) {
    if(val) {
      this._articleId = val;
      this.predefindedFilters = [{
        value: [val.toString()],
        key: (this.isVariant ? 'articleVariantId':'articleId')
      }];
      this.loadDefault();
    }
  }

  @Output() deliveryCreated = new EventEmitter<void>();
  @Output() deliveryEdited = new EventEmitter<void>();
  @Output() deliveryCancelled = new EventEmitter<void>();
  @Output() changeLogCreated = new EventEmitter<void>();
  @Output() newProductionOrderCreated = new EventEmitter<void>();

  protected readonly exportedTableVisibleName = 'Deliveries list';

  private _articleId: number;

  constructor(
    protected _service: BulkContainersService,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BulkContainersServiceErrorResolverService
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  public get articleId(): number {
    return this._articleId;
  }

  cancelBulkContainer(deliveryId: number): void {
    this._service.cancel(deliveryId, true).subscribe({
      next: () => {
        this._notifyService.pushSuccess('Erfolgreich', `Lieferung korrekt storniert`);
        this.onRefreshList(false);
        this.deliveryCancelled.emit();
      },
      error: this.parseError
    });
  }
}
