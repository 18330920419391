import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users/users.component';
import { AddEditModalComponent } from './add-edit-modal/add-edit-modal.component';
import { AddEditFormComponent } from './add-edit-form/add-edit-form.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { UiModule } from '../../ui/ui.module';
import { CoreModule } from '../../core/core.module';
import { EditMyProfileModalComponent } from './edit-my-profile-modal/edit-my-profile-modal.component';
import { EditMyProfileFormComponent } from './edit-my-profile-form/edit-my-profile-form.component';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';

@NgModule({
  declarations: [ListComponent, UsersComponent, AddEditModalComponent, AddEditFormComponent, EditMyProfileModalComponent, EditMyProfileFormComponent, TwoFactorAuthenticationComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    NzAvatarModule,
    UiModule,
    CoreModule,
  ],
    exports: [
        ListComponent,
        EditMyProfileModalComponent
    ]
})
export class UsersModule { }
