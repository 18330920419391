import { Observable } from 'rxjs';
import { ApiService } from './ApiService';
import { IService } from './IService';
import { ITableResults } from '../interfaces';
import { HttpUtils } from '../utils/HttpUtils';

export class GenericService<T> implements IService<T> {

  constructor(public apiService: ApiService) { }

  protected baseUrl = '';

  getQueryableAll(pageIndex: number,
                   pageSize: number,
                   sortField: string | null,
                   sortOrder: string | null,
                   filters: Array<{ key: string; value: string[] }>,
                   search: Array<{ key: string; value: string[] }>,
                  additionalFilters: Array<{ key: string; value: string[] }>
  ): Observable<ITableResults<T>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters);
    return this.apiService.get(`${this.baseUrl}`, params );
  }

  getById(id: string): Observable<T> {
    return this.apiService.get(`${this.baseUrl}/${id}`);
  }

  delete(id: string): Observable<T> {
    return this.apiService.delete(`${this.baseUrl}/${id}`);
  }

  update(object: T, id: number) {
    return this.apiService.put(`${this.baseUrl}/${id}`, object);
  }

  create(object: T) {
    return this.apiService.post(`${this.baseUrl}`, object);
  }
}
