import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input, Output,
  Renderer2
} from '@angular/core';
import { ListComponentGeneric } from '../../../../../ui/generics';
import { BulkContainersServiceErrorResolverService } from '../../../core/services';
import { NotificationService } from '../../../../../core/services';
import { ArticlesCommentsService } from '../../../../articles/core/ArticlesCommentsService';
import { IArticleComment } from '../../../../../core/interfaces/IArticleComment';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsListComponent extends ListComponentGeneric<IArticleComment> {
  @Input() isVariant = false;
  @Input() set articleId(val: number) {
    if (val) {
      this._articleId = val;

      if (!this.isVariant) {
        this.predefindedFilters = [
          {
            value: [val.toString()],
            key: 'articleId'
          },
          {
            value: ['null'],
            key: 'articleVariantId',
            operator: 'eq'
          }
        ];
      } else {
        this.predefindedFilters = [{
          value: [val.toString()],
          key: 'articleVariantId'
        }];
      }

      this.loadDefault();
    }
  }

  @Output() refreshArticle = new EventEmitter<void>();

  protected readonly exportedTableVisibleName = 'Comments list';

  private _articleId: number;

  constructor(
    protected _service: ArticlesCommentsService,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BulkContainersServiceErrorResolverService
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  public get articleId(): number {
    return this._articleId;
  }

  public deleteComment(id: number): void {
    this._service.delete(id.toString()).subscribe({
      next: () => {
        this._notifyService.pushSuccess('Erfolgreich', `Kommentar gelöscht`);
        this.onRefreshList(false);
        this.refreshArticle.emit();
      },
      error: this.parseError
    });
  }

  public updateComment(val: IArticleComment): void {
    if (val.isEdited) {
      this._service.update(val, val.id).subscribe({
        next: () => {
          this._notifyService.pushSuccess('Erfolgreich', `Kommentar aktualisiert`);
          this.onRefreshList(false);
          this.refreshArticle.emit();
        },
        error: this.parseError
      });
    } else {
      val.isEdited = true;
    }
  }
}
