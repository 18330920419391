import { IGlobalModal } from '../directives';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalModalsService {

  private _registeredModals: {key: string; modalRef: IGlobalModal}[] = [];


  registerModal(key: string, modalRef: IGlobalModal) {
    this._registeredModals.push({
      key,
      modalRef
    });
  }

  openModal(key, object = null) {
    const modal = this._registeredModals.find(value => value.key === key);
    if (modal) {
      modal.modalRef.showModal(object);
    }
  }
}
