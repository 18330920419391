export enum LabelColors {
  Chilli = '0',
  Curry = '1',
  Herbs = '2',
  Mix = '3',
  Paprika = '4',
  Pepper = '5',
  Salt = '6',
  SingleSpices = '7',
}
