import { Directive, ElementRef, OnInit } from '@angular/core';
import { LocalStorageService } from '../services';

@Directive({
  selector: '[appKeepState]'
})
export class KeepStateDirective implements OnInit {

  private readonly _id: string;

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _localStorageService: LocalStorageService
  ) {
    this._id = this._elementRef.nativeElement.getAttribute('id');
  }

  public ngOnInit(): void {}

  public getValue(): any {
    return this._localStorageService.getValue(this._id);
  }

  public set item(value: any) {
    this._localStorageService.item = {
      value,
      key: this._id
    };
  }

  public removeItem(): void {
    this._localStorageService.removeItem(this._id);
  }
}
