import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, Input } from '@angular/core';
import { ListComponentGeneric } from '../../../ui/generics';
import { NotificationService } from '../../../core/services';
import { IStoreStocksCorrectionsListItem } from '../../../pages/stores-stocks/core/interfaces/IStoreStocksCorrectionsListItem';
import { StoreStocksService } from '../../../pages/stores-stocks/core/services/StoreStocks.service';
import { BulkContainersServiceErrorResolverService } from '../../../pages/bulk-containers/core/services';

@Component({
  selector: 'app-store-stocks-corrections-list',
  templateUrl: './store-stocks-corrections-list.component.html',
  styleUrls: ['./store-stocks-corrections-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreStocksCorrectionsListComponent extends ListComponentGeneric<IStoreStocksCorrectionsListItem> implements OnInit {

  @Input() object: { articleVariantId: number, storeId: number };

  protected readonly exportedTableVisibleName = 'Store stocks corrections';
  protected searchColumns = [
    'createdAt',
    'user.fullName',
    'oldInStock',
    'newInStock',
    'comment',
  ];

  constructor(
    protected _service: StoreStocksService<IStoreStocksCorrectionsListItem>,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BulkContainersServiceErrorResolverService
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  callService(pageIndex: number,
               pageSize: number,
               sortField: string | null,
               sortOrder: string | null,
               filters: Array<{ key: string; value: string[] }>,
               search: Array<{ key: string; value: string[] }>,
              additionalFilters: Array<{ key: string; value: string[] }>,
  ) {
    this.loading = true;
    if (this.object) {
      filters = [
        ...filters,
        {
          key: 'store.id',
          value: [this.object.storeId.toString()]
        },
        {
          key: 'articleVariant.id',
          value: [this.object.articleVariantId.toString()]
        }
      ];
    }
    this._service.getCorrections(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters).subscribe(res => {
      this.loading = false;
      this.data = res;
      this._cdr.detectChanges();
    }, this.parseError);
  }
}
