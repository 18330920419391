import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from '../../core/services';
import { GenericService } from '../../core/services/GenericService';
import { BaseServiceErrorResolverService } from '../../core/services/error-resolvers/base-service-error-resolver.service';
import { environment } from '../../../environments/environment';
import { ISimpleListItem } from '../../core/interfaces/ISimpleListItem';

@Component({
  template: ''
})
export abstract class AddEditFormGeneric<T> implements OnInit {

  @Input() set editObject(val: T | null) {
    this._editObject = val;
    this.loadForm();
  }

  @Output() formFinished = new EventEmitter<boolean>();

  dateFormat = environment.defaultDateFormat;
  form: FormGroup;
  isSubmitted = false;
  isEdit = false;
  public loading = false;
  protected _editObject: T | null = null;

  protected constructor(
    protected _fb: FormBuilder,
    protected _formService: GenericService<T>,
    protected _notifyService: NotificationService,
    protected _errorResolver: BaseServiceErrorResolverService
  ) {}

  ngOnInit(): void {}

  get editObject(): T | null {
    return this._editObject;
  }

  loadForm(): void {
    if (this.editObject) {
      this.loading = true;
      this.isEdit = true;
      this._formService.getById(this.editObject['id']).subscribe(value => {
        this._editObject = value;
        this.beforeLoadForm();
        this.form.patchValue(this.editObject);
        this.afterLoadForm();
        this.loading = false;
      }, error => this._notifyService.pushError('Fehler', 'Fehler beim Laden des Artikels.'));
    }
  }

  submitForm(): void {
    this.isSubmitted = true;
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      }
    }

    if (!this.form.valid) {
      this.formFinished.emit(false);
      return;
    }

    if (this.editObject !== null) {
      this.saveEdit();
      return;
    }

    this.saveNew();
  }

  saveEdit(): void {
    const object: T = {...this.editObject, ...this.form.value};
    this._formService.update(object, this.editObject['id']).subscribe(() => {
        this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt aktualisiert.`);
        this.formFinished.emit(true);
      }, err => {
        this.parseError(err);
        this.formFinished.emit(false);
      });
  }

  saveNew(): void {
    const object: T = this.form.value;
    this._formService.create(object).subscribe(() => {
        this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt erstellt`);
        this.formFinished.emit(true);
      }, err => {
        this.parseError(err);
        this.formFinished.emit(false);
      });
  }

  parseError = data => {
    this._notifyService.pushError(undefined, this._errorResolver.getMessage((data && data.InternalCode) ? data.InternalCode : ''));
  }

  compareSimpleList(val1: ISimpleListItem, val2: ISimpleListItem): boolean {
    return val1?.id?.toString() === val2?.id?.toString();
  }

  beforeLoadForm() {}

  protected afterLoadForm(): void {}
}
