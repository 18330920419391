import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from '../../../../core/services/error-resolvers/base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class StoreStocksErrorResolverService extends BaseServiceErrorResolverService {
  codes = {
    BulkContainerNotFound: 'Großbehälter nicht gefunden'
  };

}
