<span #refModalTrigger><ng-content select="[trigger]"></ng-content></span>
<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>

    <nz-spin [nzSpinning]="loading">
      <nz-row>
        <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name" nzRequired>
              <span>Menge</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Bitte Menge eingeben!">
              <nz-input-number id="quantity" formControlName="quantity" [nzMin]="0" [nzStep]="1"></nz-input-number>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name" nzRequired>
              <span>Bemerkung</span>
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24"  nzErrorTip="Bitte Bemerkung eingeben!">
              <textarea rows="4" nz-input formControlName="comment"></textarea>
            </nz-form-control>
          </nz-form-item>
        </form>
      </nz-row>
    </nz-spin>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading"  [disabled]="!form.valid || !form.controls.quantity.dirty">Speichern</button>
  </ng-template>
</nz-modal>
