<button nz-button nzType="primary" class="clear-cache" (click)="clearCache()">Cache leeren / Sync</button>
<br>
<br>
<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <div style="margin-right: auto; width: 500px" nz-row>
    <nz-form-item nz-col [nzSpan]="12" class="center">
      <label
        nz-checkbox
        [(ngModel)]="filter1"
        (ngModelChange)="filter2 = false; filter3 = false; onRefreshList()"
      >
        Abpackliste Lagerbestand < 5
      </label>
    </nz-form-item>
    <nz-form-item nz-col [nzSpan]="12" class="center">
      <label
        [(ngModel)]="filter2"
        (ngModelChange)="filter1 = false; filter3 = false; onRefreshList()"
        nz-checkbox
      >
        Abpackliste Lagerbestand < 25
      </label>
    </nz-form-item>
    <nz-form-item nz-col [nzSpan]="12" class="center">
      <label
        nz-checkbox
        [(ngModel)]="filter3"
        (ngModelChange)="filter1 = false; filter2 = false; onRefreshList()"
      >
        Nicht relevant für Abfüllliste
      </label>
    </nz-form-item>
  </div>

  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>

<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="store-stocks-list-table"
>
  <thead>
  <tr>
    <th nzColumnKey="id" [nzSortFn]="true" nzWidth="75px">#ID</th>
    <th nzWidth="200px" nzColumnKey="orderNumber" [nzSortFn]="true">Order number</th>
    <th nzColumnKey="articleName" [nzSortFn]="true"  nzWidth="250px">Article</th>
    <th nzColumnKey="name" [nzSortFn]="true"  nzWidth="250px">Variant</th>
    <th nzWidth="150px" nzColumnKey="inStock" [nzSortFn]="true">Lagerbestand</th>
    <th nzWidth="150px" nzColumnKey="plannedProductionState" [nzSortFn]="true">Geplante Production</th>
    <th nzWidth="150px" nzColumnKey="bulkContainerState" [nzSortFn]="true">
      Großgebinde
    </th>
    <th nzColumnKey="ean" [nzSortFn]="true">EAN</th>
    <th nzColumnKey="actions" nzRight nzWidth="100px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let storeStocksListItem of data.results">
    <td>{{storeStocksListItem.id}}</td>
    <td>{{storeStocksListItem.orderNumber}}</td>
    <td>{{storeStocksListItem.articleName}}</td>
    <td>{{storeStocksListItem.name}}</td>
    <td>{{storeStocksListItem.inStock}}</td>
    <td>{{storeStocksListItem.plannedProductionState}}</td>
    <td>
      {{(storeStocksListItem.bulkContainerState | productionUnit:true) }}
    </td>
    <td>{{storeStocksListItem.ean}}</td>
    <td nzRight>
      <div class="actions" style="align-items: center;">
        <app-add-edit-article-modal (refresh)="onRefreshList(false)" #addEditArticleModalComponent>
          <span trigger>
              <i
                nz-icon
                nzType="edit"
                nzTheme="outline"
                (click)="openModal(storeStocksListItem.articleId, storeStocksListItem.articleName + ' ' + storeStocksListItem.name)"
              ></i>
          </span>
        </app-add-edit-article-modal>

        <a
          *ngIf="storeStocksListItem.bulkContainerState !== null"
          routerLink="/bulk-containers/bulk-container/{{ storeStocksListItem.articleId }}"
          [queryParams]="{ tab: 'deliveries' }"
        >
          <span nz-icon nzType="shopping-cart" nzTheme="outline"></span>
        </a>
      </div>
    </td>
  </tr>

  </tbody>
</nz-table>
<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
