import { BioKinds, LabelColors, LabelTypes } from '../enums';

export interface IArticleLabelInfo {
  ingredients: string;
  nutritionalValues: string;
  articleNameFirstLine: string;
  articleNameSecondLine: string;
  featured: string;
  mhd: string;
  descriptionFirstLine: string;
  descriptionSecondLine: string;
  additionalJapanText: string;
  bioKind: BioKinds;
  labelColor: LabelColors;
  labelType: LabelTypes;
  hasSmallerTitle: boolean;
  hasSmallerText: boolean;
}
