import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NotificationService } from './NotificationService';

@Injectable()
export class StaticDependenciesService {
  private static _renderer: Renderer2;
  private static _notificationService: NotificationService;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _rendererFactory: RendererFactory2,
  ) {
    StaticDependenciesService._renderer = this._rendererFactory.createRenderer(null, null);
    StaticDependenciesService._notificationService = _notificationService;
  }

  public static get renderer(): Renderer2 {
    if (!StaticDependenciesService._renderer) {
      throw new Error('Static Dependencies Service hasn\'t been initialized');
    }

    return StaticDependenciesService._renderer;
  }

  public static get notificationService(): NotificationService {
    if (!StaticDependenciesService._notificationService) {
      throw new Error('Static Dependencies Service hasn\'t been initialized');
    }

    return StaticDependenciesService._notificationService;
  }
}
