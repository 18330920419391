import { Injectable } from '@angular/core';
import { GenericService } from '../../../../core/services/GenericService';
import { ApiService } from '../../../../core/services';
import { IStockTransfer } from '../interfaces/IStockTransfer';
import { Observable } from 'rxjs';
import { IStockTransferVariantSimple } from '../interfaces/IStockTransferVariantSimple';
import { HttpParams } from '@angular/common/http';
import { IStockTransferChangeLog } from '../interfaces/IStockTransferChangeLog';

@Injectable({
  providedIn: 'root'
})
export class StockTransfersService extends GenericService<IStockTransfer> {

  protected readonly baseUrl = '/StockTransfers';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  public getVariants(phrase: string): Observable<IStockTransferVariantSimple[]> {
    const params = new HttpParams({
      fromObject: {
        search: phrase
      }
    });
    return this.apiService.get(`${ this.baseUrl }/variants/simple`, params);
  }

  public getArticleVariantByEanAndOriginStoreIdAndTargetStoreId(
    ean: string,
    originStoreId: number,
    targetStoreId: number
  ): Observable<IStockTransferVariantSimple> {
    const params = new HttpParams({
      fromObject: {
        ean,
        originStoreId: originStoreId.toString(),
        targetStoreId: targetStoreId.toString()
      }
    });

    return this.apiService.get(`${ this.baseUrl }/article-variant`, params);
  }

  public removeArticle(stockTransferId: number, articleVariantId: number): Observable<any> {
    return this.apiService.post(`${ this.baseUrl }/${ stockTransferId }/remove-article`, { stockTransferId, articleVariantId });
  }

  public dispatchTransfer(id: number): Observable<any> {
    return this.apiService.post(`${ this.baseUrl }/${ id }/dispatch`);
  }

  public bookTransfer(id: number): Observable<any> {
    return this.apiService.post(`${ this.baseUrl }/${ id }/book`);
  }

  public cancelTransfer(id: number): Observable<any> {
    return this.apiService.post(`${ this.baseUrl }/${ id }/cancel`);
  }

  public getChangeLog(transferNumber: string): Observable<IStockTransferChangeLog[]> {
    return this.apiService.get(`${this.baseUrl}/${ transferNumber }/change-logs`);
  }

  public exportCSV(ids: number[]): Observable<any> {
      let params = new HttpParams();
      ids.forEach((id) => {
        params = params.append(`ids`, id.toString());
      });

      return this.apiService.getFile(`${this.apiService.getApiUrl()}${ this.baseUrl }/export-stock-transfers`, 'application/csv', params);
  }
}
