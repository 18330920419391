import { Pipe, PipeTransform} from '@angular/core';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'sortMinutesAndHoursSelectOptions'
})
export class SortMinutesAndHoursSelectOptionsPipe implements PipeTransform {

  public transform(value: KeyValue<number, string>[]): KeyValue<number, string>[] {

    const minutesArr: KeyValue<number, string>[] = [];
    const hoursArr: KeyValue<number, string>[] = [];

    value.forEach(x => x.value.includes('Minuten') ? minutesArr.push(x) : hoursArr.push(x));

    return minutesArr.sort(this.sort).concat(hoursArr.sort(this.sort));
  }

  private sort(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return parseFloat(a.value) > parseFloat(b.value) ? 1 : -1;
  }
}
