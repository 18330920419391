import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2,
  AfterViewInit, OnDestroy, ViewChild
} from '@angular/core';
import { NotificationService } from '../../../core/services';
import { StoreStocksService } from '../core/services/StoreStocks.service';
import { StoreStocksErrorResolverService } from '../core/error-resolvers/store-stocks-error-resolver.service';
import { ListComponentGeneric } from '../../../ui/generics';
import { AddEditStockModalComponent } from '../add-edit-stock-modal/add-edit-stock-modal.component';
import { IStoreStock } from '../core/interfaces/IStoreStock';
import { ToolsService } from '../../../core/services/ToolsService';
import { AddEditArticleModalComponent } from '../../../shared/modals/add-edit-article-modal/add-edit-article-modal.component';
import { IArticle } from '../../../core/interfaces';
import { FilterOperator } from '../../../core/types';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends ListComponentGeneric<IStoreStock> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('addEditArticleModalComponent') addEditArticleModalComponent: AddEditArticleModalComponent;

  public filter1 = false;
  public filter2 = false;
  public filter3 = false;
  searchColumns = [
    'id',
    'orderNumber',
    'articleName',
    'name',
    'inStock',
    'plannedProductionState',
    'bulkContainerState',
    'ean',
  ];

  protected readonly exportedTableVisibleName = 'Stores stocks list';

  private readonly _additionalFiltersConditions = [
    { key: 'isPackable', value: ['true'] },
    { key: 'isActive', value: ['true'] }
  ];

  public constructor(
    protected _storeStockService: StoreStocksService<IStoreStock>,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: StoreStocksErrorResolverService,
    private readonly _toolsService: ToolsService,
  ) {
    super(_storeStockService, _cdr, _notifyService, renderer, _errorResolver);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  callService( pageIndex: number,
               pageSize: number,
               sortField: string | null,
               sortOrder: string | null,
               filters: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
               search: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
               additionalFilters: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
  ) {
    if (this.filter1) {
      filters = [
        { key: 'isNotRelevantForPacking', value: ['false'] },
        { key: 'inStock', value: ['5'], operator: 'lt' },
        ...this._additionalFiltersConditions
      ];
    } else if (this.filter2) {
      filters = [
        { key: 'isNotRelevantForPacking', value: ['false'] },
        { key: 'inStock', value: ['25'], operator: 'lt' },
        ...this._additionalFiltersConditions
      ];
    } else if (this.filter3) {
      filters = [
        { key: 'isNotRelevantForPacking', value: ['true'] },
        ...this._additionalFiltersConditions
      ];
    } else {
      filters = [];
    }

    this.loading = true;
    this._cdr.detectChanges();

    this._storeStockService.getQueryableAll(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters).subscribe(res => {
      this.loading = false;
      this.data = res;
      this._cdr.detectChanges();
    }, this.parseError);
  }


  showModal(modal: AddEditStockModalComponent, articleVariantId: number, id: string) {
    modal.showModal({articleVariantId: articleVariantId, storeId: Number(id)});
  }

  public loadList(): void {
    const { pageIndex, pageSize, filter, sortField, sortOrder, search } = this.lastLoadParams;
    this.load(pageIndex, pageSize, sortField, sortOrder, filter, search);
  }

  public openModal(articleId: number, name: string): void {
    setTimeout(() => {
      this.addEditArticleModalComponent.showModal({ id: articleId, name } as IArticle);
      this._cdr.detectChanges();
    }, 0);
  }

  public clearCache(): void {
    this.loading = true;
    this._cdr.detectChanges();

    this._toolsService.clearCache().subscribe({
      next: this.loadList.bind(this),
      error: this.parseError.bind(this)
    });
  }
}
