import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from '../../../../../core/services/error-resolvers/base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class FlourErrorResolverService extends BaseServiceErrorResolverService {
  public codes = {
    'PackingListNotFound': 'Packliste nicht gefunden.',
    'EmptyFlourReorderList': 'Leere Flour-Bestellliste'
  };
}
