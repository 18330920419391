import { Directive } from '@angular/core';

@Directive({
  selector: '[appTextInputOnlyNumbers]',
  host: {
    '(keypress)': 'keyPress($event)'
  }
})
export class TextInputOnlyNumbersDirective {

  constructor() {}

  public keyPress(event): boolean {
    return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 44 || event.charCode === 46) ||
      event.charCode === 45;
  }
}
