import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/guards';
import { OpenOrdersComponent } from './open-orders/open-orders.component';
import { ClosedOrdersComponent } from './closed-orders/closed-orders.component';

const routes: Routes = [
  {
    path: 'open',
    component: OpenOrdersComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'closed-today',
    component: ClosedOrdersComponent,
    canActivate: [ AuthGuard ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
