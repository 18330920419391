<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1200"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <div style="margin-left: 15px" >
      <button nz-button nzType="primary" (click)="generate()">
        <i nz-icon nzType="play-circle" nzTheme="outline"></i><span>Generieren Sie</span>
      </button>
    </div>

    <nz-spin [nzSpinning]="false">
      <nz-divider></nz-divider>
      <nz-row >
        <nz-col nzFlex="24">
          <nz-table
            #tableRef
            nzShowSizeChanger
            [nzData]="orders"
            [nzShowPagination]="false"
            class="articles-table"
          >
            <thead>
            <tr>
              <th nzColumnKey="id" nzWidth="100px">#ID</th>
              <th nzColumnKey="fullDate">Datum</th>
              <th nzColumnKey="number" >Bestellnummer</th>
              <th nzColumnKey="customer">Kunde</th>
              <th nzColumnKey="status">Aktueller<br>Bestellstatus</th>
              <th nzColumnKey="status">Versand</th>
              <th nzColumnKey="status">Versandetiketten<br>status</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let order of orders let i = index">
              <td>{{order.id}}</td>
              <td>{{order.fullDate|dateFormat}}</td>
              <td>{{order.number}}  <app-order-comments [order]="order"></app-order-comments></td>
              <td>{{order.customer}}</td>
              <td>{{order.status}}</td>
              <td>{{order.deliveryMethodName}}</td>
              <td nzAlign="center">
                <i nz-icon nzType="check-circle" nzTheme="twotone" nzTwotoneColor="#52c41a" *ngIf="order.trackingCode || order.deliverySlipStatus === 1"></i>
                <i nz-icon nzType="close-circle" nzTheme="twotone" nzTwotoneColor="#eb2f96" *ngIf="order.deliverySlipStatus === 2" nz-tooltip [nzTooltipTitle]="order.deliverySlipError"></i>
                <i style="color: blue; font-size: 18px" nz-icon nzType="loading"  *ngIf="order.deliverySlipStatus === 0"></i>
              </td>
            </tr>
            </tbody>
          </nz-table >
        </nz-col>
      </nz-row>
    </nz-spin>

  </ng-template>

  <ng-template #modalFooter>
    <nz-row>
      <nz-col nzFlex="6">
        <button nz-button nzType="default" (click)="handleCancel()" [disabled]="loading">Schließen</button>
      </nz-col>
    </nz-row>

  </ng-template>
</nz-modal>
