import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { GenericService } from '../../../../core/services/GenericService';
import { IGiftboxSimple } from '../interfaces/IGiftboxSimple';
import { HttpParams } from '@angular/common/http';
import { IGiftboxFormula } from '../interfaces/IGiftboxFormula';
import { Observable } from 'rxjs';
import { HttpError } from '../../../../core/decorators/http-error.decorator';
import { GiftboxesErrorCodes } from '../enums/GiftboxesErrorCodes';

@Injectable({
  providedIn: 'root'
})
export class GiftboxesService extends GenericService<IGiftboxSimple> {
  protected readonly baseUrl = '/Giftboxes';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  bookGiftbox(articleVariantId: number, quantity: number) {
    const params = new HttpParams()
      .append('quantity', `${quantity}`);
    return this.apiService.post(`${this.baseUrl}/${articleVariantId}/book?quantity=${quantity}` );
  }

  getFormulaGiftBox(articleVariantId: number): Observable<IGiftboxFormula[]> {
    return this.apiService.get(`${this.baseUrl}/articles/${articleVariantId}/formula-giftbox`);
  }

  updateFormulaGiftBox(articleVariantId: number, object: { variantIds: number[]; }) {
    return this.apiService.put(`${this.baseUrl}/articles/${articleVariantId}/formula-giftbox`, object);
  }

  public exportCSV(ids: number[]): Observable<any> {
    let params = new HttpParams();
    ids.forEach((id) => {
      params = params.append(`ids`, id.toString());
    });

    return this.apiService.getFile(`${this.apiService.getApiUrl()}${ this.baseUrl }/generate-export-giftbox`, 'application/csv', params);
  }

  @HttpError(GiftboxesErrorCodes)
  public generatePDF(ids: number[]): Observable<Blob> {
    let params = new HttpParams();

    ids.forEach((id) => params = params.append(`ids`, id.toString()));

    return this.apiService.getFile(
      `${ this.apiService.getApiUrl() }${ this.baseUrl }/generate-export-giftbox-pdf`,
      'application/pdf',
      params
    );
  }
}
