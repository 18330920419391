import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { AddEditFormComponent } from '../add-edit-form/add-edit-form.component';
import { AddEditModalGeneric } from '../../../ui/generics';
import { IStore } from '../core/interfaces/IStore';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditModalComponent extends AddEditModalGeneric<IStore, AddEditFormComponent> implements OnInit {

  constructor(
    protected _cdr: ChangeDetectorRef
  ) {
    super(_cdr);
  }
}
