import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { JwtService } from '../services';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
    };

    if (!req.url.includes('refresh-token') && !req.url.includes('bbm-image-tmp.s3')) {
      const token = this.jwtService.getAccessToken();

      if (token) {
        headersConfig['Authorization'] = `Bearer ${token}`;
      }

      if (!req.url.includes('open') && !req.url.includes('download') && !req.url.includes('export-delivery-notes')) {
          headersConfig['Accept'] = 'application/json';
      }
      const request = req.clone({ setHeaders: headersConfig });
      return next.handle(request).pipe(
        catchError(obj => {
          const errorClone = {
            ...obj.error
          };

          delete obj.error;

          obj.error = {
            ...errorClone,
            request: {
              ...obj
            }
          };

          return throwError(obj);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
