<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  (nzAfterOpen)="afterModalOpen()"
  [nzWidth]="1000"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <div class="manual-input-switch">
      Manuelle Eingabe
      <nz-switch [(ngModel)]="isManualInput"></nz-switch>
    </div>
    <nz-row nzJustify="space-between">
      <nz-col nzFlex="12" class="scan-field">
        Die Bestellnummer scannen: <input [(ngModel)]="orderNumber" (ngModelChange)="handleNumberScan($event)" appPossitiveNumbers #scanField nz-input>
        &nbsp;<button nz-button nzType="primary">
          <i nz-icon nzType="barcode" nzTheme="outline" (click)="handleBarcodeScanner()"></i>
        </button>
      </nz-col>
      <nz-col nzFlex="12" class="delivery-slips">
        <app-order-comments *ngIf="this.deliveryNote" [order]="deliveryNote" ></app-order-comments>
        <i class="im im-truck"></i>&nbsp;<i nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"  *ngIf="deliveryNote?.trackingCode"></i>
        <i nz-icon [nzType]="'close-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"  *ngIf="!deliveryNote?.trackingCode"></i>
      </nz-col>
    </nz-row>

    <nz-spin [nzSpinning]="loading">
      <nz-divider></nz-divider>
      <nz-row >
        <nz-col nzFlex="24">
          <div *ngIf="isProcessedCorrect" class="processed-correct">
            <span nz-icon [nzType]="'check-circle'"></span>
            RICHTIG VERARBEITET
          </div>
          <nz-table
            #tableRef
            nzShowSizeChanger
            [nzData]="deliveryNote?.articles"
            [nzShowPagination]="false"
            *ngIf="!isProcessedCorrect"
            class="articles-table"
          >
            <thead>
            <tr>

              <th>Pos.</th>
              <th>Lagerplatznr.</th>
              <th>Art-Nr.</th>
              <th>EAN</th>
              <th>Produktname</th>
              <th>ESD</th>
              <th>Menge</th>
              <th>Gescannt</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let variant of deliveryNote?.articles; let i = index" [class]="(variant.isFull() ? 'finished' : (variant.scanned > 0 ? 'partly-finished':''))" (dblclick)="markAsFull(variant.id)">
              <td>{{i + 1}}</td>
              <td>{{variant.storageLocationNumber}}</td>
              <td>{{variant.orderNumber}}</td>
              <td>{{variant.ean}}</td>
              <td>{{variant.name}}</td>
              <td>{{variant.isEsd | booleanFormat}}</td>
              <td>{{variant.quantity}} {{variant.packUnit}}</td>
              <td>{{(variant.scanned ? variant.scanned : 0)}} {{variant.packUnit}}</td>
              <td>
                <div class="actions">
                    <span nz-icon
                          [nzType]="'check-circle'"
                          [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'" (click)="markAsFull(variant.id)"></span>
                </div>
              </td>
            </tr>
            </tbody>
          </nz-table >
        </nz-col>
      </nz-row>
      <div class="ean-scanner--container">
        <span>Artikel scannen: </span><input [(ngModel)]="ean" (ngModelChange)="handleEANScan($event)" #eanField>
      </div>
    </nz-spin>

  </ng-template>

  <ng-template #modalFooter>
    <nz-row>
      <nz-col >
        <button nz-button nzType="default"
                nz-popconfirm
                nzPopconfirmTitle="Es gibt keine Möglichkeit, dies rückgängig zu machen. Stellen Sie sicher, dass Sie die Bestellung als 'Komplett ausgeliefert' umstellen möchten"
                nzPopconfirmPlacement="bottom"
                (nzOnConfirm)="handleForceFinish()"
                [disabled]="!this.deliveryNote"
                >Bestellung ohne Versandetikette ausliefern</button>
        <button nz-button nzType="default"
                (click)="notCompletedOrder(reasonTpl)"
                [disabled]="!this.deliveryNote"
                >Bestellung auf "Position unvollständig" setzen</button>
      </nz-col>
      <nz-col nzFlex="6">
        <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
      </nz-col>
    </nz-row>

  </ng-template>
</nz-modal>

<app-document-pdf-safari-modal [pdfLink]="pdfLink"></app-document-pdf-safari-modal>

<ng-template #reasonTpl>
  <label for="">Internal Comment</label>
  <textarea rows="4" nz-input [(ngModel)]="uncompletedReason" ></textarea>
</ng-template>

