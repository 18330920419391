import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from '../../../../../core/services/error-resolvers/base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class OpenOrdersServiceErrorResolverService extends BaseServiceErrorResolverService {
  codes = {
    RequiredDocumentsNotGenerated: 'Die erforderlichen Dokumente wurden noch nicht erstellt, erzeugen Sie sie und versuchen Sie es erneut',
    OrderOpenDocumentNotFound: 'Bestellung konnte nicht gefunden werden, bitte aktualisieren Sie die Seite und versuchen Sie es erneut',
    PartialDocument: 'Batch-dokument wurde teilweise graviert, Aufträge prüfen',

    ProductNotExists: 'In dieser Bestellung ist das Produkt nicht verfügbar',
    ProductTooMuch: 'Das Produkt ist bereits in ausreichender Menge.',
    OrderNotLoaded: 'Der Auftrag wurde nicht geladen.',
    End: 'Ihre Bestellung ist komplett. Der Status wurde auf „Komplett ausgeliefert” geändert',
    EndError: 'Ihre Bestellung wurde nicht erfolgreich abgeschlossen. Bitte wenden Sie sich an unser Support Team.',
    EndTitle: 'Ihre Bestellung wurde abgeschlossen',
    TrackCodeNotCorrect: 'Die DHL/UPS Kartennummer ist  inkorrekt',
    TrackingCodeNotExists: 'DHL/UPS Schein wurde dem Auftrag nicht zugeordnet',
    CantFinish: 'In diesem Fall können Sie das Prozess nicht beenden'
  }
}
