<div #refModalTrigger><ng-content select="[trigger]"></ng-content></div>
<div *ngIf="(refModalTrigger.children.length == 0)">
  <button nz-button nzType="primary" (click)="showModal(null)">
    <i nz-icon nzType="plus-circle" nzTheme="outline"></i><span>{{ triggerTitle }}</span>
  </button>
</div>
<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
  (nzAfterOpen)="afterModalOpen()"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <div class="manual-input-switch">
      Manuelle Eingabe
      <nz-switch [(ngModel)]="isManualInput"></nz-switch>
    </div>
    <div class="scan-field">
      Die Auftragsnummer scannen: <textarea [ngModel]="transferNumber" [value]="transferNumber" (ngModelChange)="handleNumberScan($event)"#scanField nz-input  rows="1"></textarea>
      &nbsp;<button nz-button nzType="primary">
      <i nz-icon nzType="barcode" nzTheme="outline" (click)="handleBarcodeScanner()"></i>
    </button>
    </div>
    <app-stock-transfer-process-form
      #form
      [packingListDocumentNumber]="numberChanged$ | async"
      (clearScanField)="clearScanField()"
      [windowType]="this.object?.type"
    ></app-stock-transfer-process-form>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
  </ng-template>
</nz-modal>
