<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th nzColumnKey="createdAt" [nzSortFn]="true">Hergestellt in</th>
    <th nzColumnKey="user.fullName" [nzSortFn]="true">Benutzer</th>
    <th nzColumnKey="oldInStock" [nzSortFn]="true">Alt im Laden</th>
    <th nzColumnKey="newInStock" [nzSortFn]="true">Neu im Laden</th>
    <th [nzSortFn]="true">Bestand</th>
    <th nzColumnKey="comment" [nzSortFn]="true">Bemerkung</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let result of data.results">
    <td>{{ result.createdAt | datetimeFormat }}</td>
    <td>{{ result.user.fullName }}</td>
    <td>{{ result.oldInStock }}</td>
    <td>{{ result.newInStock }}</td>
    <td>
      <span
        [style.color]="'var('+ (result.newInStock - result.oldInStock > 0 ? '--nz-success-color' : '--nz-error-color') +')'"
      >
        {{ result.newInStock - result.oldInStock }}
      </span>
    </td>
    <td>{{ result.comment }}</td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
