<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <app-download-documents [documentKeys]="selectedDocumentKeys"></app-download-documents>
  <div class="uncompleted-orders-checkbox-wrapper">
    <label
      nz-checkbox
      [(ngModel)]="showUncompletedOrders"
      (ngModelChange)="valueChanged()"
    >Offene Produktionsaufträge</label>
  </div>
  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="production-orders-list-table"
>
  <thead>
  <tr>
    <th
      [(nzChecked)]="checked"
      [nzIndeterminate]="indeterminate"
      (nzCheckedChange)="onAllChecked($event)"
      nzWidth="60px"
    ></th>
    <th nzColumnKey="id" [nzSortFn]="true" nzSortOrder="descend">#ID</th>
    <th nzColumnKey="createdAt" [nzSortFn]="true">Datum</th>
    <th nzColumnKey="isPriority" [nzSortFn]="true">Priorität</th>
    <th nzColumnKey="article.orderNumber" [nzSortFn]="true">Artikelnummer</th>
    <th nzColumnKey="article.name" [nzSortFn]="true">Name</th>
    <th nzColumnKey="processingDate" [nzSortFn]="true">Bearbeitungsdatum</th>
    <th nzColumnKey="worker.fullName" [nzSortFn]="true">Bearbeiter</th>
    <th
      nzColumnKey="status"
      [nzFilters]="statusFilters"
      [nzFilterMultiple]="true"
      [nzFilterFn]="true"
      [nzSortFn]="true"
      (nzFilterChange)="filtersChanged()"
    >Status</th>
    <th nzRight nzWidth="100px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let pOrder of data.results">
    <td [nzChecked]="setOfCheckedId.has(pOrder.id)" (nzCheckedChange)="onItemChecked(pOrder.id, $event)"></td>
    <td>#{{pOrder.id}}</td>
    <td>{{pOrder.createdAt|dateFormat}}</td>
    <td>
      <i nz-icon nzType="fire" nzTheme="twotone" [nzTwotoneColor]="'#eb2f96'" *ngIf="pOrder.isPriority"> </i>
    </td>
    <td>{{pOrder.article.orderNumber}}</td>
    <td>{{pOrder.article.name}}</td>
    <td>{{pOrder.processingDate|dateFormat}}</td>
    <td>
      <ng-container *ngFor="let worker of pOrder.workers">
        {{ worker.fullName }}<br>
      </ng-container>
    </td>
    <td>{{pOrder.status | statusFormat}}</td>
    <td nzRight>
      <div class="actions">
        <app-add-edit-modal (refresh)="onRefreshList(false)" #modal>
        <span trigger>
            <i nz-icon nzType="edit"  nzTheme="outline" (click)="modal.showModal(pOrder)"></i>
        </span>
        </app-add-edit-modal>
        <span *ngIf="pOrder.documentKey && pOrder.documentKey !== ''">
          <app-download-document [documentKey]="pOrder.documentKey"></app-download-document>
        </span>
        <span *ngIf="pOrder.status !== 'Canceled'">
          <i
            nz-icon
            nzType="stop"
            nzTheme="outline"
            nz-popconfirm
            nzPopconfirmTitle="Bestätigen Sie, dass Sie einen Produktionsauftrag stornieren möchten, wenn dieser im Voraus gebucht wurde, wird der Bestand reduziert."
            (nzOnConfirm)="cancelProductionOrder(pOrder.id)"
          ></i>
        </span>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
