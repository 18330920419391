import { Injectable } from '@angular/core';
import { ApiService } from './ApiService';
import { JwtService } from './auth/jwt.service';
import { Observable } from 'rxjs';
import { IDocument, ITableResults } from '../interfaces';
import { GenericService } from './GenericService';
import { HttpUtils } from '../utils/HttpUtils';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends GenericService<IDocument> {
  protected readonly baseUrl = '/tools';

  constructor(public apiService: ApiService, private _jwtService: JwtService) {
    super(apiService);
  }

  getDownloadUrl(documentKey: string): string {
    return `${this.apiService.getApiUrl()}${this.baseUrl}/document?key=${documentKey}&token=${this._jwtService.getAccessToken()}&isPrint=${ documentKey.includes('.pdf') }`;
  }

  getMassDownloadUrl(documentKeys: string[]): string {
    return `${this.apiService.getApiUrl()}${this.baseUrl}/documents?keys=${documentKeys.join('&keys=')}&token=${this._jwtService.getAccessToken()}&isPrint=${ documentKeys.every(x => x.includes('.pdf')) }`;
  }

  getGeneratedDocuments(pageIndex: number,
                        pageSize: number,
                        sortField: string | null,
                        sortOrder: string | null,
                        filters: Array<{ key: string; value: string[] }>,
                        search: Array<{ key: string; value: string[] }>,
                        additionalFilters: Array<{ key: string; value: string[] }>,
  ): Observable<ITableResults<IDocument>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters);
    return this.apiService.get(`${this.baseUrl}/generated-documents`, params);
  }

  public getBatchOpenOrdersDocuments(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    search: Array<{ key: string; value: string[] }>,
    additionalFilters: Array<{ key: string; value: string[] }>,
  ) {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters);

    return this.apiService.get(`${this.baseUrl}/batch-open-orders-documents`, params);
  }

  getQueryableAll(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, filters: Array<{ key: string; value: string[] }>, search: Array<{ key: string; value: string[] }>): Observable<ITableResults<IDocument>> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Observable<IDocument> {
    throw new Error('Method not implemented.');
  }

  delete(id: string): Observable<IDocument> {
    throw new Error('Method not implemented.');
  }

  update(object: IDocument, id: number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  create(object: IDocument): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
