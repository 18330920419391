import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef, Input, Output, EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../../core/services';
import { SupplierServiceErrorResolverService } from '../../../../suppliers/core/services/error-resolvers/supplier-service-error-resolver.service';
import { BulkContainersService, BulkContainersServiceErrorResolverService } from '../../../core/services';
@Component({
  selector: 'app-correct-quantity-modal',
  templateUrl: './correct-quantity-modal.component.html',
  styleUrls: ['./correct-quantity-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrectQuantityModalComponent  implements OnInit {
  title = 'Inventurkorrektur: ';
  isConfirmLoading = false;
  isVisible = false;
  form: FormGroup;
  isSubmitted = false;
  private _bulkContainerId: number;

  @Output() changeLogCreated = new EventEmitter<boolean>();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _notifyService: NotificationService,
    private _errorResolver: BulkContainersServiceErrorResolverService,
    private _formService: BulkContainersService
  ) {
    this.form = this._fb.group({
      newQuantity: [0, Validators.compose([Validators.required, Validators.min(0)])],
      comment: ['', Validators.required]
    })
  }

  ngOnInit(): void {}

  show = (val:number) => {
    if(val) {
      this.title = `Inventurkorrektur: #${val}`;
      this._bulkContainerId = val;
      this.isVisible = true;
      this._cdr.detectChanges();
    }
  }

  handleOk = () => this.submitForm();
  handleCancel = () => this.isVisible = false;

  submitForm(): void {
    this.isSubmitted = true;
    this.isConfirmLoading = true;

    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      }
    }

    if (!this.form.valid) {
      this.isConfirmLoading = false;
      return;
    }

    const object = this.form.value;
    object.newQuantity = object.newQuantity * 1000;
    this._formService.createChangeLog(this. _bulkContainerId, object).subscribe(() => {
      this._notifyService.pushSuccess('Erfolgreich', `Korrektur hinzugefügt`);
      this.isConfirmLoading = false;
      this.changeLogCreated.emit(true);
      this.isVisible = false;
      this.form.reset();
      this._cdr.detectChanges();
    }, err => {
      this.parseError(err);
      this.isConfirmLoading = false;
      this._cdr.detectChanges();
    });

  }

  parseError = data => {
    this._notifyService.pushError('Fehlgeschlagen', this._errorResolver.getMessage((data && data.InternalCode) ? data.InternalCode : ''));
  }
}
