import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AddEditModalGeneric } from '../../../ui/generics';
import { IStockTransfer } from '../core/interfaces/IStockTransfer';
import { AddEditFormComponent } from '../add-edit-form/add-edit-form.component';
import { StockTransferStatusTypes } from '../core/enums';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditModalComponent extends AddEditModalGeneric<IStockTransfer, AddEditFormComponent> implements OnInit {

  public readonly stockTransferStatusTypes = StockTransferStatusTypes;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
