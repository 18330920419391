import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef, OnDestroy, ViewContainerRef, ViewChild, ElementRef, TemplateRef
} from '@angular/core';
import { ProcessFormComponent } from '../process-form/process-form.component';
import { AddEditModalGeneric } from '../../../ui/generics';
import { IProductionOrder } from '../core/interfaces/IProductionOrder';
import { IGlobalModal, D_GLOBAL_MODAL } from '../../../core/directives';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DocumentCodes, ProductionOrderStatuses } from '../../../core/enums';
import { DownloadDocumentComponent } from '../../../ui/download-document/download-document.component';
import { DocumentService } from '../../../core/services/DocumentService';
import { NotificationService } from '../../../core/services';
import { QrcodeScannerComponent } from '../../../ui/qrcode-scanner/qrcode-scanner.component';

@Component({
  selector: 'app-process-modal',
  templateUrl: './process-modal.component.html',
  styleUrls: ['./process-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: ProcessModalComponent }]
})
export class ProcessModalComponent extends AddEditModalGeneric<IProductionOrder, ProcessFormComponent> implements OnInit, OnDestroy, IGlobalModal {
  @ViewChild('scanField') scanField: ElementRef<HTMLInputElement>;
  @ViewChild('variantsWithoutLabelKey') variantsWithoutLabelKey: TemplateRef<HTMLInputElement>;

  public orderNumber = '';
  private _orderNumberChange$ = new Subject<{ val: string }>();
  public numberChanged$: Observable<{ val: string }>;
  public orderStatus: ProductionOrderStatuses;
  public orderStatuses = ProductionOrderStatuses;
  public isManualInput = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private readonly _documentService: DocumentService,
    private readonly _notificationService: NotificationService,
  ) {
    super(cdr);
  }


  ngOnInit() {
    this.numberChanged$ = this._orderNumberChange$
      .asObservable()
      .pipe(debounceTime(500));
    super.ngOnInit();
  }

  handleNumberScan = (val): void => {
    if (val.slice(0, 2) === DocumentCodes.PRODUCTION_ORDER) {
      this._orderNumberChange$.next({ val: val.slice(2) });
      return;
    }
    this._orderNumberChange$.next({ val });

  }

  handleBarcodeScanner = (): void => this.createBarcodeScannerModal();

  handleInsert(): void {
    this.checkVariants(this.form.openInsertModal.bind(this.form));
  }

  afterModalOpen() {
    if (this.scanField) {
      this.setFocusOnScanField();
    }
  }

  rebuildTitle(): void {
    this.title = this.isNew ? 'Produzieren' : `Produzieren: #${this.object.id}`;
  }

  handleOk() {
    this.checkVariants(super.handleOk.bind(this));
  }

  handleCancel(): void {
    this.orderNumber = '';
    this.closeModal();
  }

  createBarcodeScannerModal(): void {
    const modal = this.modal.create({
      nzTitle: 'Barcode scanner',
      nzContent: QrcodeScannerComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: 689,
      nzFooter: [
        {
          label: 'Ok',
          type: 'primary',
          onClick: () => modal.destroy()
        }
      ]
    });
    modal.afterClose.subscribe(result => {
      if (result && result.code) {
        this.orderNumber = result.code.substring(2);
        this.handleNumberScan(this.orderNumber);
      } else {
        this.orderNumber = '';
      }
      this.cdr.detectChanges();
    });

    this.cdr.detectChanges();
  }

  onFormFinished(state: boolean): void {
    if (state) {
      this.orderNumber = '';
      this.setFocusOnScanField();
      this.refresh.emit();
    }
    this.isConfirmLoading = false;
    this.cdr.detectChanges();
  }

  setFocusOnScanField(): void {
    setTimeout(() => this.scanField.nativeElement.focus(), 100);
  }

  handleChangeStatus(val): void {
    this.orderStatus = val;
    this.cdr.detectChanges();
  }

  public printLabels(): void {
    const isMissingLabel = this.form.editObject.productionOrderVariants.some(val => !val.articleVariant.labelKey);

    if (isMissingLabel) {
      const modal = this.modal.create({
        nzTitle: 'Artikel Variante',
        nzContent: this.variantsWithoutLabelKey,
        nzViewContainerRef: this.viewContainerRef,
        nzWidth: 689,
        nzFooter: [
          {
            label: 'Fortsetzen',
            type: 'primary',
            onClick: () => {
              this.openLabelsDocuments();
              modal.destroy();
            }
          }
        ]
      });
    } else {
      this.openLabelsDocuments();
    }
    this.cdr.detectChanges();
  }

  private openLabelsDocuments(): void {
    let windowInstances: Window[] = [];
    this.form.editObject.productionOrderVariants.filter(val => val.articleVariant.labelKey).forEach(val => {
      const downloadDocumentComponent = new DownloadDocumentComponent(this._documentService);
      downloadDocumentComponent.documentKey = val.articleVariant.labelKey;
      windowInstances = [...windowInstances, downloadDocumentComponent.openFile()];
    });
    if (windowInstances.includes(null)) {
      this.createMultipleTabsInfoModal();
    }
  }

  private createMultipleTabsInfoModal(): void {
    const modal = this.modal.create({
      nzTitle: 'Info',
      nzContent: `<h3>Wenn Sie möchten, dass alle Beschriftungen in neuen Registerkarten geöffnet werden, lassen Sie die Site Popups öffnen.</h3>`,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: 689,
      nzFooter: [
        {
          label: 'Getan',
          type: 'primary',
          onClick: () => {
            this.openLabelsDocuments();
            modal.destroy();
          }
        }
      ]
    });

    this.cdr.detectChanges();
  }

  private checkVariants(cb: () => void): void {
    this.form.variants.every(variant => variant.scanned >= variant.quantity || variant.isCorrected) ?
      cb() :
      this._notificationService.pushError('Fehler', 'Bitte scannen Sie alle Varianten.');
  }
}
