export enum MinutesAndHoursSelectOptions {
  '5 Minuten' = '5 Minuten',
  '10 Minuten' = '10 Minuten',
  '15 Minuten' = '15 Minuten',
  '20 Minuten' = '20 Minuten',
  '25 Minuten' = '25 Minuten',
  '30 Minuten' = '30 Minuten',
  '35 Minuten' = '35 Minuten',
  '40 Minuten' = '40 Minuten',
  '45 Minuten' = '45 Minuten',
  '50 Minuten' = '50 Minuten',
  '55 Minuten' = '55 Minuten',
  '1 Stunde' = '1 Stunde',
  '1.5 Stunde' = '1.5 Stunde',
  '2 Stunden' = '2 Stunden',
  '2.5 Stunden' = '2.5 Stunden',
  '3 Stunden' = '3 Stunden',
  '3.5 Stunden' = '3.5 Stunden',
  '4 Stunden' = '4 Stunden',
  '4.5 Stunden' = '4.5 Stunden',
  '5 Stunden' = '5 Stunden',
  '5.5 Stunden' = '5.5 Stunden',
  '6 Stunden' = '6 Stunden',
  '6.5 Stunden' = '6.5 Stunden',
  '7 Stunden' = '7 Stunden',
  '7.5 Stunden' = '7.5 Stunden',
  '8 Stunden' = '8 Stunden',
  '8.5 Stunden' = '8.5 Stunden',
  '9 Stunden' = '9 Stunden',
  '9.5 Stunden' = '9.5 Stunden',
  '10 Stunden' = '10 Stunden',
  '10.5 Stunden' = '10.5 Stunden',
  '11 Stunden' = '11 Stunden',
  '11.5 Stunden' = '11.5 Stunden',
  '12 Stunden' = '12 Stunden',
  '12.5 Stunden' = '12.5 Stunden',
}
