import { Injectable } from '@angular/core';
import { ApiService } from './ApiService';
import { JwtService } from './auth';
import { IExportTable } from '../../pages/bulk-containers/core/interfaces/IExportTable';
import { Observable } from 'rxjs';
import { ITax, IUnit } from '../interfaces';
import { HttpParams } from '@angular/common/http';
import { DictionaryKeys } from '../enums/DictionaryKeys';
import { IDictionary } from '../interfaces/IDictionary';
import { IS3PresignedUrl } from '../interfaces/IS3PresignedUrl';
import { HttpError } from '../decorators/http-error.decorator';
import { environment } from '../../../environments/environment';
import { UploadDocumentTypes } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  private readonly _baseUrl = '/tools';

  constructor(
    private readonly _apiService: ApiService,
    private readonly _jwtService: JwtService,
  ) {}

  getDownloadUrl(documentKey: string) {
    return `${this._apiService.getApiUrl()}${this._baseUrl}/document?key=${documentKey}&token=${this._jwtService.getAccessToken()}`;
  }

  getAmazonDownloadUrl(documentKey: string) {
    const param = new HttpParams().append('keys', documentKey);
    return this._apiService.get(`${ this._baseUrl }/download-url`, param);
  }

  public getFile(documentKey: string, contentType: string): Observable<Blob> {
    return this._apiService.getFile(this.getDownloadUrl(documentKey), contentType);
  }

  getMassDownloadUrl(documentKeys: string[]) {
    return `${this._apiService.getApiUrl()}${this._baseUrl}/documents?keys=${documentKeys.join('&keys=')}&token=${this._jwtService.getAccessToken()}`;
  }

  public exportTable(exportDetails: IExportTable): Observable<any> {
    return this._apiService.post(`${ this._baseUrl }/export-table`, exportDetails);
  }

  public getUnits(): Observable<IUnit[]> {
    return this._apiService.get(`${ this._baseUrl }/units`);
  }

  public getTaxes(): Observable<ITax[]> {
    return this._apiService.get(`${ this._baseUrl }/taxes`);
  }

  public getBarcodeSvg(articleVariantId: number): Observable<string> {
    return this._apiService.get(`${this._baseUrl }/svg-barcode?articleVariantId=${articleVariantId.toString(10)}`);
  }

  public clearCache(): Observable<any> {
    return this._apiService.post(`${ this._baseUrl}/sync-with-shopware`);
  }

  public getDictionaryValue(key: DictionaryKeys): Observable<IDictionary> {
    const params = new HttpParams().append('key', key);

    return this._apiService.get(`${ this._baseUrl }/get-dictionary-value`, params);
  }

  public setDictionaryValue(key: DictionaryKeys, value: string): Observable<void> {
    const body = {
      key,
      value
    };

    return this._apiService.post(`${ this._baseUrl }/set-dictionary-value`, body);
  }

  public getUploadSignatureUrl(bannerLink: string): string {
    return `${ environment.apiUrl }${ this._baseUrl }/upload-signature?url=${ bannerLink }`;
  }

  public getUploadDocumentUrl(type: UploadDocumentTypes): string {
    return `${ environment.apiUrl }${ this._baseUrl }/upload-document?type=${ type }`;
  }

  @HttpError()
  public uploadFileToS3WithPresignedUrl(url: string, file: File): Observable<Response> {
    return this._apiService.uploadFileToS3WithPresignedUrl(url, file);
  }

  @HttpError()
  public getS3PresignedUrl(): Observable<IS3PresignedUrl> {
    return this._apiService.get(`${ this._baseUrl }/upload-url`);
  }
}
