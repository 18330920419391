<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="400"
  class="new-export-modal"
>
  <ng-template #modalTitle>Neue Ausfuhr</ng-template>

  <ng-template #modalContent>
    <nz-spin [nzSpinning]="false">
      <form nz-form [formGroup]="form" #formElementRef (ngSubmit)="submitForm()" id="new-export-form">
        <nz-row>
          <nz-form-label nzFor="exportType" nzRequired>Art der Ausfuhr</nz-form-label>
          <nz-select class="export-type-select" formControlName="exportType">
            <nz-option
              *ngFor="let type of exportTypesEnum | keyvalue"
              [nzLabel]="exportTypesTranslationsEnum[type.key]"
              [nzValue]="+type.value"
            ></nz-option>
          </nz-select>
        </nz-row>
        <div *ngIf="isSubmitted && form.controls.exportType.errors" class="invalid-feedback">
          <div *ngIf="form.controls.exportType.errors.required">Feld ist erforderlich</div>
        </div>
      </form>
    </nz-spin>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button class="left-space" nzType="default" (click)="handleCancel()">Schließen</button>
    <button
      nz-button
      type="submit"
      form="new-export-form"
      class="left-space"
      nzType="primary"
      [nzLoading]="isConfirmLoading"
    >Speichern</button>
  </ng-template>
</nz-modal>
