<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th nzColumnKey="charge" [nzSortFn]="true">Charge</th>
    <th nzColumnKey="id" [nzSortFn]="true" nzSortOrder="descend">#Bestell-ID</th>
    <th nzColumnKey="quantity" [nzSortFn]="true">Menge</th>
    <th nzColumnKey="orderDate" [nzSortFn]="true">Datum</th>
    <th nzColumnKey="status" [nzSortFn]="true">Status</th>
    <th nzRight></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let order of data.results">
    <td>{{order.charge}}</td>
    <td>#{{order.id}}</td>
    <td>{{ order.quantity | productionUnit:true }}</td>
    <td>{{order.orderDate|dateFormat}}</td>
    <td>{{order.status}}</td>
    <td>
      <app-download-document *ngIf="order.documentKey" [documentKey]="order.documentKey"></app-download-document>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
