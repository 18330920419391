import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  ChangeDetectorRef,
  ViewChild, AfterViewInit
} from '@angular/core';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true
    }
  ]
})
export class RichTextEditorComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('angularEditorComponent') angularEditorComponent: AngularEditorComponent;

  @Input() editable = true;
  @Input() spellcheck = false;
  @Input() height = 'auto';
  @Input() placeholder = 'Enter text here...';
  @Input() translate: 'yes' | 'no' | string = 'no';
  @Input() defaultParagraphSeparator = 'p';
  @Input() toolbarHiddenButtons =  [];
  @Input() customConfig = {};

  public config: AngularEditorConfig = {};
  public selectedValue: string;
  public isDisabled = false;
  public propagateChange: any = () => {};

  constructor(
    private readonly _cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.config = {
      height: this.height,
      editable: this.editable,
      spellcheck: this.spellcheck,
      placeholder: this.placeholder,
      translate: this.translate,
      defaultParagraphSeparator: this.defaultParagraphSeparator,
      toolbarHiddenButtons: this.toolbarHiddenButtons,
      ...this.customConfig
    };
  }

  public ngAfterViewInit(): void {
    this.angularEditorComponent.editorToolbar.execute.subscribe(key => {
      if (key === 'removeFormat') {
        const fakeDiv = document.createElement('div') as HTMLDivElement;

        fakeDiv.innerHTML = this.selectedValue;
        this.selectedValue = fakeDiv.textContent.replaceAll('\n', '');

        this.propagateChange(this.selectedValue);
      }
    });
  }

  public writeValue(val: string): void {
    this.selectedValue = val;
    this._cdr.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this._cdr.detectChanges();
  }
}
