import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { de_DE } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { CoreModule } from './core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ProductionOrdersModule } from './pages/production-orders/production-orders.module';
import { OrdersModule } from './pages/orders/orders.module';
import { StoresModule } from './pages/stores/stores.module';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { UiModule } from './ui/ui.module';
import { UsersModule } from './pages/users/users.module';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { BulkContainersModule } from './pages/bulk-containers/bulk-containers.module';
import { StockTransferModule } from './pages/stock-transfer/stock-transfer.module';
import { StaticDependenciesService } from './core/services';

registerLocaleData(de);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.isSW}),
    ProductionOrdersModule,
    OrdersModule,
    StoresModule,
    StockTransferModule,
    NzAvatarModule,
    UiModule,
    UsersModule,
    NzAlertModule,
    BulkContainersModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: de_DE },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    StaticDependenciesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly _staticDependenciesService: StaticDependenciesService
  ) {}
}
