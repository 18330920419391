import { Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';
import { environment } from '../../../environments/environment';
import { DocumentService } from '../services/DocumentService';

@Pipe({
  name: 'downloadFileUrl'
})
export class DownloadFileUrlPipe implements PipeTransform {


  constructor(
    private _documentService: DocumentService
  ) {
  }

  transform(value: string, args?: any): string {
    return this._documentService.getDownloadUrl(value);
  }
}
