import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DocumentService } from '../../core/services/DocumentService';

@Component({
  selector: 'app-download-document',
  templateUrl: './download-document.component.html',
  styleUrls: ['./download-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadDocumentComponent implements OnInit {
  private _documentKey: string;
  private _documentUrl: string;
  @Input() icon = 'file-pdf';
  @Input() text: string;
  @Input() set documentKey(val: string) {
    this._documentKey = val;
    this._documentUrl = this._documentService.getDownloadUrl(this._documentKey);
  }

  constructor(private _documentService: DocumentService) { }

  ngOnInit(): void {
  }

  openFile(): Window {
    return window.open(this._documentUrl, '_blank');
  }
}
