import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from '../../../../../core/services/error-resolvers/base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class UsersServiceErrorResolverService extends BaseServiceErrorResolverService {
  codes = {
    'LoginAlreadyInUser': 'Login is already in use'
  };
}
