import { Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'booleanFormat'
})
export class BooleanFormatPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}


  transform(value: boolean): any {
    return (value ? 'Ja' : 'Nein');
  }
}
