<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="false"
>
  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzData]="data"
  [nzLoading]="loading"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
>
  <thead>
  <tr>
    <th></th>
    <th
      *ngFor="let column of listOfColumns"
      [nzSortOrder]="column.sortOrder"
      [nzSortFn]="column.sortFn"
      [nzSortDirections]="column.sortDirections"
      [nzFilterMultiple]="column.filterMultiple"
      [nzFilters]="column.listOfFilter"
      [nzFilterFn]="column.filterFn"
      [nzShowFilter]="column.showFilter"
      [nzShowSort] ="column.showOrder"
    >
      {{ column.name }}
    </th>
    <th></th>
  </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let pOrder of listOfCurrentPageData">
      <tr>
        <td [(nzExpand)]="pOrder.expand"></td>
        <td>#{{pOrder.id}}</td>
        <td>{{pOrder.createdAt|dateFormat}}</td>
        <td>{{pOrder.bulkContainer.charge}}</td>
        <td>{{pOrder.bulkContainer.supplier?.name}}</td>
        <td>{{pOrder.processingDate|dateFormat}}</td>
        <td>
          <ng-container *ngFor="let worker of pOrder.workers">
            {{ worker.fullName }}<br>
          </ng-container>
        </td>
        <td>{{pOrder.isFinished | booleanFormat}}</td>
        <td>{{pOrder.status | statusFormat}}</td>
        <td>
          <div class="actions">
            <app-add-edit-modal (refresh)="onRefreshList(false)" #modal>
              <span trigger>
                  <i nz-icon nzType="edit"  nzTheme="outline" (click)="modal.showModal(pOrder)"></i>
              </span>
            </app-add-edit-modal>
            <span *ngIf="pOrder.documentKey && pOrder.documentKey !== ''">
              <app-download-document [documentKey]="pOrder.documentKey"></app-download-document>
            </span>
            <span *ngIf="pOrder.status !== 'Canceled'">
              <i
                nz-icon
                nzType="stop"
                nz-popconfirm
                nzPopconfirmTitle="Bestätigen Sie, dass Sie einen Produktionsauftrag stornieren möchten, wenn dieser im Voraus gebucht wurde, wird der Bestand reduziert."
                (nzOnConfirm)="cancelOrder(pOrder.id)"
                nzTheme="outline"
              ></i>
          </span>
          </div>
        </td>
      </tr>
      <tr [nzExpand]="pOrder.expand">
        <nz-table #tableRef #innerTable [nzData]="pOrder.productionOrderVariants" nzSize="middle" [nzShowPagination]="false">
          <thead>
            <tr>
              <th>Variant</th>
              <th>Ordernummer</th>
              <th>Weight</th>
              <th>Menge</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let variant of pOrder.productionOrderVariants">
              <td>{{ variant.articleVariant.name }}{{ variant.recipeCardVariant ? ' (' + variant.recipeCardVariant.value + ')' : '' }}</td>
              <td>{{ variant.articleVariant.orderNumber }}</td>
              <td>{{ variant.weight }} g</td>
              <td>{{ variant.quantity }}</td>
            </tr>
          </tbody>
        </nz-table>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
