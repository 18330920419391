import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { PermissionService } from '../services/auth/PermissionService';

@Directive({ selector: '[appShowForRole]' })
export class ShowForRoleDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionService
  ) {}

  action: string;
  isCreated = false;

  ngOnInit() {
    if (this.action === null || this.action === undefined || (this.permissionsService.hasAccess(this.action) && !this.isCreated)) {
      this.isCreated = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.isCreated = false;
      this.viewContainer.clear();
    }
  }

  @Input() set appShowForRole(action: string) {
    this.action = action;
  }
}
