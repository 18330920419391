import { Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'stringIncludes'
})
export class StringIncludesPipe implements PipeTransform {

  public transform(value: string, search: string): boolean {
    return value.includes(search);
  }
}
