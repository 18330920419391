import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { AddEditFormComponent } from '../add-edit-form/add-edit-form.component';
import { AddEditModalGeneric } from '../../../ui/generics';
import { IProductionOrder } from '../core/interfaces/IProductionOrder';
import { IBulkContainer } from '../../bulk-containers/core/interfaces/IBulkContainer';
import { ArticlesService } from '../../articles/core/ArticlesService';
import { finalize } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditModalComponent extends AddEditModalGeneric<IProductionOrder, AddEditFormComponent> implements OnInit {

  public bulkContainer: IBulkContainer;
  public isLoading = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    private readonly _articlesService: ArticlesService
  ) {
    super(cdr);
  }

  rebuildTitle(): void {
    this.title = this.isNew ? 'Neuen Produktionsauftrag anlegen' : `Produktionsauftrag bearbeiten: #${this.object.id}`;
  }

  public showModalForBulkContainer(bulkContainer: IBulkContainer) {
    this.isLoading = true;
    this.showModal(null);

    this._articlesService.getVariantsByArticleId(bulkContainer.articleId).pipe(
      finalize(() => {
        this.isLoading = false;
        this.cdr.detectChanges();
      })
    ).subscribe(variants => {
      bulkContainer.article.articleVariants = [
        ...variants.filter(x => x.isActiveForProduction)
      ];
      this.bulkContainer = cloneDeep(bulkContainer);
    });
  }
}
