<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  nzClassName="search-modal"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <app-search (searchChanged)="valueChanged($event)"></app-search>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
  </ng-template>
</nz-modal>
