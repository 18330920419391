<div #refModalTrigger><ng-content select="[trigger]"></ng-content></div>
<div *ngIf="(refModalTrigger.children.length == 0)">
  <button nz-button nzType="primary" (click)="showModal(null)">
    <i nz-icon nzType="plus-circle" nzTheme="outline"></i><span>{{triggerTitle}}</span>
  </button>
</div>
<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <app-edit-form
      #form
      [editObject]="object"
      (formFinished)="onFormFinished($event)"
      [isVariant]="isVariant"
    ></app-edit-form>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">Speichern</button>
  </ng-template>
</nz-modal>
