import {
  Component,
  OnInit,
  ChangeDetectionStrategy, Input, ChangeDetectorRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../core/services';
import { AddEditFormGeneric } from '../../../ui/generics';
import { IProductionOrder } from '../core/interfaces/IProductionOrder';
import { ProductionOrdersService } from '../core/services/ProductionOrdersService';
import { ProductionOrderServiceErrorResolverService } from '../core/services/error-resolvers/production-order-service-error-resolver.service';
import { IBulkContainer } from '../../bulk-containers/core/interfaces/IBulkContainer';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UsersService } from '../../users/core/services/UsersService';
import { debounceTime, switchMap } from 'rxjs/operators';
import { IArticleVariant } from '../../../core/interfaces';
import { ProductionOrderStatuses } from '../../../core/enums';
import { ArticlesService } from '../../articles/core/ArticlesService';
import * as moment from 'moment';

@Component({
  selector: 'app-add-edit-form',
  templateUrl: './add-edit-form.component.html',
  styleUrls: ['./add-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditFormComponent extends AddEditFormGeneric<IProductionOrder> implements OnInit {

  isUsersLoading = false;
  usersList: ISimpleListItem[];
  bulkContainerObj: IBulkContainer;
  searchUsersChange$ = new Subject();
  variantsFormList: FormArray;
  restValue$ = new BehaviorSubject<number>(0);
  public isRecipeCardsLoading: boolean;
  public recipeCardsList: ISimpleListItem[];
  private _startRestValue = 0;
  private readonly _searchRecipeCardsChange$ = new Subject<string>();

  @Input() set bulkContainer(val: IBulkContainer) {
    this.bulkContainerObj = val;

    if (val) {
      this.form.patchValue({
        bulkContainerId: val.id,
      });
      this.rebuildVariants();
      this.restValue$.next(val.state.value);
      this._startRestValue = val.state.value;

      if ((this.editObject && ProductionOrderStatuses[this.editObject.status] === ProductionOrderStatuses.Created) || !this.isEdit) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }
  }

  constructor(
    protected _fb: FormBuilder,
    protected _suppliersService: ProductionOrdersService,
    protected _notifyService: NotificationService,
    protected _errorResolver: ProductionOrderServiceErrorResolverService,
    protected _usersService: UsersService,
    protected _cdr: ChangeDetectorRef,
    private readonly _articlesService: ArticlesService
  ) {
    super(_fb, _suppliersService, _notifyService, _errorResolver);
    this.form = this._fb.group({
      bulkContainerId: [],
      isPriority: [false, Validators.required],
      processingDate: [null, Validators.required],
      workers: [null, Validators.required],
      comment: [''],
      productionOrderVariants: this._fb.array([])
    });

    this.variantsFormList = this.form.get('productionOrderVariants') as FormArray;
    this.variantsFormList.valueChanges.subscribe((changes) => this.reCalcRestValue(changes));

  }

  ngOnInit() {
    const getUsers = (name: string) =>
      this._usersService.getSimpleList(name, true);

    const getRecipeCards = (name: string) =>
      this._articlesService.getArticlesVariants(name, false, true);

    const usersOptionList$: Observable<ISimpleListItem[]> = this.searchUsersChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getUsers));
    usersOptionList$.subscribe(data => {
      this.usersList = data;
      this.isUsersLoading = false;
      this._cdr.detectChanges();
    });

    const recipeCardsOptionList$: Observable<ISimpleListItem[]> = this._searchRecipeCardsChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getRecipeCards));
    recipeCardsOptionList$.subscribe(data => {
      this.recipeCardsList = data;

      if (this.editObject) {
        this.editObject.productionOrderVariants.forEach(x => {
          if (x.recipeCardVariant && !this.recipeCardsList.find(y => y.id === x.recipeCardVariantId)) {
            this.recipeCardsList.unshift(x.recipeCardVariant);
          }
        });
      }

      this.isRecipeCardsLoading = false;
      this._cdr.detectChanges();
    });
    super.ngOnInit();
  }

  public onRecipeCardsSearch(value: string): void {
    this.isRecipeCardsLoading = true;
    this._searchRecipeCardsChange$.next(value);
  }

  onSearchUsers(value: string) {
    this.isUsersLoading = true;
    this.searchUsersChange$.next(value);
  }

  public submitForm(): void {
    if (this.restValue$.getValue() < 0) {
      this.formFinished.emit(false);
      this._notifyService.pushError('Fehler', 'Die Restmenge kann nicht kleiner als 0 sein.');
    } else {
      super.submitForm();
    }
  }

  public saveNew(): void {
    this.form.value['processingDate'] = moment(this.form.value['processingDate']).format('YYYY-MM-DD');
    super.saveNew();
  }

  public saveEdit(): void {
    this.form.value['processingDate'] = moment(this.form.value['processingDate']).format('YYYY-MM-DD');
    super.saveEdit();
  }

  beforeLoadForm() {
    if (this.editObject) {
      const _bulkContainer = this.editObject.bulkContainer as IBulkContainer;
      _bulkContainer.article = this.editObject.article;
      this.bulkContainer = _bulkContainer;
      this.bulkContainerObj.state.value = this.bulkContainerObj.state.value;
      this._startRestValue = this.bulkContainerObj.state.value  + this.calcSumOfOrder(this.editObject.productionOrderVariants);
      this.searchUsersChange$.next();
      this._searchRecipeCardsChange$.next();

      this.editObject.productionOrderVariants.forEach(x => {
        if (x.recipeCardVariant) {
          x.recipeCardVariantId = x.recipeCardVariantId.toString();
        }
      });
    }
  }

  private createVariant(variant: IArticleVariant): FormGroup {
    return this._fb.group({
      id: [-1],
      articleVariantId: [variant.id, Validators.required],
      quantity: [0, Validators.required],
      weight: [variant.purchaseUnit],
      customWeight: [variant.customWeight],
      recipeCardVariantId: [null],
    });
  }

  private rebuildVariants(): void {
    this.bulkContainerObj.article.articleVariants.forEach(value => {
      this.variantsFormList.push(this.createVariant(value));
    });
  }

  private reCalcRestValue(changes:Array<{quantity:number, weight: number; customWeight?: number;}>): void {
    const sum = this.calcSumOfOrder(changes) * (this.isEdit ? 1 : 1000);
    this.restValue$.next(this._startRestValue - sum);
  }

  private calcSumOfOrder(changes:Array<{quantity:number, weight: number; customWeight?: number;}>): number {
    return +changes.reduce((prev, next) => prev + (next.quantity * (next.customWeight ? next.customWeight : next.weight)), 0).toFixed(2);
  }
}
