import { inject, Pipe, PipeTransform } from '@angular/core';
import { Params, Router } from '@angular/router';

@Pipe({
  name: 'queryParams'
})
export class QueryParamsPipe implements PipeTransform {

  private readonly _router = inject(Router);

  public transform(path: string): Params {
    return this._router.parseUrl(path).queryParams;
  }
}
