<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <button nz-button nzType="primary" class="new-export-button" (click)="newExportModalComponentRef.showModal()">
    <i nz-icon nzType="plus" nzTheme="outline"></i><span>Neue Ausfuhr</span>
  </button>
  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="generated-documents-list-table"
>
  <thead>
  <tr>
    <th nzColumnKey="visibleName" [nzSortFn]="true">Name</th>
    <th nzColumnKey="type" [nzSortFn]="true">Typ</th>
    <th nzColumnKey="outputType" [nzSortFn]="true">Dateityp</th>
    <th nzColumnKey="comment" [nzSortFn]="true">Kommentar</th>
    <th nzColumnKey="user.fullName" [nzSortFn]="true">Benutzer</th>
    <th nzColumnKey="createdAt" [nzSortFn]="true">Hergestellt in</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let doc of data.results">
    <td>{{ doc.visibleName }}</td>
    <td>{{ doc.type }}</td>
    <td>{{ documentTypes[doc.outputType] | uppercase }}</td>
    <td>{{ doc.comment }}</td>
    <td>{{ doc.user.fullName }}</td>
    <td>{{ doc.createdAt | datetimeFormat }}</td>
    <td>
      <div class="actions">
        <app-download-document [documentKey]="doc.documentKey" *ngIf="doc.outputType === documentTypes.Pdf"></app-download-document>
        <app-download-csv
          [documentKey]="doc.documentKey"
          *ngIf="doc.outputType === documentTypes.Csv"
          [fileName]="doc.documentKey"
        ></app-download-csv>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>

<app-new-export-modal #newExportModalComponentRef></app-new-export-modal>
