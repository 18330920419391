import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { NotificationService } from '../../../core/services';
import { ArticlesService } from '../../articles/core/ArticlesService';
import { finalize } from 'rxjs/operators';
import { StoresService } from '../../stores/core/services/StoresService';
import { Observable } from 'rxjs';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { IArticleVariant } from '../../../core/interfaces';

@Component({
  selector: 'app-article-variants-stores-stocks',
  templateUrl: './article-variants-stores-stocks.component.html',
  styleUrls: ['./article-variants-stores-stocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleVariantsStoresStocksComponent implements OnInit {

  @Input() set articleId(val: number) {
    if (val) {
      this._articleId = val;
      this.load();
    }
  }

  public loading = false;
  public variants: IArticleVariant[] = [];
  public storesArr$: Observable<ISimpleListItem[]>;

  private _articleId: number;

  public constructor(
    protected _notifyService: NotificationService,
    private _cdr: ChangeDetectorRef,
    private readonly _articlesService: ArticlesService,
    private readonly _storeService: StoresService,
  ) {}

  public ngOnInit(): void {
    this.storesArr$ = this._storeService.getSimple();
  }

  public load(): void {
    this.loading = true;

    this._articlesService.getVariantsByArticleId(this._articleId).pipe(
      finalize(() => {
        this.loading = false;
        this._cdr.detectChanges();
      })
    ).subscribe(res => this.variants = res);
  }
}
