import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-subpage-header',
  templateUrl: './subpage-header.component.html',
  styleUrls: ['./subpage-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubpageHeaderComponent implements OnInit {

  @Input() tooltipText: string;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }
}
