import { Injectable } from '@angular/core';
import { GenericService } from '../../../core/services/GenericService';
import { ApiService } from '../../../core/services';
import { IArticleComment } from '../../../core/interfaces/IArticleComment';

@Injectable({
  providedIn: 'root'
})
export class ArticlesCommentsService extends GenericService<IArticleComment> {

  protected baseUrl = '/articles/comments';

  constructor(
    public apiService: ApiService
  ) {
    super(apiService);
  }
}
