import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ListComponentGeneric } from '../../../ui/generics';
import { ProductionOrdersService } from '../core/services/ProductionOrdersService';
import { NotificationService } from '../../../core/services';
import { IProductionOrder } from '../core/interfaces/IProductionOrder';
import { ProductionOrderServiceErrorResolverService } from '../core/services/error-resolvers/production-order-service-error-resolver.service';
import { IExpandableItem } from '../../../core/interfaces';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import * as dayjs from 'dayjs';
import { finalize } from 'rxjs/operators';

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn | null;
  filterMultiple: boolean;
  showFilter: boolean;
  showOrder: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: 'app-list-for-article',
  templateUrl: './list-for-article.component.html',
  styleUrls: ['./list-for-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListForArticleComponent extends ListComponentGeneric<IProductionOrder> implements OnInit {
  private _articleId:number;
  private _data: IExpandableItem<IProductionOrder>[] =[];

  // @ts-ignore
  public data: IExpandableItem<IProductionOrder>[] =[];
  public listOfCurrentPageData: ReadonlyArray<IExpandableItem<IProductionOrder>> =[];

  listOfColumns: ColumnItem[] = [
    {
      name: '#ID',
      sortOrder: 'descend',
      sortFn: (a: IProductionOrder, b: IProductionOrder) => a.id - b.id,
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: null,
      filterFn: null,
      showFilter: false,
      showOrder: true
    },
    {
      name: 'Datum',
      sortOrder: null,
      sortFn: (a: IProductionOrder, b: IProductionOrder) => a.id - b.id,
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: null,
      filterFn: null,
      showFilter: false,
      showOrder: true
    },
    {
      name: 'Charge',
      sortOrder: null,
      sortFn: (a: IProductionOrder, b: IProductionOrder) =>
        a.bulkContainer.charge.localeCompare(b.bulkContainer.charge),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: null,
      filterFn: null,
      showFilter: false,
      showOrder: true
    },
    {
      name: 'Lieferant',
      sortOrder: null,
      sortFn: (a: IProductionOrder, b: IProductionOrder) =>
        a.bulkContainer.supplier.name.localeCompare(b.bulkContainer.supplier.name),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: null,
      filterFn: null,
      showFilter: false,
      showOrder: true
    },
    {
      name: 'Bearbeitungsdatum',
      sortOrder: null,
      sortFn: (a: IProductionOrder, b: IProductionOrder) => dayjs(a.processingDate).diff(b.processingDate),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: null,
      filterFn: null,
      showFilter: false,
      showOrder: true
    },
    {
      name: 'Bearbeiter',
      sortOrder: null,
      sortFn: null,
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: null,
      filterFn: null,
      showFilter: false,
      showOrder: true
    },
    {
      name: 'Abgeschlossen',
      sortOrder: null,
      sortFn: (a: IProductionOrder, b: IProductionOrder) => a.isFinished.toString().localeCompare(b.isFinished.toString()),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: null,
      filterFn: null,
      showFilter: false,
      showOrder: true
    },
    {
      name: 'Status',
      sortOrder: null,
      sortFn: (a: IProductionOrder, b: IProductionOrder) => a.status.localeCompare(b.status),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      listOfFilter: null,
      filterFn: null,
      showFilter: false,
      showOrder: true
    },
];

  protected readonly exportedTableVisibleName = 'Production orders list for article';

  @Input() set articleId(val: number) {
    if(val) {
      this._articleId = val;
      this.loadDefault();
    }
  }
  constructor(
    protected _service: ProductionOrdersService,
    protected _cdr: ChangeDetectorRef,
    protected _notify: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: ProductionOrderServiceErrorResolverService
  ) {
    super(_service, _cdr, _notify, renderer, _errorResolver);
  }

  ngOnInit(): void {
  }

  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[] = [],
  ): void {
    this.lastLoadParams = {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      search: null,
      additionalFilters
    };
    filter = [...filter, ...this.predefindedFilters];
    if (this.keepStateDirective) {
      this.keepStateDirective.item = {
        ...this.lastLoadParams
      };
    }
    this.loading = true;
    this._service.getForArticle(this._articleId).pipe(
    ).subscribe(res => {
      this.loading = false;
      this.data = this._data = res.map((value) => {
        return {
          ...value, expand: false
        }
      })
      this._cdr.detectChanges();
    }, this.parseError);
  }

  onCurrentPageDataChange(listOfCurrentPageData: ReadonlyArray<IExpandableItem<IProductionOrder>>): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this._cdr.detectChanges();
  }

  search = (value: string) => {
    this.currentSearch = value.trim();
    const tmpSearch = value.toLowerCase();
    this.data = this._data.filter(element =>(
      element.id.toString().startsWith(tmpSearch)  ||
      element.processingDate.toString().toLowerCase().indexOf(tmpSearch) >= 0 ||
      element.bulkContainer.charge.toLowerCase().indexOf(tmpSearch) >= 0 ||
      element.bulkContainer.supplier.name.toLowerCase().indexOf(tmpSearch) >= 0 ||
      element.createdAt.toString().toLowerCase().indexOf(tmpSearch) >= 0 ||
      element.workers.map(x => x.fullName.toLowerCase()).join('').indexOf(tmpSearch) >= 0 ||
      element.productionOrderVariants.filter(variant => (
        variant.articleVariant.name.toLowerCase().indexOf(tmpSearch) >= 0 ||
        variant.articleVariant.orderNumber.toLowerCase().indexOf(tmpSearch) >= 0
      )).length > 0
    ));
    this._cdr.detectChanges();
  }

  cancelOrder = (id: number) => {
    this.loading = true;
    this._service.cancel(id).pipe(finalize(() => this.loading = false)).subscribe(() => {
      this.onRefreshList(false);
    }, this.parseError);
  }
}
