import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-pdf-safari-modal',
  templateUrl: './document-pdf-safari-modal.component.html',
  styleUrls: ['./document-pdf-safari-modal.component.scss']
})
export class DocumentPdfSafariModalComponent {
  public isVisible = false;

  @Input() set pdfLink(link: string) {
    if (link) {
      this.isVisible = true;
      this._pdfLink = link;
    }
  }

  private _pdfLink: string;

  get pdfLink(): string {
    return this._pdfLink;
  }

  public openLink(event: MouseEvent): void {
    event.preventDefault();
    window.open(this.pdfLink, '_blank');
    this.handleCancel();
  }

  public handleCancel(): void {
    this._pdfLink = '';
    this.isVisible = false;
  }
}
