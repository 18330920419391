import { Observable } from 'rxjs';
import { ApiService } from './ApiService';
import { HttpParams } from '@angular/common/http';
import { ISimpleListItem } from '../interfaces/ISimpleListItem';
import { GenericService } from './GenericService';

export class GenericWithSimpleListService<T> extends GenericService<T>{

  protected baseUrl = '';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  public  getSimpleList( search = ''): Observable<ISimpleListItem[]> {
    const params = new HttpParams()
      .append('search', `${search}`);
    return this.apiService.get(`${this.baseUrl}/simple`, params);
  }
}
