import { GenericService } from '../../../../core/services/GenericService';
import { Injectable } from '@angular/core';
import { IBulkContainerArticle } from '../interfaces/IBulkContainerArticle';
import { ApiService } from '../../../../core/services';
import { Observable } from 'rxjs';
import { ITableResults } from '../../../../core/interfaces';
import { IBulkContainer } from '../interfaces/IBulkContainer';
import { IBulkContainerChangeLog } from '../interfaces/IBulkContainerChangeLog';
import { HttpParams } from '@angular/common/http';
import { ISimpleListItem } from '../../../../core/interfaces/ISimpleListItem';
import { HttpUtils } from '../../../../core/utils/HttpUtils';

@Injectable({
  providedIn: 'root'
})
export class BulkContainersService extends GenericService<IBulkContainer> {
  protected readonly baseUrl = '/bulkcontainers';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getSimple(value: string, articleId= 0 ): Observable<ISimpleListItem[]> {
    const params = new HttpParams()
      .append('Search', value)
      .append('ArticleId', articleId === 0 ? '' : articleId.toString());
    return this.apiService.get(`${this.baseUrl}/simple`, params);
  }

  getQueryableArticlesAll( pageIndex: number,
                   pageSize: number,
                   sortField: string | null,
                   sortOrder: string | null,
                   filters: Array<{ key: string; value: string[] }>,
                   search: Array<{ key: string; value: string[] }>,
                           additionalFilters: Array<{ key: string; value: string[] }>,
  ): Observable<ITableResults<IBulkContainerArticle>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters);
    return this.apiService.get(`${this.baseUrl}/articles`, params);
  }

  getBulkContainerArticleById(articleId: number): Observable<IBulkContainerArticle> {
    return this.apiService.get(`${this.baseUrl}/articles/${articleId}`);
  }

  getQueryableBulkContainerChangeLogByArticleId(articleId: number,
                                       pageIndex: number,
                                       pageSize: number,
                                       sortField: string | null,
                                       sortOrder: string | null,
                                       filters: Array<{ key: string; value: string[] }>): Observable<ITableResults<IBulkContainerChangeLog>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, null, []);
    return this.apiService.get(`${this.baseUrl}/articles/${articleId}/change-logs`, params);
  }

  createChangeLog(bulkContainerId, object:{quantity:number, comment:string}):Observable<any> {
    return this.apiService.post(`${this.baseUrl}/${bulkContainerId}/change-log`, object);
  }

  cancel(bulkContainerId: number, setAsDeleted = false): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/${bulkContainerId}/cancel?setAsDeleted=${ setAsDeleted }`);
  }

  public checkCharge(charge: string, articleId: number, articleVariantId: number): Observable<{ isValid: boolean; }> {
    let params = new HttpParams().append('articleId', articleId);

    if (articleVariantId) {
      params = params.append('articleVariantId', articleVariantId);
    }

    return this.apiService.get(`${ this.baseUrl }/check-charge?charge=${ charge }`, params);
  }
}
