<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  nzWidth="100%"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <app-add-edit-order-form
      #form
      [orderType]="orderType"
      [editObject]="object"
      (formFinished)="onFormFinished($event)"
    ></app-add-edit-order-form>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">Speichern</button>
  </ng-template>
</nz-modal>
