import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { IUser } from '../core/interfaces/IUser';
import { UsersService } from '../core/services/UsersService';
import { NotificationService, RolesService } from '../../../core/services';
import { ListComponentGeneric } from '../../../ui/generics';
import { UsersServiceErrorResolverService } from '../core/services/error-resolvers/users-service-error-resolver.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends ListComponentGeneric<IUser> implements OnInit {

  filterRoles: { text: string, value: string }[];

  protected readonly exportedTableVisibleName = 'Users list';

  constructor(
    protected _service: UsersService,
    protected _cdr: ChangeDetectorRef,
    protected _rolesService: RolesService,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: UsersServiceErrorResolverService
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.filterRoles = this._rolesService.getRoles().map((el) => {
      return {
        text: el,
        value: el
      };
    });
    super.loadDefault();
  }
}
