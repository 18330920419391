<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
 <span class="action">
    <button nz-button nzType="primary" (click)="onDownloadCSV()">Auswertung</button>
  </span>
  <div class="uncompleted-orders-checkbox-wrapper">
    <label
      nz-checkbox
      [(ngModel)]="showOpenTransfers"
      (ngModelChange)="showOpenTransfersChange()"
    >Nicht abgeschlossene Umlagerung</label>
  </div>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  class="stores-transfer-list"
>
  <thead>
  <tr>
    <th
      [(nzChecked)]="checked"
      [nzIndeterminate]="indeterminate"
      (nzCheckedChange)="onAllChecked($event)"
      nzWidth="60px"
    ></th>
    <th nzColumnKey="id" [nzSortFn]="true" nzWidth="75px">#ID</th>
    <th nzColumnKey="originStore.name" [nzSortFn]="true">Ursprungsspeicher</th>
    <th nzColumnKey="targetStore.name" [nzSortFn]="true">Zielspeicher</th>
    <th nzColumnKey="worker.fullName" [nzSortFn]="true">Mitarbaiter</th>
    <th nzColumnKey="deliveryDate" [nzSortFn]="true">Liefertermin</th>
    <th
      nzColumnKey="status"
      [nzSortFn]="true"
      [nzFilters]="statusFilters"
      [nzFilterMultiple]="true"
      [nzFilterFn]="true"
      (nzFilterChange)="filtersChanged()"
    >Status</th>
    <th nzRight nzWidth="120px"></th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let result of data.results">
      <td [nzChecked]="setOfCheckedId.has(result.id)" (nzCheckedChange)="onItemChecked(result.id, $event)"></td>
      <td>{{ result.id }}</td>
      <td>{{ result.originStore.name }}</td>
      <td>{{ result.targetStore.name }}</td>
      <td>{{ result.worker.fullName }}</td>
      <td>{{ result.deliveryDate | dateFormat }}</td>
      <td>{{ result.status | statusFormat }}</td>
      <td nzRight>
        <div class="actions" style="align-items: center;">
           <span
             *ngIf="statusTypes[result.status] === statusTypes.Open"
           >
            <i nz-icon nzType="send" nzTheme="outline" (click)="dispatchTransfer(result.id)"></i>
          </span>

          <span
            *ngIf="statusTypes[result.status] === statusTypes.Dispatched"
          >
            <i nz-icon nzType="book" nzTheme="outline" (click)="bookTransfer(result.id)"></i>
          </span>

          <span
            *ngIf="statusTypes[result.status] !== statusTypes.Canceled && statusTypes[result.status] !== statusTypes.Booked"
          >
            <i nz-icon nzType="stop" nzTheme="outline" (click)="cancelTransfer(result.id)"></i>
          </span>

          <app-add-edit-modal #modal>
            <span trigger>
              <i nz-icon nzType="eye"  nzTheme="outline" (click)="modal.showModal(result)"></i>
            </span>
          </app-add-edit-modal>

          <span *ngIf="result.documentKey">
            <app-download-document [documentKey]="result.documentKey"></app-download-document>
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
