import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DocumentService } from '../../core/services/DocumentService';

@Component({
  selector: 'app-download-documents',
  templateUrl: './download-documents.component.html',
  styleUrls: ['./download-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadDocumentsComponent implements OnInit {
  private _documentKeys: string[];
  private _documentUrl: string;
  @Input() icon = 'file-pdf';
  @Input() set documentKeys(val: string[]) {
    this._documentKeys = val;
    this._documentUrl = this._documentService.getMassDownloadUrl(this._documentKeys);
  }

  constructor(private _documentService: DocumentService) { }

  ngOnInit(): void {
  }

  public get documentKeys(): string[] {
    return this._documentKeys;
  }

  openFile(): void {
    window.open(this._documentUrl, '_blank');
  }
}
