import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'productionUnit'
})
export class ProductionUnitFormatPipe implements PipeTransform {

  public transform(value: number, isBagable: boolean): string {

    value = value || 0;

    if (!isBagable) {
      return `${ value } Stück`;
    } else {
      return `${ (value / 1000).toLocaleString('DE-de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } kg`;
    }
  }
}
