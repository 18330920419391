import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { AddEditFormGeneric } from '../../../ui/generics';
import { IUser } from '../core/interfaces/IUser';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalModalsService, JwtService, NotificationService, RolesService } from '../../../core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UsersService } from '../core/services/UsersService';
import { UsersServiceErrorResolverService } from '../core/services/error-resolvers/users-service-error-resolver.service';

@Component({
  selector: 'app-edit-my-profile-form',
  templateUrl: './edit-my-profile-form.component.html',
  styleUrls: ['./edit-my-profile-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMyProfileFormComponent extends AddEditFormGeneric<IUser> implements OnInit {

  public roles: string[];

  constructor(
    protected _fb: FormBuilder,
    protected _formService: UsersService,
    private _jwtService: JwtService,
    protected _notifyService: NotificationService,
    protected _rolesService: RolesService,
    protected _errorResolver: UsersServiceErrorResolverService,
    private _globalModalService: GlobalModalsService,
    protected _cdr: ChangeDetectorRef,
    private _modal: NzModalService,
    private _viewContainerRef: ViewContainerRef
  ) {
    super(_fb, _formService, _notifyService, _errorResolver);
    this.roles = this._rolesService.getRoles();
    this.form = this._fb.group({
      id: ['', Validators.required],
      login: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      role: ['', Validators.required],
      email: ['', Validators.email],
      phone: [''],
      position: ['', Validators.required],
      twoFactorAuthEnabled: [false]
    });
  }

  ngOnInit(): void {
    this._formService.getMyProfile().subscribe(res => {
      this.editObject = res;
      this.form.patchValue(res);
      this._cdr.detectChanges();
    });
  }

  isAvatarValue(): boolean {
    return !(this.form === null || (this.form.get('firstName').value === '' && this.form.get('lastName').value === ''));
  }

  saveEdit(): void {
    const object = {...this.editObject, ...this.form.value};
    this._formService.updateMyProfile(object).subscribe(() => {
      this._jwtService.updateIdentityClaims(object);
      this._notifyService.pushSuccess('Erfolgreich', `Element wurde korrekt aktualisiert.`);
      this.formFinished.emit(true);
    }, err => {
      this.parseError(err);
      this.formFinished.emit(false);
    });
  }
}
