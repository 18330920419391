<ng-container *ngIf="order.internalComment || order.customerComment ||order.comment">
  <span nz-icon nzType="comment" nzTheme="outline" class="comment-icon" nz-tooltip [nzTooltipTitle]="customerComments"></span>

  <ng-template #customerComments let-thing>
    <div *ngIf="order.customerComment">
      <b>Kundenkommentar</b><br>
      {{order.customerComment}}<br><br>
    </div>
    <div *ngIf="order.internalComment">
      <b>Interner Kommentar</b><br>
      {{order.internalComment}}<br><br>
    </div>
    <div *ngIf="order.comment">
      <b>Externer Kommentar</b><br>
      {{order.comment}}<br><br>
    </div>
  </ng-template>
</ng-container>

