import { Injectable } from '@angular/core';
import { Identity } from '../../interfaces/auth';
import { IUser } from '../../../pages/users/core/interfaces/IUser';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  getIdentity(): Identity {
    return (window.localStorage['identity'] !== undefined ? JSON.parse(window.localStorage['identity']) : false);
  }

  getAccessToken(): string {
    const identity = this.getIdentity();
    if (identity) {
      return identity.accessToken;
    }
    return '';
  }

  getRefreshToken(): string {
    const identity = this.getIdentity();
    if (identity) {
      return identity.refreshToken;
    }
    return '';
  }

  saveIdentity(identity: Identity) {
    window.localStorage['identity'] = JSON.stringify(identity);
  }

  destroyIdentity() {
    window.localStorage.removeItem('identity');
  }

  updateIdentityClaims(userObject: IUser) {
    const identity = this.getIdentity();
    identity.claims = {...identity.claims, ...userObject};
    this.saveIdentity(identity);
  }

  setWorkstationMode(value: boolean) {
    if (value) {
      window.localStorage['workstationMode'] = value;
    } else {
      window.localStorage.removeItem('workstationMode');
    }
  }

  getWorkstationMode(): boolean {
    return window.localStorage.workstationMode ?  window.localStorage.workstationMode : false;
  }
}
