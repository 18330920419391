import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { from, Observable } from 'rxjs';
import { JwtService } from './auth';
import { environment } from '../../../environments/environment';
import { IWebSocketService } from '../interfaces/IWebSocketService';
@Injectable({
  providedIn: 'root'
})
export abstract class SignalRService implements IWebSocketService {
  private _connection: signalR.HubConnection;
  private _initMethod: string;
  private _initializedListeners: string[] = [];

  protected constructor(
    protected readonly jwtService: JwtService
  ) { }

  public buildConnection(hubUrl: string, initializedMethod = 'initializeAsync'): void {
    this._initMethod = initializedMethod;
    this._connection = new signalR.HubConnectionBuilder().configureLogging(signalR.LogLevel.Information).withUrl(`${ environment.wsUrl }${ hubUrl }`).build();
  }

  public start(): Observable<any> {
    return from(this._connection.start().then(() => {
      this._connection.invoke(this._initMethod, this.jwtService.getAccessToken());
    }));
  }

  public createMethod(methodName: string, method: (...args: any[]) => void): void {
    this._initializedListeners.push(methodName);
    this._connection.on(methodName, method);
  }

  public stop(): Observable<any> {
    this._initializedListeners.forEach(x => this._connection.off(x));
    return from(this._connection.stop());
  }

  public onConnected(method: () => void): void {
    this._initializedListeners.push('connected');
    this._connection.on('connected', method);
  }

  public onDisconnected(method: () => void): void {
    this._initializedListeners.push('disconnected');
    this._connection.on('disconnected', method);
  }
}
