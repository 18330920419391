import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { titles } from '../../../environments/titles';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  protected constructor (
    private readonly _angularTitleService: Title,
    private readonly _router: Router
  ) {}

  public populate(): void {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url: string[] = event.urlAfterRedirects.replace(/[0-9]/g, '').split('/');
        const typeParam = new URL(window.location.href).searchParams.get('type');
        if (url[url.length - 1].includes('?')) {
          url.pop();
        }
        if (typeParam) {
          url.push(typeParam);
        }
        const concatUrl: string = url.filter(word => word !== '').join('-');
        this._angularTitleService.setTitle(titles[concatUrl] ? titles[concatUrl] + ' | ' + environment.appName : environment.appName);
      }
    });
  }
}
