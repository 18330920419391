<nz-divider></nz-divider>
<nz-spin [nzSpinning]="loading">
  <form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
  <nz-row>
    <nz-col [nzFlex]="12">
      <ng-container *ngIf="productionOrder">
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">Datum</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{ productionOrder.processingDate|dateFormat }}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">Artikel</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{productionOrder.article.name}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">Lieferant</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{productionOrder.bulkContainer.supplier.name}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">Charge</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{productionOrder.bulkContainer.charge}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">MHD</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{productionOrder.bulkContainer.mhd | dateFormat}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">BIO</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{productionOrder.article.isBio|booleanFormat}}
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="slim">
          <nz-form-label [nzSm]="10" [nzXs]="24">eigener Produktion:</nz-form-label>
          <nz-form-control [nzSm]="13" [nzXs]="24">
            {{productionOrder.article.isOwnProduction | booleanFormat}}
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </nz-col>
    <nz-col [nzFlex]="12" *ngIf="_orderNumber" style="min-height: 50px">
      <ng-container *ngIf="changeLogs$|async as changelog;">
        <nz-table
          #tableRef
          nzShowSizeChanger
          [nzData]="changelog"
          [nzLoading]="loading"
          [nzShowPagination]="false"
        >
          <thead>
          <tr>
            <th>Status</th>
            <th>Datum</th>
            <th>Name</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let log of changelog">
            <td>{{log.newStatus|statusFormat}}</td>
            <td>{{log.createdAt|dateFormat}}</td>
            <td>{{log.worker.fullName}}</td>
          </tr>
          </tbody>
        </nz-table>
      </ng-container>
      <ng-template #changeLogLoader>
        <nz-spin [nzSpinning]="true" style="min-height: 50px"></nz-spin>
      </ng-template>
    </nz-col>
  </nz-row>
<nz-divider></nz-divider>
<nz-row>
  <nz-col nzFlex="24">
    <nz-table
      #tableRef
      nzShowSizeChanger
      [nzData]="variants"
      [nzLoading]="loading"
      [nzShowPagination]="false"
    >
      <thead>
      <tr>

        <th >Variant</th>
        <th>EAN</th>
        <th>Gewicht</th>
        <th>Anzahl</th>
        <th>Gescannt</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr
        *ngFor="let variant of variants"
        [class]="(variant.scanned >= variant.quantity || variant.isCorrected ? 'finished':'')"
        (dblclick)="variant.scanned = variant.quantity"
        style="cursor:pointer;"
      >
        <td>{{ variant.articleVariant.name }}{{ variant.recipeCardVariant ? ' (' + variant.recipeCardVariant.value + ')' : '' }}</td>
        <td>{{variant.articleVariant.ean}}</td>
        <td>{{ variant.articleVariant.customWeight ? (variant.articleVariant.customWeight + ' g') : (variant.articleVariant.purchaseUnit | unitFormat:'kg') }}</td>
        <td>{{variant.quantity}}</td>
        <td>{{variant.scanned}}</td>
        <td>
          <div class="actions">
            <span *ngIf="currentStatus === statuses.Created">
              <i nz-icon nzType="edit" nzTheme="outline" (click)="openCorrectModal(variant)"></i>
            </span>
          </div>
        </td>
      </tr>
      </tbody>
    </nz-table>


  </nz-col>
</nz-row>
</form>
  <div class="ean-scanner--container" *ngIf="currentStatus !== statuses.Posted">
    <span>Die Produkt scannen: </span><input [(ngModel)]="currentEan" (ngModelChange)="handleEanScan($event)" [ngModelOptions]="{standalone: true}" #eanField>
  </div>
</nz-spin>


<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="correctModalVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzWidth]="300"
  (nzOnCancel)="correctModal.close()"
  #correctModal
  *ngIf="productionOrder && currentStatus === statuses.Created"
>
  <ng-template #modalTitle>Korrekt: {{ productionOrder.article.name }} - {{ revisedVariant.articleVariant.name }}</ng-template>

  <ng-template #modalContent>
    <span>Neuer Wert: </span><input [(ngModel)]="newVariantValue">
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="correctModal.close()">Schließen</button>
    <button nz-button nzType="primary" (click)="correctVariant()">Speichern</button>
  </ng-template>
</nz-modal>

<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="insertModalVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  [nzWidth]="500"
  (nzOnCancel)="insertModal.close()"
  #insertModal
  *ngIf="productionOrder && currentStatus === statuses.Labeled"
>
  <ng-template #modalTitle>Menge einbuchen: {{ productionOrder.article.name }}</ng-template>

  <ng-template #modalContent>

    <nz-table
      #tableRef
      nzShowSizeChanger
      [nzData]="variants"
      [nzLoading]="loading"
      [nzShowPagination]="false"
    >
      <thead>
      <tr>
        <th>Variant</th>
        <th>Anzahl</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let variant of variants">
        <td>{{variant.articleVariant.name}}</td>
        <td>{{variant.quantity}}</td>
      </tr>
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="insertModal.close()">Schließen</button>
    <button nz-button nzType="primary" (click)="submitForm()">Speichern</button>
  </ng-template>
</nz-modal>
