import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AddEditModalGeneric } from '../../../ui/generics';
import { IArticle } from '../../../core/interfaces';
import { AddEditArticleFormComponent } from '../../forms/add-edit-article-form/add-edit-article-form.component';

@Component({
  selector: 'app-add-edit-article-modal',
  templateUrl: './add-edit-article-modal.component.html',
  styleUrls: ['./add-edit-article-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditArticleModalComponent extends AddEditModalGeneric<IArticle, AddEditArticleFormComponent> implements OnInit {

  @Output() articleCreated = new EventEmitter<IArticle>();

  constructor(
    protected cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  rebuildTitle(): void {
    this.title = this.isNew ? 'Create new article' : `Edit article: ${this.object.name}`;
  }
}
