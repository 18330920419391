export enum ReportTypes {
  Order = 1,
  ProductionOrder = 2,
  DeliveryNote = 3,
  Export = 4,
  BatchOpenOrdersDocument = 5,
  Label = 6,
  UserIdentityCard = 7,
  OwnProduction = 8,
  StockTransfer = 9,
  PackingList = 10,
  OutletStore = 11,
  SaleReport = 12,
  WhoPacked = 13,
  RoundLabelWithProductName = 14,
  Recipe = 15,
  Voucher = 16,
  EanLabel = 17,
  RectangleLabel50x25 = 18,
  RoundLabel58x58 = 19,
  AttendeesList = 20,
  RoundLabel50x50 = 21,
}
