import {
  Component,
  ChangeDetectionStrategy,
  Input, ChangeDetectorRef
} from '@angular/core';
import { IDeliveryOrder } from '../../core/interfaces/IDeliveryOrder';
import { AddEditModalGeneric } from '../../../../ui/generics';
import { AddEditOrderFormComponent } from '../add-edit-order-form/add-edit-order-form.component';
import { OrderTypes } from '../../core/enums';

@Component({
  selector: 'app-add-edit-order-modal',
  templateUrl: './add-edit-order-modal.component.html',
  styleUrls: ['./add-edit-order-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditOrderModalComponent extends AddEditModalGeneric<IDeliveryOrder, AddEditOrderFormComponent> {

  @Input() orderType = OrderTypes.MIXED;

  public readonly orderTypes = OrderTypes;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }
}
