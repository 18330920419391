import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragBackgroundPosition]',
  host: {
    '(mousedown)': 'onMouseDown($event)',
    '(mouseup)': 'onMouseUp()',
    '(mousemove)': 'onMouseMove($event)',
    '(wheel)': 'onWheel($event)'
  }
})
export class DragBackgroundPositionDirective {

  private _isCtrlPressed = false;
  private _isMouseDown = false;
  private _backgroundSize = 100;
  private readonly _mousePos = {
    x: 0,
    y: 0
  };
  private readonly _imgBgPos = {
    x: 0,
    y: 0
  };

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>
  ) {
    setTimeout(() => {
      const { nativeElement } = this._elementRef;
      const { backgroundSize, backgroundPosition } = nativeElement.style;
      const [x, y] = backgroundPosition.replaceAll('px', '').split(' ');

      this._imgBgPos.x = -(+x);
      this._imgBgPos.y = -(+y);
      this._backgroundSize = +backgroundSize.replace('%', '');

      nativeElement.classList.add('cursor-move');
      nativeElement.style.backgroundSize = this._backgroundSize + '%';
    });
  }

  public onMouseDown(event: MouseEvent): void {
    this._isMouseDown = true;
    this._mousePos.x = event.clientX;
    this._mousePos.y = event.clientY;
  }

  public onMouseMove(event: MouseEvent): void {
    if (this._isMouseDown) {
      this._imgBgPos.x += (this._mousePos.x - event.clientX);
      this._imgBgPos.y += (this._mousePos.y - event.clientY);
      this._mousePos.x = event.clientX;
      this._mousePos.y = event.clientY;
      this._elementRef.nativeElement.style.backgroundPosition = `${ -this._imgBgPos.x }px ${ -this._imgBgPos.y }px`;
    }
  }

  public onMouseUp(): void {
    this._isMouseDown = false;
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    this._isCtrlPressed = [17, 91].includes(event.which);
  }

  @HostListener('window:keyup')
  public onKeyUp(): void {
    this._isCtrlPressed = false;
  }

  public onWheel(event: WheelEvent): void {
    event.preventDefault();

    if (this._isCtrlPressed) {
      if (event.wheelDelta < 0) {
        this._backgroundSize--;
      } else {
        this._backgroundSize++;
      }

      this._elementRef.nativeElement.style.backgroundSize = this._backgroundSize + '%';
    }
  }
}
