import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)},
  { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)},
  { path: 'suppliers', loadChildren: () => import('./pages/suppliers/suppliers.module').then(m => m.SuppliersModule)},
  { path: 'bulk-containers', loadChildren: () => import('./pages/bulk-containers/bulk-containers.module').then(m => m.BulkContainersModule)},
  { path: 'own-production', loadChildren: () => import('./pages/own-production/own-production.module').then(m => m.OwnProductionModule)},
  { path: 'giftbox-production', loadChildren: () => import('./pages/giftbox-production/giftbox-production.module').then(m => m.GiftboxProductionModule)},
  { path: 'production-orders', loadChildren: () => import('./pages/production-orders/production-orders.module').then(m => m.ProductionOrdersModule)},
  { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule)},
  { path: 'articles', loadChildren: () => import('./pages/articles/articles.module').then(m => m.ArticlesModule)},
  { path: 'stores', loadChildren: () => import('./pages/stores/stores.module').then(m => m.StoresModule) },
  { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)},
  { path: 'storestocks', loadChildren: () => import('./pages/stores-stocks/storestocks.module').then(m => m.StorestocksModule)},
  { path: 'stock-transfer', loadChildren: () => import('./pages/stock-transfer/stock-transfer.module').then(m => m.StockTransferModule)},
  { path: 'ean', loadChildren: () => import('./pages/ean/ean.module').then(m => m.EanModule)},
  { path: 'flour', loadChildren: () => import('./pages/flour/flour.module').then(m => m.FlourModule)},
  { path: 'planning-production', loadChildren: () => import('./pages/new-item-produktions/new-item-produktions.module').then(m => m.NewItemProduktionsModule)},
  { path: 'labels-printer', loadChildren: () => import('./pages/labels-printer/labels-printer.module').then(m => m.LabelsPrinterModule)},
  { path: 'tools', loadChildren: () => import('./pages/tools/tools.module').then(m => m.ToolsModule)},
  { path: 'marketing', loadChildren: () => import('./pages/marketing/marketing.module').then(m => m.MarketingModule)},
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)},
  { path: 'marketing', loadChildren: () => import('./pages/marketing/marketing.module').then(m => m.MarketingModule)},
  { path: 'seminars', loadChildren: () => import('./pages/seminars/seminars.module').then(m => m.SeminarsModule)},
  { path: 'controlling', loadChildren: () => import('./pages/controlling/controlling.module').then(m => m.ControllingModule)},
  {
    path: 'recipe-cards-printer',
    loadChildren: () => import('./pages/recipe-cards-printer/recipe-cards-printer.module').then(m => m.RecipeCardsPrinterModule)
  }
  // { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
