import { GenericService } from '../../../../core/services/GenericService';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { Observable } from 'rxjs';
import { IProductionOrder } from '../interfaces/IProductionOrder';
import { IProductionOrderVariant } from '../interfaces/IProductionOrderVariant';
import { IProductionOrderChangeLog } from '../interfaces/IProductionOrderChangeLog';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrdersService extends GenericService<IProductionOrder> {
  protected readonly baseUrl = '/productionorders';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getForArticle(articleId): Observable<IProductionOrder[]> {
    return this.apiService.get(`${this.baseUrl}/article/${articleId}`);
  }

  fill(productionOrderId: number, body: IProductionOrderVariant[]): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/${productionOrderId}/fill`, {
      productionOrderVariants: body
    });
  }

  finish(productionOrderId: number): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/${productionOrderId}/finish`);
  }

  label(productionOrderId: number): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/${productionOrderId}/label`);
  }

  mhdChecked(productionOrderId: number): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/${productionOrderId}/check-mhd`);
  }

  cancel(productionOrderId: number): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/${productionOrderId}/cancel`);
  }

  getChangeLog(productionOrderId): Observable<IProductionOrderChangeLog[]> {
    return this.apiService.get(`${this.baseUrl}/${productionOrderId}/change-logs`);
  }
}
