import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ListComponentGeneric } from '../../../ui/generics';
import { NotificationService } from '../../../core/services';
import { StoresService } from '../core/services/StoresService';
import { StoresServiceErrorResolverService } from '../core/services/error-resolvers/stores-service-error-resolver.service';
import { IStore } from '../core/interfaces/IStore';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends ListComponentGeneric<IStore> implements OnInit {

  protected readonly exportedTableVisibleName = 'Stores list';

  constructor(
    protected _service: StoresService,
    protected _cdr: ChangeDetectorRef,
    protected _notify: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: StoresServiceErrorResolverService
  ) {
    super(_service, _cdr, _notify, renderer, _errorResolver);
  }

  searchColumns = [
    'name'
  ];
}
