import { IUser } from '../interfaces/IUser';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { HttpParams } from '@angular/common/http';
import { GenericWithSimpleListService } from '../../../../core/services/GenericWithSimpleListService';
import { Observable } from 'rxjs';
import { ISimpleListItem } from '../../../../core/interfaces/ISimpleListItem';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends GenericWithSimpleListService<IUser> {
  protected readonly baseUrl = '/users';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  public getSimpleList(search: string = '', onlyProduction = false): Observable<ISimpleListItem[]> {
    const params = new HttpParams()
      .append('search', `${search}`)
      .append('onlyProduction', onlyProduction);

    return this.apiService.get(`${this.baseUrl}/simple`, params);
  }

  resetPassword(id: number) {
    const params = new HttpParams().append('userId', id.toString());
    return this.apiService.post(`${this.baseUrl}/set-default-password`, {}, params);
  }

  getMyProfile() {
    return this.apiService.get(`${this.baseUrl}/my-profile`);
  }

  updateMyProfile(body: IUser) {
    return this.apiService.put(`${this.baseUrl}/my-profile`, body);
  }

  regenerateBatch(id: number): Observable<IUser> {
    const params = new HttpParams().append('userId', id.toString());
    return this.apiService.post(`${this.baseUrl}/regenerate-badge`, {}, params);
  }
}
