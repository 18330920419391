import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { OwnProductionService } from '../../../../own-production/core/OwnProductionService';
import { IOwnProductionOrder } from '../../../../own-production/core/interfaces/IOwnProductionOrder';
import { NotificationService } from '../../../../../core/services';
import { ReportsErrorResolverService } from '../../../../reports/core/services';
import { ListComponentGeneric } from '../../../../../ui/generics';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-own-productions-list',
  templateUrl: './own-productions-list.component.html',
  styleUrls: ['./own-productions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnProductionsListComponent extends ListComponentGeneric<IOwnProductionOrder>  implements OnInit {
  private _articleId: number;
  public loading = false;
  @Input() set articleId(val: number) {
    if (val) {
      this._articleId = val;
      this.loadDefault();
    }
  }

  protected readonly exportedTableVisibleName = 'Own productions list';

  constructor(
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected _ownProductionService: OwnProductionService,
    protected _errorResolver: ReportsErrorResolverService,
    protected _renderer: Renderer2,

  ) {
    super(_ownProductionService, _cdr, _notifyService, _renderer, _errorResolver);
  }

  ngOnInit(): void {
  }

  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[] = [],
  ): void {
    this.lastLoadParams = {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      search: null,
      additionalFilters
    };
    filter = [...filter, ...this.predefindedFilters];
    this.loading = true;
    this._ownProductionService.getOwnProductionsByArticleId(
      pageIndex, pageSize, sortField, sortOrder, filter, this._articleId).pipe(
    ).pipe(
      finalize(() => {
        this.loading = false;
        this._cdr.detectChanges();
      })
    ).subscribe(res => this.data = res, this.parseError);
  }
}
