import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef, ViewContainerRef
} from '@angular/core';
import { D_GLOBAL_MODAL, IGlobalModal } from '../../../core/directives';
import { AddEditModalGeneric } from '../../../ui/generics';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotificationService } from '../../../core/services';
import { IStockTransfer } from '../core/interfaces/IStockTransfer';
import { QrcodeScannerComponent } from '../../../ui/qrcode-scanner/qrcode-scanner.component';

@Component({
  selector: 'app-stock-transfer-process-modal',
  templateUrl: './stock-transfer-process-modal.component.html',
  styleUrls: ['./stock-transfer-process-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: D_GLOBAL_MODAL, useExisting: StockTransferProcessModalComponent }]
})
export class StockTransferProcessModalComponent extends AddEditModalGeneric<IStockTransfer, null>
  implements OnInit, OnDestroy, IGlobalModal {

  @ViewChild('scanField') scanField: ElementRef<HTMLInputElement>;

  public transferNumber = '';
  public isManualInput = false;
  public numberChanged$: Observable<{ val: string }>;

  private _transferNumberChange$ = new Subject<{ val: string }>();

  constructor(
    protected cdr: ChangeDetectorRef,
    private readonly _modal: NzModalService,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _notificationService: NotificationService,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.numberChanged$ = this._transferNumberChange$
    .asObservable()
    .pipe(
      debounceTime(500),
      map(({val}) => {
        if (val) {
          if (val.substring(0,2) === '10' && !this.isManualInput) {
            val = val.substring(2);
          }
          this.transferNumber = val;
        }
        return {val};
      })
    );
    super.ngOnInit();
  }

  afterModalOpen() {
    if (this.scanField) {
      this.setFocusOnScanField();
    }
  }

  setFocusOnScanField(): void {
    setTimeout(() => this.scanField.nativeElement.focus(), 100);
  }

  rebuildTitle(): void {
    // this.title = this.isNew ? 'Transfer' : `Transfer: #${ this.object.id }`;
  }

  handleNumberScan = (val: string): void => {
    this.transferNumber = val.trim();
    if ( /\r|\n/.exec(val)) {
      val = val.trim();
      this._transferNumberChange$.next({ val });
    }
  }

  handleBarcodeScanner = (): void => this.createBarcodeScannerModal();

  createBarcodeScannerModal(): void {
    const modal = this._modal.create({
      nzTitle: 'Barcode scanner',
      nzContent: QrcodeScannerComponent,
      nzViewContainerRef: this._viewContainerRef,
      nzWidth: 689,
      nzFooter: [
        {
          label: 'Ok',
          type: 'primary',
          onClick: () => modal.destroy()
        }
      ]
    });
    modal.afterClose.subscribe(result => {
      if (result && result.code) {
        this.transferNumber = result.code.substring(2);
        this.handleNumberScan(this.transferNumber);
      } else {
        this.transferNumber = '';
      }
      this.cdr.detectChanges();
    });

    this.cdr.detectChanges();
  }

  handleCancel(): void {
    this.clearScanField();
    this.closeModal();
  }

  public clearScanField(): void {
    this.transferNumber = '';
    this.setFocusOnScanField();
    this.cdr.detectChanges();
  }

}
