import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { IdentityService, JwtService } from '../../core/services';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownComponent implements OnInit, OnDestroy {

  @Output() timeout = new EventEmitter<void>();

  private _sub: Subscription;
  public counter = 300;
  tick = 1000;

  constructor(
    private _jwtService: JwtService,
    private _identityService: IdentityService,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private message: NzMessageService
  ) {
    this.trackEvents();
  }

  ngOnInit(): void {
    this.updateExpiredTime();
    this._sub = timer(0, this.tick).subscribe(() => {
      this.counter = Number(localStorage.getItem('expiredTime')) - Math.floor(new Date().getTime() / 1000);
      if (!this.counter || this.counter < 1) {
        this.logout();
      }
      this._cdr.detectChanges();
    });
  }

  trackEvents(): void {
    window.addEventListener('mousemove', this.updateExpiredTime.bind(this));
    window.addEventListener('scroll', this.updateExpiredTime.bind(this));
    window.addEventListener('keydown', this.updateExpiredTime.bind(this));
  }

  cleanTrackEvents(): void {
    window.removeEventListener('mousemove', this.updateExpiredTime.bind(this));
    window.removeEventListener('scroll', this.updateExpiredTime.bind(this));
    window.removeEventListener('keydown', this.updateExpiredTime.bind(this));
  }

  updateExpiredTime() {
    if (this._jwtService.getWorkstationMode()) {
      const expiredTime = Math.floor(new Date().getTime() / 1000) + 300;
      localStorage.setItem('expiredTime', expiredTime.toString());
    }
  }

  logout() {
    this.timeout.emit();
    this._identityService.purgeAuth();
    localStorage.removeItem('expiredTime');
    this._router.navigateByUrl('/auth/login');
  }

  ngOnDestroy() {
    this.cleanTrackEvents();
    this._sub.unsubscribe();
  }
}
