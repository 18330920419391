import { HttpParams } from '@angular/common/http';
import { FilterOperator } from '../types';

export class HttpUtils {
  public static prepareQueryableParams(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
    search: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
    additionalFilters: Array<{ key: string; value: string[]; operator?: FilterOperator; }>,
  ): HttpParams {
    let params = new HttpParams()
    .append('PageIndex', `${pageIndex}`)
    .append('PageSize', `${pageSize}`);
    if (sortField !== null) {
      params = params.append('SortField', `${sortField}`);
    }
    if (sortOrder !== null) {
      params = params.append('SortOrder', `${sortOrder}`);
    }
    if (filters.length > 0) {
      filters.forEach(filter => {
        if (filter.value) {
          filter.value.forEach(value => {
            if (value) {
              params = params.append('Filter', `${ filter.operator ? filter.operator + ':' : '' }${filter.key}:${value}`);
            }
          });
        }
      });
    }

    if (search?.length > 0) {
      search.forEach(el => {
        if (el.value) {
          el.value.forEach(value => {
            if (value) {
              params = params.append('Search', `${el.key}:${value}`);
            }
          });
        }
      });
    }

    if (additionalFilters && additionalFilters?.length > 0) {
      additionalFilters.forEach(el => {
        if (el.value && el.value[0]) {
          params = params.append(el.key, el.value[0]);
        }
      });
    }

    return params;
  }
}
