import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ModalGeneric } from '../../generics';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationModalComponent extends ModalGeneric implements OnInit {

  @Input() title = 'Bestätigung';
  @Input() contentText = 'Sind Sie Sicher?';

  @Output() choice = new EventEmitter<boolean>();

  constructor(
    protected readonly cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  public ngOnInit(): void {
  }

  public handleCancel() {
    super.handleCancel();
    this.choice.emit(false);
  }

  public handleOk(): void {
    super.handleCancel();
    this.choice.emit(true);
  }
}
