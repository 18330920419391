import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ExportListViewComponent } from '../../views/export-list-view/export-list-view.component';

@Component({
  selector: 'app-exports-page',
  templateUrl: './exports-page.component.html',
  styleUrls: ['./exports-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportsPageComponent {
  @ViewChild('exportListViewComponentRef') exportListViewComponentRef: ExportListViewComponent;
}
