import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  Optional,
  Renderer2
} from '@angular/core';
import {
  COMPOSITION_BUFFER_MODE,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from "@angular/forms";

@Directive({
  selector: 'input[trim], textarea[trim]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTrimDirective,
      multi: true
    }
  ]
})
export class InputTrimDirective implements ControlValueAccessor {

  @Input() trim!: string;

  public onChange = (_: any) => {};
  public onTouched = () => {};

  private _value!: string;

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
    @Optional() @Inject(COMPOSITION_BUFFER_MODE) compositionMode: boolean
  ) {}

  @HostListener('blur', ['$event.type', '$event.target.value'])
  public onBlur(event: string, value: string): void {
    this.updateValue(event, value.trim());
    this.onTouched();
  }

  @HostListener('input', ['$event.type', '$event.target.value'])
  public onInput(event: string, value: string): void {
    this.updateValue(event, value);
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    this._value = value === '' ? '' : value || null;

    this._renderer.setProperty(this._elementRef.nativeElement, 'value', this._value);

    if (this.type !== 'text') {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'value', this._value);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  private setCursorPointer(cursorPosition: any, hasTypedSymbol: boolean): void {
    if (hasTypedSymbol && ['text', 'search', 'url', 'tel', 'password'].indexOf(this.type) >= 0) {
      this._elementRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
    }
  }

  private get type(): string {
    return this._elementRef.nativeElement.type || 'text';
  }

  private updateValue(event: string, value: string): void {
    value = this.trim !== '' && event !== this.trim ? value : value.trim();

    const previous = this._value;
    const cursorPosition = this._elementRef.nativeElement.selectionStart;

    this.writeValue(value);

    if ((this._value || previous) && this._value.trim() !== previous) {
      this.onChange(this._value);
    }


    this.setCursorPointer(cursorPosition, (value && previous && value !== previous) as boolean);
  }
}
