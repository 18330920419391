import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../../../core/services';
import { AddEditFormGeneric } from '../../../ui/generics';
import { StoresService } from '../core/services/StoresService';
import { StoresServiceErrorResolverService } from '../core/services/error-resolvers/stores-service-error-resolver.service';
import { IStore } from '../core/interfaces/IStore';

@Component({
  selector: 'app-add-edit-form',
  templateUrl: './add-edit-form.component.html',
  styleUrls: ['./add-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditFormComponent extends AddEditFormGeneric<IStore> implements OnInit {

  constructor(
    protected fb: FormBuilder,
    protected suppliersService: StoresService,
    protected notifyService: NotificationService,
    protected errorResolver: StoresServiceErrorResolverService
  ) {
    super(fb, suppliersService, notifyService, errorResolver);
    this.form = this._fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      isMainStore: [false, Validators.required],
      isActive: [true, Validators.required],
    });
  }
}
