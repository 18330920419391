import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { CoreModule } from '../../core/core.module';
import { UiModule } from '../../ui/ui.module';
import { OpenOrdersComponent } from './open-orders/open-orders.component';
import { OrdersRoutingModule } from './orders-routing.module';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { DeliveryNoteProcessModalComponent } from './delivery-note-process-modal/delivery-note-process-modal.component';
import { BatchesListComponent } from './batches-list/batches-list.component';
import { OrderCommentsComponent } from './ui/order-comments/order-comments.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ClosedOrdersComponent } from './closed-orders/closed-orders.component';
import { GenerateDeliverySlipsModalComponent } from './generate-delivery-slips-modal/generate-delivery-slips-modal.component';

@NgModule({
  declarations: [
    OpenOrdersComponent,
    ListComponent,
    DeliveryNoteProcessModalComponent,
    BatchesListComponent,
    OrderCommentsComponent,
    ClosedOrdersComponent,
    GenerateDeliverySlipsModalComponent
  ],
  imports: [
    CommonModule,
    OrdersRoutingModule,
    CoreModule,
    UiModule,
    NzProgressModule,
    NzToolTipModule,
  ],
  exports: [
    DeliveryNoteProcessModalComponent
  ]
})
export class OrdersModule { }
