<div class="parent">
  <zxing-scanner  #qrscanner
                  [formats]="['QR_CODE', 'EAN_13', 'CODE_128']"
                  [enable]="scannerEnable"
                  (camerasFound)="camerasFoundHandler($event)"
                  (camerasNotFound)="camerasNotFoundHandler($event)"
                  (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>

  <div *ngIf="isLoading" class="loader">
    <i nz-icon nzType="loading" nzTheme="outline" class="loader-icon"></i>
  </div>
</div>

