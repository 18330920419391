<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <div style="margin-right: auto; width: 500px" nz-row>
    <nz-form-item nz-col [nzSpan]="12" class="center">
      <label
        nz-checkbox
        [(ngModel)]="showArticlesBelowMinStock"
        (ngModelChange)="onRefreshList()"
      >Produkte, die unter dem Mindestbestand liegen</label>
    </nz-form-item>
  </div>

  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>
<nz-table
  #tableRef
  [nzScroll]="{ x: tableMaxWidth }"
  nzShowSizeChanger
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="non-packing-list-table"
>
  <thead>
  <tr>
    <th nzColumnKey="articleVariant.id" [nzSortFn]="true" nzWidth="75px" nzSortOrder="descend">#ID</th>
    <th nzColumnKey="articleVariant.orderNumber" [nzSortFn]="true">Number</th>
    <th nzColumnKey="articleVariant.name" [nzSortFn]="true">Name</th>
    <th nzColumnKey="nonPackableState" [nzSortFn]="true">Bestand</th>
    <th nzColumnKey="minStock" [nzSortFn]="true">Mindestbestand</th>
    <th
      nzColumnKey="supplier.name"
      [nzFilters]="(supplierFilters$ | async) || []"
      [nzSortFn]="true"
      [nzFilterMultiple]="true"
      [nzFilterFn]="true"
    >letzter Lieferant</th>
    <th class="separator-box" nzWidth="44px"></th>
    <th nzColumnKey="orderDate" [nzSortFn]="true">Bestelltermin</th>
    <th nzColumnKey="orderSupplier.name" [nzSortFn]="true">Lieferant</th>
    <th nzColumnKey="orderQuantitySum" [nzSortFn]="true">Menge</th>
    <th class="separator-box" nzWidth="27px"></th>
    <th nzWidth="73px"></th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td colspan="6" style="text-align: center">Artikel</td>
    <td class="separator-box">
      <div class="separator"></div>
    </td>
    <td colspan="3" style="text-align: center; padding-right: 60px;" class="next-deliver">Next delivery</td>
    <td class="separator-box">
      <div class="separator"></div>
    </td>
    <td></td>
  </tr>
  <tr *ngFor="let article of data.results">
    <td>{{article.articleVariant.id}}</td>
    <td>{{article.articleVariant.orderNumber}}</td>
    <td>{{article.articleVariant.articleName}} {{ article.articleVariant.name }}</td>
    <td>{{ article.nonPackableState | productionUnit:false }}</td>
    <td>{{ article.minStock !== null ? (article.minStock | productionUnit:false) : '' }}</td>
    <td>{{ article.supplier?.name }}</td>
    <td class="separator-box">
      <div class="separator"></div>
    </td>
    <td class="next-deliver">{{article.orderDate|dateFormat}}</td>
    <td class="next-deliver">{{article.orderSupplier?.name}}</td>
    <td class="next-deliver">
      {{ article.orderQuantitySum ? (article.orderQuantitySum | productionUnit:false) : 'Keine weitere Lieferung geplant' }}
    </td>
    <td class="separator-box">
      <div class="separator"></div>
    </td>
    <td>
      <div class="actions">
          <span>
            <a
              [routerLink]="['/bulk-containers/non-packing/', article.articleVariant.id]"
              [queryParams]="{ 'tab': 'deliveries'}"
            >
              <i nz-icon nzType="edit"  nzTheme="outline"></i>
            </a>
          </span>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
