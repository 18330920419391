import {
  Directive, ElementRef, Inject, InjectionToken,
  Input,
  OnInit, Optional, ViewContainerRef
} from '@angular/core';

import { AddEditModalGeneric } from '../../ui/generics';
import { GlobalModalsService } from '../services';

export const D_GLOBAL_MODAL = new InjectionToken<IGlobalModal>(
  "D_GLOBAL_MODAL"
);

export interface IGlobalModal {
  showModal(object: any): void;
}

@Directive({
  selector: '[globalModal]'
})
export class GlobalModalDirective implements OnInit {
  private _globalModalId;
  @Input() set globalModal(id: string) {
    this._globalModalId = id;
    this._globalModalService.registerModal(this._globalModalId, this._viewRef);
  }

  private readonly _viewRef: any;

  constructor(
    @Optional() @Inject(D_GLOBAL_MODAL) component: IGlobalModal | null,
    private _globalModalService: GlobalModalsService
  ) {
    this._viewRef = component;
  }


  ngOnInit() {}
}
