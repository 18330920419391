<app-inner-box>
  <app-subpage-header [title]="title"></app-subpage-header>
  <br>
  <app-list #listComponent (reloadBatchDocumentsList)="batchesListComponent.onRefreshList()" (loaded)="ordersLoaded($event)"></app-list>
</app-inner-box>
<app-inner-box>
  <app-subpage-header title="Generierte Batch-Documenten" tooltipText="In der Tabelle unten finden Sie die Batch-Documenten der letzten 7 Tage."></app-subpage-header>
  <br>
  <app-batches-list #batchesListComponent></app-batches-list>
</app-inner-box>
