import { IBaseUser } from './IBaseUser';
import * as dayjs from 'dayjs';

export interface IDocument {
  type: string,
  documentKey: string,
  outputType: string,
  user: IBaseUser,
  createdAt: dayjs.Dayjs;
}
