<app-table-header
  (reload)="load()"
  [loading]="loading"
  [withExportOption]="false"
></app-table-header>

<nz-table
  #tableRef
  style="width: 100%"
  [nzData]="variants"
  [nzLoading]="loading"
  [nzShowPagination]="false"
  *ngIf="storesArr$ | async as storesArr"
>
  <thead>
    <tr>
      <th nzColumnKey="id" nzWidth="75px">#ID</th>
      <th nzColumnKey="name">Name</th>
      <th nzColumnKey="orderNumber">Nummer</th>
      <th nzColumnKey="ean">EAN</th>
      <th *ngFor="let store of storesArr" [nzColumnKey]="store.id">{{store.value}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let variant of variants">
      <td>{{variant.id}}</td>
      <td>{{variant.name || variant.articleName }}</td>
      <td>{{variant.orderNumber}}</td>
      <td>{{variant.ean}}</td>
      <td *ngFor="let store of storesArr; let i = index;">
        {{ variant.stocks | stockByStore:store.value }}
      </td>
    </tr>
  </tbody>
</nz-table>

<app-store-stocks-corrections-modal #correctionsModalComponent></app-store-stocks-corrections-modal>
