import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenOrdersComponent implements OnInit {

  private _basicTitle = 'Offene Bestellungen';
  public title  = this._basicTitle;
  constructor() { }

  ngOnInit(): void {
  }

  ordersLoaded(val: number) {
    if (val > 0) {
      this.title = `${this._basicTitle} (${val})`;
    } else {
      this.title = `${this._basicTitle}`;
    }
  }

}
