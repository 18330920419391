import { Injectable } from '@angular/core';
import { IdentityService } from './identity.service';
// import { RoleService } from './RoleService';
// import { rules } from '../../../../environments/rules';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    public identityService: IdentityService
  ) { }

  // public getRulesForCurrentApplication(): string[] {
  //   const currentRole = this.identityService.getCurrentIdentity().role;
  //   switch (currentRole) {
  //     case 'CinemaOwner':
  //       return rules.CinemaOwner;
  //     case 'Manager':
  //       return rules.Manager;
  //     case 'StandardUser':
  //       return rules.StandardUser;
  //     case 'Administrator':
  //       return rules.Administrator;
  //     case 'SuperAdministrator':
  //       return rules.SuperAdministrator;
  //     default:
  //       return [];
  //   }
  // }

  public hasAccess(rule: string): boolean {
    // const appRules = this.getRulesForCurrentApplication();
    // return appRules.includes(rule);
    return true;
  }
}
