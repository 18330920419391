export enum FlourStockTypes {
  Munich,
  Nuremberg,
  Hechendorf,
  Salzburg,
  Munster = 6
}

export enum FlourStockTypesTranslations {
  Munich = 'München',
  Nuremberg = 'Nürnberg',
  Hechendorf = 'Hechendorf',
  Salzburg = 'Salzburg',
  Munster = 'Münster'
}
