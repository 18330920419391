import { Observable } from 'rxjs';
import { StaticDependenciesService } from '../services';

export class Csv {
  public static downloadCSV(
    data: { results: any },
    setOfCheckedId: Set<number>,
    service: { exportCSV: (ids: number[]) => Observable<any> },
    fileName: string,
    ) {

    const ids = data.results.filter(value => setOfCheckedId.has(value.id)).map(value => (value.id));

    if (ids.length < 1) {
      StaticDependenciesService.notificationService.pushWarning('Achtung', 'Bitte wählen Sie zunächst die zu exportierenden Elemente aus.');
      return;
    }

    service.exportCSV(ids).subscribe(value => {
      const hiddenElement = StaticDependenciesService.renderer.createElement('a');
      hiddenElement.href = window.URL.createObjectURL(value);
      hiddenElement.download = fileName + ids.join('_') + '.csv';
      hiddenElement.click();
    });
  }
}
