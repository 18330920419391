import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ITableResults } from '../../../../core/interfaces';
import { BulkContainersServiceErrorResolverService } from '../../core/services';
import { NotificationService } from '../../../../core/services';
import { ListComponentGeneric } from '../../../../ui/generics';
import { IBulkContainer } from '../../core/interfaces/IBulkContainer';
import { NonPackableService } from '../../core/services/NonPackableService';
import { INonPackableArticle } from '../../core/interfaces/INonPackableArticle';
import { Observable } from 'rxjs';
import { NzTableFilterList } from 'ng-zorro-antd/table';
import { map } from 'rxjs/operators';
import { SuppliersService } from '../../../suppliers/core/services/SuppliersService';

@Component({
  selector: 'app-non-packing-list',
  templateUrl: './non-packing-list.component.html',
  styleUrls: ['./non-packing-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NonPackingListComponent extends ListComponentGeneric<IBulkContainer> implements OnInit {

  public showArticlesBelowMinStock = false;
  public supplierFilters$: Observable<NzTableFilterList>;
  // @ts-ignore
  public data: ITableResults<INonPackableArticle> = {
    results: [],
    info: {
      page: 1,
      results: 0,
      totalResults: 0,
      exportTableName: null
    }
  };

  searchColumns = [
    'articleVariant.id',
    'articleVariant.orderNumber',
    'articleVariant.name',
    'articleVariant.fullName',
    'articleVariant.articleName',
    'nonPackableState',
    'orderDate',
    'orderSupplier.name',
    'orderQuantitySum',
  ];

  protected readonly exportedTableVisibleName = 'Non packing list';

  constructor(
    protected _service: NonPackableService,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BulkContainersServiceErrorResolverService,
    private readonly _supplierService: SuppliersService
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.supplierFilters$ = this._supplierService.getSimpleList().pipe(
      map(value => {
        return value.map((x) => {
          return {
            value: x.value,
            text: x.value
          };
        });
      })
    ) as Observable<NzTableFilterList>;
  }

  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[] = [],
  ): void {
    if (this.showArticlesBelowMinStock) {
      filter = [
        { key: 'isBelowMinStock', value: ['true'] },
        ...filter,
      ];
    }

    if (!sortField) {
      sortField = 'articleVariant.id';
    }

    if (!sortOrder) {
      sortOrder = 'descend';
    }

    this.lastLoadParams = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortField: sortField,
      sortOrder: sortOrder,
      filter: filter,
      search: search,
      additionalFilters
    };

    if (this.keepStateDirective) {
      this.keepStateDirective.item = {
        ...this.lastLoadParams
      };
    }

    this.loading = true;

    this._service.getQueryableArticlesAll(pageIndex, pageSize, sortField, sortOrder, filter, search, additionalFilters).pipe(
    ).subscribe(res => {
      this.loading = false;
      this.data = res;
      this._cdr.detectChanges();
    }, this.parseError);
  }
}

