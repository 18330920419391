import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AddEditFormGeneric } from './AddEditFormGeneric';
import { Subscription } from 'rxjs';
import { ModalGeneric } from './ModalGeneric';
@Injectable({
  providedIn: 'root'
})
@Component({
  template: ''
})
export abstract class AddEditModalGeneric<T, F extends AddEditFormGeneric<T>> extends ModalGeneric implements OnInit, OnDestroy {

  @ViewChild('form') form: F;

  @Input() triggerTitle = 'Neu erstellen';

  @Output() refresh  = new EventEmitter<void>();

  public isConfirmLoading = false;
  public title = 'Neu erstellen';
  public object: null | T = null;
  protected isNew = false;

  protected constructor(
    protected cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  ngOnInit(): void {
  }

  showModal(object: null | T): void {
    this.isNew = (object === null);
    this.object = object;
    this.rebuildTitle();
    super.showModal();
  }

  rebuildTitle(): void {
    this.title = this.isNew ? 'Neu erstellen' : 'Bearbeiten';
  }

  handleOk(): void {
    this.isConfirmLoading = true;
    this.form.submitForm();
  }

  onFormFinished(state: boolean): void {
    if (state) {
      this.handleCancel();
      this.refresh.emit();
    }

    this.isConfirmLoading = false;
  }
}
