import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StaticDependenciesService } from '../services';

type Descriptor = TypedPropertyDescriptor<(...args: any[]) => Observable<any>>;

export function HttpError(errorCodes: Record<string, string> = null): (target: object, propertyKey: string, descriptor: Descriptor) => Descriptor {

  return (target: object, propertyKey: string, descriptor: Descriptor): Descriptor => {

    const originalMethod: (...args: any[]) => Observable<any> = descriptor.value;

    descriptor.value = function(...args: any[]): Observable<any> {

      const methodStringed = originalMethod.toString();
      const pattern = new RegExp('\\b' + propertyKey + '\\b\\((.*)\\)', 'gi');

      let match = methodStringed.match(pattern)[0] ?? '';

      match = match
        .replace(propertyKey + '(', '')
        .replace(')', '')
        .replaceAll(' ', '');

      const params = match.split(',').map((param, index) => {
        return {
          param,
          value: args[index]
        };
      });

      return originalMethod.call(this, ...args).pipe(
        catchError(error => {
          let errorMessage = errorCodes ? errorCodes[error.InternalCode] : null;

          if (!errorMessage) {
            errorMessage = `
              Internal error<br><br>
              Class: ${ target.constructor.name }<br>
              Method: ${ propertyKey }<br>
              Message: ${ error.request.message }<br>
              Params: ${ JSON.stringify(params) }
            `;

            console.log(errorMessage);
          }

          StaticDependenciesService.notificationService.pushError('Fehler', errorMessage);

          return throwError(error);
        })
      );
    };

    return descriptor;
  };
}
