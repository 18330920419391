import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from '../../../../../core/services/error-resolvers/base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersServiceErrorResolverService extends BaseServiceErrorResolverService {
  codes = {
    ChargesExistsInDb : 'Chargennummer existiert in der Datenbank'
  }
}
