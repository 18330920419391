<div #refModalTrigger><ng-content select="[trigger]"></ng-content></div>
<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="450"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <nz-row>
      <form nz-form [formGroup]="form" (ngSubmit)="submitForm()" style="width: 100%;">
        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="name" nzRequired>
            <span>Neue Menge</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <div class="d-flex align-items-center">
              <input nz-input id="newQuantity" type=number [min]="0" formControlName="newQuantity" />&nbsp;kg
            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="name" nzRequired>
            <span>Kommentar</span>
          </nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
            <textarea nz-input id="comment" type=number formControlName="comment" ></textarea>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-row>

  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">Speichern</button>
  </ng-template>
</nz-modal>
