import { GenericService } from '../../../../core/services/GenericService';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services';
import { IDeliveryOrder } from '../interfaces/IDeliveryOrder';
import { Observable } from 'rxjs';
import { ISimpleListItem } from '../../../../core/interfaces/ISimpleListItem';
import { HttpParams } from '@angular/common/http';
import { IDeliveryOrderProduct } from '../interfaces/IDeliveryOrderProduct';

@Injectable({
  providedIn: 'root'
})
export class OrdersService extends GenericService<IDeliveryOrder> {
  protected readonly baseUrl = '/orders';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getArticlesSimpleList(search = '', onlyNonPacking: boolean, supplierId?: number): Observable<ISimpleListItem[]> {
    let params = new HttpParams()
      .append('search', `${search}`)
      .append('onlyNonPacking', onlyNonPacking);

    if (supplierId) {
      params = params.append('supplierId', supplierId);
    }

    return this.apiService.get(`${this.baseUrl}/articles/simple`, params);
  }

  bookProductOrder(orderProduct: IDeliveryOrderProduct): Observable<null> {
    return this.apiService.post(`${this.baseUrl}/products/${orderProduct.id.toString(10)}/book/`, orderProduct );
  }

  cancelProductOrder(id: number): Observable<null> {
    return this.apiService.post(`${this.baseUrl}/products/${id}/cancel` );
  }

  cancel(id: number): Observable<null> {
    return this.apiService.post(`${this.baseUrl}/${id}/cancel` );
  }
}
