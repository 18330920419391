import { Injectable } from '@angular/core';
import { GenericService } from '../../../core/services/GenericService';
import { ApiService } from '../../../core/services';
import { Observable } from 'rxjs';
import { IFormulaIngredient } from './interfaces/IFormulaIngredient';
import { IOwnProductionOrder } from './interfaces/IOwnProductionOrder';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { HttpParams } from '@angular/common/http';
import { IDeliveryOrderProduct } from '../../bulk-containers/core/interfaces/IDeliveryOrderProduct';
import { ITableResults } from '../../../core/interfaces';
import { IBulkContainerArticle } from '../../bulk-containers/core/interfaces/IBulkContainerArticle';
import { HttpUtils } from '../../../core/utils/HttpUtils';

@Injectable({
  providedIn: 'root'
})
export class OwnProductionService extends GenericService<IOwnProductionOrder> {
  protected readonly baseUrl = '/ownproductions';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  updateFormulaIngredientsByArticleId(id: number, object: {articleFormulaIngredients: IFormulaIngredient[]}, ): Observable<any> {
    return this.apiService.put(`${this.baseUrl}/articles/${id}/formula-ingredient`, object);
  }

  getFormulaIngredientsByArticleId(id: number): Observable<IFormulaIngredient[]> {
    return this.apiService.get(`${this.baseUrl}/articles/${id}/formula-ingredient`);
  }

  getArticlesSimpleList( search = ''): Observable<ISimpleListItem[]> {
    const params = new HttpParams()
      .append('search', `${search}`);
    return this.apiService.get(`${this.baseUrl}/articles/simple`, params );
  }

  bookProductOrder(orderProduct: IDeliveryOrderProduct): Observable<null> {
    return this.apiService.post(`${this.baseUrl}/products/${orderProduct.id.toString(10)}/book/`, orderProduct );
  }

  cancelProductOrder(id: number): Observable<null> {
    return this.apiService.post(`${this.baseUrl}/products/${id}/cancel` );
  }

  cancel(id: number): Observable<null> {
    return this.apiService.post(`${this.baseUrl}/${id}/cancel` );
  }

  cancelOrderProduct(productId: number): Observable<null> {
    return this.apiService.post(`${this.baseUrl}/products/${productId}/cancel`);
  }

  getOwnProductionsByArticleId( pageIndex: number,
                                pageSize: number,
                                sortField: string | null,
                                sortOrder: string | null,
                                filters: Array<{ key: string; value: string[] }>,
                                articleId: number): Observable<ITableResults<IOwnProductionOrder>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, null, []);
    return this.apiService.get(`${this.baseUrl}/article/${articleId}`, params);
  }

}
