<div #refModalTrigger><ng-content select="[trigger]"></ng-content></div>
<div *ngIf="(refModalTrigger.children.length == 0)">
  <button nz-button nzType="primary" (click)="showModal(null)">
    <i nz-icon nzType="plus-circle" nzTheme="outline"></i><span>{{triggerTitle}}</span>
  </button>
</div>
<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
  (nzAfterOpen)="afterModalOpen()"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <div class="manual-input-switch">
      Manuelle Eingabe
      <nz-switch [(ngModel)]="isManualInput"></nz-switch>
    </div>
    <div class="scan-field">
      Die Auftragsnummer scannen: <input [(ngModel)]="orderNumber" (ngModelChange)="handleNumberScan($event)" appPossitiveNumbers #scanField nz-input>
      &nbsp;<button nz-button nzType="primary">
      <i nz-icon nzType="barcode" nzTheme="outline" (click)="handleBarcodeScanner()"></i>
    </button>
    </div>

    <app-process-form #form [orderNumber]="numberChanged$ | async" (formFinished)="onFormFinished($event)" (statusChange)="handleChangeStatus($event)"></app-process-form>
  </ng-template>

  <ng-template #modalFooter>
    <button
      nz-button
      nzType="default"
      *ngIf="form && form.currentStatus === orderStatuses.MhdChecked"
      (click)="printLabels()"
    >Etiketten Drucken</button>

    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>

    <button
      nz-button nzType="primary"
      (click)="handleOk()"
      [nzLoading]="isConfirmLoading"
      *ngIf="orderStatus === orderStatuses.Created"
    >Abgefüllt
    </button>

    <button
      nz-button nzType="primary"
      (click)="handleOk()"
      [nzLoading]="isConfirmLoading"
      *ngIf="orderStatus === orderStatuses.MhdChecked"
    >Etikettiert
    </button>

    <button
      nz-button
      nzType="primary"
      (click)="handleInsert()"
      [nzLoading]="isConfirmLoading"
      *ngIf="orderStatus && orderStatus === orderStatuses.Labeled"
    >Einbuchen
    </button>
  </ng-template>
</nz-modal>

<ng-template #variantsWithoutLabelKey>
  <h3 style="padding-bottom: 16px;">Unterhalb von Variantenlabels werden keine Labels generiert, füllen Sie zuerst Variantenlabels.</h3>
  <nz-table #tableRef *ngIf="form" [nzData]="form.editObject.article.articleVariants" [nzShowPagination]="false">
    <thead>
    <tr>
      <th nzColumnKey="id">Variantenname</th>
      <th nzColumnKey="createdAt">Bestellnummer</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let variant of (form.editObject.productionOrderVariants | getVariantsWithoutLabel)">
        <td>{{ variant.articleVariant.name }}</td>
        <td>{{ variant.articleVariant.orderNumber }}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>
