<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="1000"
>
  <ng-template #modalTitle>{{ title }}</ng-template>

  <ng-template #modalContent>
    <form class="export-table-modal-content" [formGroup]="form" id="export-table-form" (ngSubmit)="exportTable()">
      <label>
        Wählen Sie Spalten
      </label>
      <nz-select
        class="multiselect"
        nzMode="multiple"
        [nzOptions]="fields"
        formControlName="selectedFields"
      ></nz-select>
      <label>
        Wählen Sie Dokumenttyp
      </label>
      <nz-select formControlName="targetType" class="document-select">
        <nz-option *ngFor="let type of documentTypes | keyvalue" [nzLabel]="type.key | uppercase" [nzValue]="type.value"></nz-option>
      </nz-select>
      <label nz-checkbox formControlName="withTableParams" style="display: flex !important;">
        Tabelle mit ausgewählter Sortierung, Filterung und Suche exportieren
      </label>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" type="submit" form="export-table-form">Export</button>
    <button nz-button nzType="default" (click)="handleCancel()">Schließen</button>
  </ng-template>
</nz-modal>
