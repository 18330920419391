import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './ApiService';
import { ReportTypes } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {

  private readonly _baseUrl = '/labels';

  constructor(
    private readonly _apiService: ApiService,
  ) {
  }

  public generateProductNameLabel(
    body: { type: ReportTypes; data: { name: string; ingredients: string; content: string; mhd: string; charge: string; ean: string; addBarCode: boolean; allIngredientsAreBio: boolean; } }
  ): Observable<{ labelKey: string }> {
    return this._apiService.post(`${ this._baseUrl }/product-name/generate-label`, body);
  }

  public generateWhoPackedLabel(packerName: string): Observable<{ labelKey: string }> {
    return this._apiService.post(`${ this._baseUrl }/who-packed/generate-label`, {
      name: packerName
    });
  }

  public generateRoundLabelWithProductName(articleName: string): Observable<{ labelKey: string }> {
    return this._apiService.post(`${ this._baseUrl }/round-label-with-product-name/generate-label`, {
      name: articleName
    });
  }

  public generateEANLabel(ean: string): Observable<{ labelKey: string }> {
    return this._apiService.post(`${ this._baseUrl }/ean/generate-label`, {
      ean
    });
  }
}
