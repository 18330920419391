import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild, ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../core/services/UsersService';
import { IUser } from '../core/interfaces/IUser';
import { NotificationService, RolesService } from '../../../core/services';
import { AddEditFormGeneric } from '../../../ui/generics';
import { UsersServiceErrorResolverService } from '../core/services/error-resolvers/users-service-error-resolver.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-add-edit-form',
  templateUrl: './add-edit-form.component.html',
  styleUrls: ['./add-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditFormComponent extends AddEditFormGeneric<IUser> implements OnInit {
  @ViewChild('confirmModalContent') confirmModalContent;
  @ViewChild('confirmResetModalContent') confirmResetModalContent;

  currentMailTo: SafeUrl;
  currentPassword = '';
  roles: string[];

  constructor(
    protected _fb: FormBuilder,
    protected _usersService: UsersService,
    protected _notifyService: NotificationService,
    protected _rolesService: RolesService,
    protected _errorResolver: UsersServiceErrorResolverService,
    private _modal: NzModalService,
    private _notifcationService: NzNotificationService,
    private _sanitizer: DomSanitizer,
    private _cdr: ChangeDetectorRef
  ) {
    super(_fb, _usersService, _notifyService, _errorResolver);
    this.roles = this._rolesService.getRoles();
    // use FormBuilder to create a form group
    this.form = this._fb.group({
      login: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      role: ['', Validators.required],
      phone: [''],
      isProduction: [false],
      email: ['', Validators.email],
      position: ['', Validators.required],
    });
  }

  isAvatarValue(): boolean {
    return !(this.form === null || (this.form.get('firstName').value === '' && this.form.get('lastName').value === ''));
  }

  saveNew(): void {
    const object: IUser = this.form.value;
    this._usersService.create(object).subscribe(data => {
        this.currentMailTo = this._sanitizer.bypassSecurityTrustUrl(`mailto:?subject=Hello%20in%20Baobab%20Manager!&body=Hello%20new%20user%20%3B)%0A%0AOne%20of%20administrators%20from%20Baobab%20Manager%20created%20account%20for%20you.%20%0AYou%20can%20go%20to%20https%3A%2F%2Fmanager.gdw.zone%20and%20login%20there.%20%0A%0APassword%3A%20${escape(data)}%0AAbout%20login%2C%20please%20ask%20your%20supervisor.%0A%0ABest%20Regards%2C%0ABaobab%20Team`);
        this.currentPassword = data;
        this._modal.success({
          nzTitle: 'User has been created correctly',
          nzContent: this.confirmModalContent
        });
        this.formFinished.emit(true);
      }, this.parseError);
  }

  resetPassword(): void {
    this._usersService.resetPassword(this.editObject.id).subscribe(data => {
        this.currentMailTo = this._sanitizer.bypassSecurityTrustUrl(`mailto:?subject=Your%20Password%20for%20Baobab%20Manager%20accout%20has%20been%20reset!&body=Hello%20user%20%3B)%0A%0AOne%20of%20administrators%20from%20Baobab%20Manager%20reset%20account%20password%20for%20you.%20%0AYou%20can%20go%20to%20https%3A%2F%2Fmanager.gdw.zone%20and%20login%20there.%20%0A%0ANew%20Password%3A%20${escape(data)}%0AAbout%20login%2C%20please%20ask%20your%20supervisor.%0A%0ABest%20Regards%2C%0ABaobab%20Team`);
        this.currentPassword = data;
        this._modal.success({
          nzTitle: 'Password reset successfully',
          nzContent: this.confirmResetModalContent
        });
      }, this.parseError);
  }

  regenerateBatch() {
    this._usersService.regenerateBatch(this.editObject.id).subscribe(data => {
      this.editObject.documentKey = data.documentKey;
      this._notifcationService.success('Batch', 'Batch korrekt regenerieren');
      this._cdr.detectChanges();
    }, this.parseError);
  }
}
