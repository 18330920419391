import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NonPackableService } from '../../core/services/NonPackableService';
import { INonPackableArticle } from '../../core/interfaces/INonPackableArticle';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { NotificationService } from '../../../../core/services';
import {
  CommentsListComponent
} from '../../bulk-containers/single-bulk-container/comments-list/comments-list.component';
import { ArticlesCommentsService } from '../../../articles/core/ArticlesCommentsService';
import { ArticlesService } from '../../../articles/core/ArticlesService';

@Component({
  selector: 'app-single-non-packing',
  templateUrl: './single-non-packing.component.html',
  styleUrls: ['./single-non-packing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleNonPackingComponent implements OnInit, OnDestroy {

  @ViewChild('commentsListComponent') commentsListComponent: CommentsListComponent;

  public title: string;
  public articleId: number;
  public parentTitle = 'Non-packing';
  public parentLink = '/bulk-containers/non-packing';
  private _subs: Subscription[] = [];
  articleId$ = new BehaviorSubject<number>(null);
  nonPack$: Observable<INonPackableArticle> = null;
  public articleName: string;
  public showCommentControl = false;
  public newComment: string;

  private _nonPackingArticle: INonPackableArticle;

  constructor(
    private _service: NonPackableService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _notificationService: NotificationService,
    private _articlesCommentsService: ArticlesCommentsService,
    private _articlesService: ArticlesService,
  ) { }

  ngOnInit(): void {
    this._subs.push(
        this._route.params.subscribe(params => {
          this.articleId$.next(+params['id']);
        }),
        this._route.queryParams.subscribe(params => {
          if (!params.hasOwnProperty('tab')) {
            this._router.navigateByUrl(this._router.url + '?tab=deliveries');
          }
        })
      );
      this.articleId$.asObservable().subscribe(() => this.loadContent());
  }

  public loadContent(): void {
    this.nonPack$ = this._service.getNonPackableArticleById(this.articleId$.getValue()).pipe(
      tap(res => {
        this.articleName = res.articleVariant.articleName + ' ' + res.articleVariant.name;
        this.title = this.parentTitle + ': ' + this.articleName;
        this.articleId = res.articleVariant.articleId;
        this._nonPackingArticle = res;
      }),
      catchError(err => {
        if (err.request.status === 404) {
          this._router.navigateByUrl('/bulk-containers/non-packing');
        }

        return observableThrowError(err);
      })
    );
  }

  public saveComment(): void {
    if (!this.newComment) {
      this._notificationService.pushError('Fehler', 'Comment cannot be empty.');
    } else {
      this._articlesCommentsService.create({
        articleId: this._nonPackingArticle.articleVariant.articleId,
        articleVariantId: this.articleId$.getValue(),
        comment: this.newComment
      }).subscribe({
        next: () => {
          this.loadContent();
          this.newComment = null;
          this.showCommentControl = false;
          this.commentsListComponent.onRefreshList(true);
        }
      });
    }
  }

  public updateMinStock(minStock: number): void {
    this._articlesService.updateArticleMinStock(
      this._nonPackingArticle.articleVariant.articleId,
      this.articleId$.getValue(),
      minStock
    ).subscribe({
      next: () => this._notificationService.pushSuccess('Erfolgreich', `Der Mindestbestand wurde korrekt angelegt`),
      error: () => this._notificationService.pushError('Fehler', `Fehler beim Speichern des Mindestbestands`),
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
