import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() set fullName({ firstName, lastName }: { firstName: string; lastName: string; }) {
    this._firstName = firstName;
    this._lastName = lastName;
    this.rebuildAvatar();
  }
  @Input() set sizePx(val: number) {
    this._sizePx = val;
    this.calculate();
  }

  private readonly defaultProportion = [160, 50];
  private readonly _colours = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50', '#f1c40f', '#e67e22', '#e74c3c', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d'];

  color = '';
  text = '';
  fontSize = this.defaultProportion[1];
  size = this.defaultProportion[0];
  private _firstName: string;
  private _lastName: string;
  private _sizePx: number;

  constructor() { }

  get sizePx(): number {
    return this._sizePx;
  }

  private calculate() {
    this.fontSize = (this.defaultProportion[1] * this.sizePx) / this.defaultProportion[0];
    this.size = this.sizePx;
  }

  private rebuildAvatar() {
    this.text = `${ this._firstName.charAt(0).toUpperCase() }${ this._lastName.charAt(0).toUpperCase() }`;
    this.color = this._colours[(this.text.charCodeAt(0) - 65) % 19];
  }
}
