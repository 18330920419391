import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ListComponentGeneric } from '../../../ui/generics';
import { IStockTransfer } from '../core/interfaces/IStockTransfer';
import { OrdersServiceErrorResolverService } from '../../bulk-containers/core/services';
import { NotificationService } from '../../../core/services';
import { SuppliersService } from '../../suppliers/core/services/SuppliersService';
import { StockTransfersService } from '../core/services/StockTransfersService';
import { StockTransferStatusTypes } from '../core/enums';
import { Csv } from '../../../core/utils';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends ListComponentGeneric<IStockTransfer> implements OnInit {

  public readonly statusTypes = StockTransferStatusTypes;
  public showOpenTransfers = false;
  public statusFilters = Object.keys(StockTransferStatusTypes).map(key => {
    return {
      value: key,
      text: StockTransferStatusTypes[key],
      byDefault: false
    };
  });

  protected readonly exportedTableVisibleName = 'Stock transfer list';

  constructor(
    protected _service: StockTransfersService,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    private _supplierService: SuppliersService,
    protected renderer: Renderer2,
    protected _errorResolver: OrdersServiceErrorResolverService,
   ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  public ngOnInit(): void {}

  public cancelTransfer(id: number): void {
    this.loading = true;
    this._service.cancelTransfer(id).subscribe(super.onRefreshList.bind(this), this.stopLoadingAndPushError.bind(this));
  }

  public dispatchTransfer(id: number): void {
    this.loading = true;
    this._service.dispatchTransfer(id).subscribe(super.onRefreshList.bind(this), this.stopLoadingAndPushError.bind(this));
  }

  public bookTransfer(id: number): void {
    this.loading = true;
    this._service.bookTransfer(id).subscribe(super.onRefreshList.bind(this), this.stopLoadingAndPushError.bind(this));
  }

  public showOpenTransfersChange(): void {
    this.statusFilters = this.statusFilters.map(el => {
      el.byDefault = this.showOpenTransfers && (el.value === StockTransferStatusTypes.Open);
      return el;
    });
    this._cdr.detectChanges();
  }

  public filtersChanged(): void {
    this.showOpenTransfers = false;
    this._cdr.detectChanges();
  }

  public onDownloadCSV() {
    const ids = this.data.results.filter(value => value.documentKey && this.setOfCheckedId.has(value.id)).map(value => (value.id));
    Csv.downloadCSV(this.data, new Set(ids), this._service, 'Stock-transfers_');
  }

  private stopLoadingAndPushError(error: any): void {
    this.loading = false;
    this.parseError(error);
    this._cdr.detectChanges();
  }
}
