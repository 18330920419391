<nz-spin [nzSpinning]="loading">
  <nz-row>
    <form nz-form [formGroup]="form" (ngSubmit)="submitForm()" style="width: 100%">
      <nz-col>
        <nz-divider nzText="Bestellabgabe" nzOrientation="left"></nz-divider>
        <nz-form-item>
          <nz-form-label nzFor="supplier" nzRequired>
            <span>Lieferant</span>
          </nz-form-label>
          <nz-form-control [nzSm]="6" [nzXs]="24" nzErrorTip="Feld ist erforderlich!">
          <span [hidden]="!isAllProductsOpen()">
            {{ form['controls'].supplier.value.name }}
          </span>

            <nz-select
              [hidden]="isAllProductsOpen()"
              nzPlaceHolder="Wählen Sie Lieferant"
              nzShowSearch
              nzServerSearch
              formControlName="supplier"
              (nzOnSearch)="onSearchSupplier($event)"
              [compareWith]="compareSimpleList"
              (ngModelChange)="!isEdit ? onSupplierChange() : null"
            >
              <ng-container *ngFor="let o of suppliersList">
                <nz-option *ngIf="!isSuppliersLoading" [nzValue]="o" [nzLabel]="o.value"></nz-option>
              </ng-container>
              <nz-option *ngIf="isSuppliersLoading" nzDisabled nzCustomContent>
                <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
              </nz-option>
            </nz-select>
          </nz-form-control>

          <nz-form-label [nzOffset]="1" nzFor="orderDate" nzRequired>
            <span>Bestelltermin</span>
          </nz-form-label>
          <nz-form-control [nzSm]="6" [nzXs]="24" nzErrorTip="Feld ist erforderlich">
           <span [hidden]="!isAllProductsOpen()">
            {{ form['controls'].supplier.value.name }}
          </span>
            <nz-date-picker
              [hidden]="isAllProductsOpen()"
              [nzFormat]="dateFormat"
              formControlName="orderDate"
            ></nz-date-picker>
          </nz-form-control>

          <ng-container *ngIf="orderType !== orderTypes.MIXED">
            <div style="flex-grow: 1;">
              <div class="d-flex flex-column">
                <div class="d-flex">
                  <nz-form-label style="width: 120px;" [nzOffset]="1" nzFor="orderer" nzRequired>
                    <span>Bearbeiter</span>
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" nzErrorTip="Geben Sie die Bearbeiter!">
                    <nz-select
                      style="max-width: 250px;"
                      nzPlaceHolder="Wählen Sie Bearbeiter"
                      nzShowSearch
                      nzServerSearch
                      formControlName="orderer"
                      (nzOnSearch)="onSearchUsers($event)"
                      [compareWith]="compareSimpleList"
                    >
                      <ng-container *ngFor="let o of usersList">
                        <nz-option *ngIf="!isUsersLoading" [nzValue]="o" [nzLabel]="o.value"></nz-option>
                      </ng-container>
                      <nz-option *ngIf="isUsersLoading" nzDisabled nzCustomContent>
                        <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </div>
              </div>
            </div>
          </ng-container>
        </nz-form-item>
        <nz-form-item *ngIf="orderType !== orderTypes.MIXED">
          
          <div class="d-flex" style="gap: 64px;">
            <div>
              <nz-divider nzText="Liefereingang" nzOrientation="left"></nz-divider>
              <div class="d-flex">
                <nz-form-label nzFor="handler">
                  <span>Bearbeiter</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24">
                  <nz-select
                    style="width: 200px;"
                    nzPlaceHolder="Wählen Sie Bearbeiter"
                    nzShowSearch
                    nzServerSearch
                    formControlName="handler"
                    (nzOnSearch)="onSearchHandlers($event)"
                    [compareWith]="compareSimpleList"
                  >
                    <ng-container *ngFor="let o of handlersList">
                      <nz-option *ngIf="!isHandlersLoading" [nzValue]="o" [nzLabel]="o.value"></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isHandlersLoading" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </div>
            </div>
            <div>
              <nz-divider nzText="Rechnungsbearbeitung" nzOrientation="left"></nz-divider>
              <div class="d-flex" style="gap: 24px;">
                <div class="d-flex">
                  <nz-form-label nzFor="invoiceDate">
                    <span>Datum</span>
                  </nz-form-label>
                  <nz-form-control [nzSm]="24">
                    <nz-date-picker
                      style="width: 200px;"
                      [nzFormat]="dateFormat"
                      formControlName="invoiceDate"
                    ></nz-date-picker>
                  </nz-form-control>
                </div>
                <div class="d-flex">
                  <nz-form-label nzFor="invoiceType">
                    <span>Rechnungstyp</span>
                  </nz-form-label>
                  <nz-form-control [nzSm]="24">
                    <nz-select
                      style="width: 200px;"
                      nzPlaceHolder="Wählen Sie Rechnungstyp"
                      formControlName="invoiceType"
                    >
                      <ng-container *ngFor="let o of invoiceTypes | keyvalue">
                        <nz-option [nzValue]="o.value" [nzLabel]="o.key"></nz-option>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                </div>
                <div class="d-flex">
                  <nz-form-label nzFor="invoiceApprover">
                    <span>Bearbeiter</span>
                  </nz-form-label>
                  <nz-form-control [nzSm]="24">
                    <nz-select
                      style="width: 200px;"
                      nzPlaceHolder="Wählen Sie Bearbeiter"
                      nzShowSearch
                      nzServerSearch
                      formControlName="invoiceApprover"
                      (nzOnSearch)="onSearchInvoiceApprovers($event)"
                      [compareWith]="compareSimpleList"
                    >
                      <ng-container *ngFor="let o of isInvoiceApproversList">
                        <nz-option *ngIf="!isInvoiceApproversLoading" [nzValue]="o" [nzLabel]="o.value"></nz-option>
                      </ng-container>
                      <nz-option *ngIf="isInvoiceApproversLoading" nzDisabled nzCustomContent>
                        <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </div>
              </div>
            </div>
          </div>
        </nz-form-item>

        <nz-form-item *ngIf="orderType === orderTypes.NonPacking">
          <nz-divider></nz-divider>
          <nz-form-label nzFor="supplier">
            <span>Order Document</span>
          </nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <div class="d-flex align-items-center" *ngIf="!form.get('orderDocumentKey').value" style="gap: 8px;">
              <nz-upload
                [nzLimit]="1"
                [nzAction]="toolsService.getUploadDocumentUrl(uploadDocumentTypes.ORDER)"
                [nzHeaders]="{ authorization: 'authorization-text' }"
                nzAccept="application/pdf"
                [nzShowUploadList]="false"
                [nzDisabled]="isFileUploading"
                (nzChange)="handleChange($event)"
              >
                <button nz-button type="button">
                  <i nz-icon nzType="upload"></i>
                  Zum Hochladen klicken
                </button>
              </nz-upload>
              <nz-spin nzSimple nzSize="small" *ngIf="isFileUploading"></nz-spin>
            </div>
            <div class="d-flex align-items-center file-actions" *ngIf="form.get('orderDocumentKey').value">
              {{ form.get('orderDocumentKey').value }}

              <app-download-document
                [documentKey]="form.get('orderDocumentKey').value"
              ></app-download-document>

              <i
                nz-icon
                nzType="delete"
                nzTheme="outline"
                nz-popconfirm
                nzPopconfirmTitle="Sind Sie sicher?"
                (nzOnConfirm)="form.get('orderDocumentKey').patchValue(null)"
              ></i>
            </div>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col>
        <br />
        <br />
        <button nz-button (click)="addProductRow()" nzType="primary" type="button" id="add-product-row">Neuen Artikel hinzufügen</button>
        <br />
        <br />
        <nz-table
          #tableRef
          #editRowTable
          nzBordered
          [nzData]="[{}]"
          [nzShowPagination]="false"
        >
          <thead>
          <tr>
            <th>Number</th>
            <th width="250">Name</th>
            <th width="10%">Lieferdatum</th>
            <th width="10%" *ngIf="orderType !== orderTypes.NonPacking">MHD</th>
            <th *ngIf="orderType !== orderTypes.NonPacking">Charge</th>
            <th>Menge</th>
            <th *ngIf="orderType !== orderTypes.Bagable">Preis netto</th>
            <th>Vom Benutzer überprüft</th>
            <th *ngIf="orderType !== orderTypes.NonPacking">Bio</th>
            <th>Status</th>
            <th>Kommentar</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr
            (click)="startEdit(i)"
            formArrayName="orderProducts"
            *ngFor="let data of form.get('orderProducts')['controls']; let i = index;"
            class="editable-row"
          >
            <ng-container [formGroupName]="i">
              <td>{{ data['controls'].number.value }}</td>
              <td>
                <div style="max-width: 250px;">
                  <div class="editable-cell" [hidden]="editRow === i && data['controls'].isNew.value">
                    {{ data['controls'].name.value }}
                  </div>
                  <nz-select
                    style="width: 100%"
                    nzPlaceHolder="Wählen Sie Artikel"
                    nzShowSearch
                    nzServerSearch
                    nzAllowClear
                    formControlName="product"
                    [hidden]="editRow !== i || !data['controls'].isNew.value"
                    (ngModelChange)="productChange($event); orderType !== orderTypes.NonPacking ? data['controls'].charge.patchValue(null) : null"
                    (nzOnSearch)="onArticleSearch($event)"
                  >
                    <ng-container *ngFor="let o of articlesList">
                      <nz-option *ngIf="!isArticlesLoading" [nzValue]="o.id" [nzLabel]="o.value"></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isArticlesLoading" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i> Daten laden...
                    </nz-option>
                  </nz-select>
                  <div *ngIf="isSubmitted && data['controls'].product.errors" class="invalid-feedback">
                    <div *ngIf="data['controls'].product.errors.required">Feld ist erforderlich</div>
                  </div>
                </div>
              </td>
              <td >
                <div class="editable-cell" [hidden]="editRow === i && data['controls'].status.value === 'Open'" >
                  {{ data['controls'].deliveryDate.value|dateFormat }}
                </div>
                <nz-date-picker
                  [nzFormat]="dateFormat"
                  formControlName="deliveryDate"
                  [hidden]="editRow !== i || data['controls'].status.value !== 'Open'"
                ></nz-date-picker>
                <div *ngIf="isSubmitted && data['controls'].deliveryDate.errors" class="invalid-feedback">
                  <div *ngIf="data['controls'].deliveryDate.errors.required">Feld ist erforderlich</div>
                </div>
              </td>
              <td *ngIf="orderType !== orderTypes.NonPacking">
                <div class="editable-cell" [hidden]="editRow === i && data['controls'].status.value === 'Open'" >
                  {{ data['controls'].mhd.value|dateFormat }}
                </div>
                <nz-date-picker
                  [nzFormat]="dateFormat"
                  formControlName="mhd"
                  [hidden]="editRow !== i || data['controls'].status.value !== 'Open'"
                ></nz-date-picker>
                <div *ngIf="isSubmitted && data['controls'].mhd.errors" class="invalid-feedback">
                  <div *ngIf="data['controls'].mhd.errors.required">Feld ist erforderlich</div>
                </div>
              </td>
              <td *ngIf="orderType !== orderTypes.NonPacking">
                <div class="editable-cell" [hidden]="editRow === i && data['controls'].status.value === 'Open'" >
                  {{ data['controls'].charge.value }}
                </div>
                <input
                  [hidden]="editRow !== i || data['controls'].status.value !== 'Open'"
                  formControlName="charge"
                  appChargeInput
                  [initialValue]="editObject?.orderProducts[i]?.charge"
                  [articleId]="data['controls'].articleId.value"
                  [articleVariantId]="data['controls'].articleVariantId.value"
                  type="text" nz-input  />
                <div *ngIf="isSubmitted && data['controls'].charge.errors" class="invalid-feedback">
                  <div *ngIf="data['controls'].charge.errors.required">Feld ist erforderlich</div>
                </div>
              </td>
              <td >
                <div class="d-flex align-items-center">
                  <div class="editable-cell" [hidden]="editRow === i && data['controls'].status.value === 'Open'" >
                    {{ data['controls'].quantity.value }}
                  </div>
                  <input
                    [hidden]="editRow !== i || data['controls'].status.value !== 'Open'"
                    formControlName="quantity"
                    appPossitiveNumbers
                    type="number" nz-input />&nbsp;{{ data['controls'].isBagable.value !== null ? (data['controls'].isBagable.value ? 'kg' : 'Stück') : '' }}
                </div>
                <div *ngIf="isSubmitted && data['controls'].quantity.errors" class="invalid-feedback">
                  <div *ngIf="data['controls'].quantity.errors.required">Feld ist erforderlich</div>
                </div>
              </td>
              <td *ngIf="orderType !== orderTypes.Bagable">
                <div class="editable-cell" [hidden]="editRow === i && data['controls'].status.value == 'Open'" >
                  {{ data['controls'].price.value }}
                </div>
                <input
                  [hidden]="editRow !== i || data['controls'].status.value !== 'Open'"
                  formControlName="price"
                  min="0.00"
                  step="0.01"
                  appPossitiveNumbers
                  type="number" nz-input />
                <div *ngIf="isSubmitted && data['controls'].price.errors" class="invalid-feedback">
                  <div *ngIf="data['controls'].price.errors.required">Feld ist erforderlich</div>
                </div>
              </td>

              <td>
                <div class="editable-cell" [hidden]="editRow === i && data['controls'].status.value === 'Open'" >
                  {{ data['controls'].checked.value|booleanFormat }}
                </div>
                <label
                  class="verify-checkbox"
                  [hidden]="editRow !== i || data['controls'].status.value !== 'Open'"
                  nz-checkbox formControlName="checked">Ja/Nein</label>

              </td>
              <td *ngIf="orderType !== orderTypes.NonPacking">{{data['controls'].isBio.value | booleanFormat}}</td>
              <td>{{data['controls'].status.value | statusFormat}}</td>
              <td>
                <div class="editable-cell" [hidden]="editRow === i" >
                  {{data['controls'].comment.value}}
                </div>
                <input
                  [hidden]="editRow !== i"
                  formControlName="comment"
                  type="text" nz-input   />
              </td>
              <td >
                <div class="actions">
                  <ng-container *ngIf="data['controls'].id.value !== -1 && data['controls'].status.value === 'Open'">
                  <span>
                    <i
                      nz-icon nzType="check-circle"
                      nz-popconfirm nzPopconfirmTitle="Sind Sie sicher?"
                      (nzOnConfirm)="bookRow(i)"
                      [nzTheme]="'twotone'"
                      [nzTwotoneColor]="'#52c41a'"
                    ></i>
                  </span>
                  </ng-container>
                  <span>
                  <i nz-icon nzType="copy" (click)="copyRow(i); $event.stopPropagation()"  nzTheme="twotone"
                     [nzTwotoneColor]="'#ffaa04'"></i>
                </span>
                  <ng-container *ngIf="data['controls'].id.value !== -1 && data['controls'].status.value === 'Open'">
                <span>
                  <i nz-icon nzType="stop"
                     nz-popconfirm
                     nzPopconfirmTitle="Sind Sie sicher?" (nzOnConfirm)="cancelRow(i)"
                     nzTheme="twotone"
                     [nzTwotoneColor]="'#eb2f96'"></i>
                </span>
                  </ng-container>

                  <ng-container *ngIf="data['controls'].id.value == -1">
                  <span>
                    <i nz-icon nzType="delete"
                       nz-popconfirm
                       nzPopconfirmTitle="Sind Sie sicher?" (nzOnConfirm)="deleteRow(i)"
                       nzTheme="twotone"
                       [nzTwotoneColor]="'#eb2f96'"></i>
                  </span>
                  </ng-container>
                </div>
              </td>
            </ng-container>
          </tr>
          </tbody>
        </nz-table>
      </nz-col>
    </form>
  </nz-row>
</nz-spin>

<nz-modal
  [nzMaskClosable]="false"
  [(nzVisible)]="isAdditionalProductsModalVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="isAdditionalProductsModalVisible = false"
  [nzWidth]="600"
>
  <ng-template #modalTitle>
    Empfohlene Zusatzprodukte
  </ng-template>

  <ng-template #modalContent>
    <nz-table
      #basicTable
      [nzData]="additionalProducts"
      [nzShowPagination]="false"
      [nzFrontPagination]="false"
    >
      <thead>
      <tr>
        <th>Name</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let val of basicTable.data">
        <td>{{ val.value }}</td>
        <td style="text-align: right;">
          <button nz-button nzType="primary" (click)="addSuggestedArticleToOrder(val)">
            Zur Bestellung hinzufügen
          </button>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="isAdditionalProductsModalVisible = false">Schließen</button>
  </ng-template>
</nz-modal>
