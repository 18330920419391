import { ChangeDetectorRef, ElementRef } from '@angular/core';

export default class Fields {



  static SetAsWrong(field: ElementRef<HTMLInputElement>,  backColorAction: () => void) {
    this.PlayWrongScanSound();
    this.setFieldColor(field, 'var(--nz-error-color)', backColorAction);
  }

  static SetAsCorrect(field: ElementRef<HTMLInputElement>,  backColorAction: () => void) {
    this.setFieldColor(field, 'var(--nz-light-primary-color)', backColorAction);
  }

  static PlayWrongScanSound() {
    var audio = new Audio('assets/sounds/beep.mp3');
    audio.play();
  }

  private static setFieldColor(field: ElementRef<HTMLInputElement>,
                               color: string,
                               backColorAction: () => void) {
    field.nativeElement.style.backgroundColor = color;
    field.nativeElement.style.color = 'white';
    setTimeout(() => {
      field.nativeElement.style.backgroundColor = 'white';
      backColorAction()
    }, 1000)
  }

}



