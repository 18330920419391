import { Pipe, PipeTransform } from '@angular/core';
import { IStock } from '../interfaces/IStock';

@Pipe({
  name: 'stockByStore'
})
export class StockByStorePipe implements PipeTransform {

  public transform(stocks: IStock[], store: string): number {
    return stocks?.find(stock => stock.stockName === store)?.inStock || 0;
  }
}
