import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef, Renderer2
} from '@angular/core';
import { ListComponentGeneric } from '../../../../../ui/generics';
import { NotificationService } from '../../../../../core/services';
import {
  BulkContainersService,
  BulkContainersServiceErrorResolverService,
} from '../../../core/services';
import { IBulkContainerChangeLog } from '../../../core/interfaces/IBulkContainerChangeLog';
import { IBulkContainer } from '../../../core/interfaces/IBulkContainer';
import { ITableResults } from '../../../../../core/interfaces';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-log-list',
  templateUrl: './change-log-list.component.html',
  styleUrls: ['./change-log-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeLogListComponent extends ListComponentGeneric<IBulkContainer> implements OnInit {
  private _articleId: number;

  // @ts-ignore
  public data: ITableResults<IBulkContainerChangeLog> = {
    results: [],
    info: {
      page: 1,
      results: 0,
      totalResults: 0,
      exportTableName: null
    }
  };

  @Input() set articleId(val: number) {
    if(val) {
      this._articleId = val
      this.loadDefault();
    }
  }

  protected readonly exportedTableVisibleName = 'Change log list';

  constructor(
    protected _service: BulkContainersService,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BulkContainersServiceErrorResolverService
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  ngOnInit() {}

  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[] = [],
  ): void {
    this.lastLoadParams = {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      search: null,
      additionalFilters
    };
    filter = [...filter, ...this.predefindedFilters];
    this.loading = true;
    this._service.getQueryableBulkContainerChangeLogByArticleId(
      this._articleId,
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter
    ).pipe(
      finalize(() => {
        this.loading = false;
        this._cdr.detectChanges();
      })
    ).subscribe(res => this.data = res, this.parseError);
  }
}
