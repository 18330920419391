<app-table-header
  (reload)="onRefreshList()"
  [loading]="loading"
  [withExportOption]="false"
>
  <div class="d-flex justify-content-start flex-column" style="flex:1;">
    <div class="d-flex justify-content-start " style=" margin-bottom:20px">
      <div  *ngIf="!onlyClosed" style="margin-right: 10px">
        <button nz-button nzType="secondary" (click)="openGenerateDeliverySlipsModal(generateDeliverySlipsModalComponent)">
          <i nz-icon nzType="inbox" nzTheme="outline"></i><span>Versandetiketten erstellen</span>
        </button>
      </div>
      <div style="margin-right: 10px" *ngIf="!onlyClosed">
        <button nz-button nzType="secondary" (click)="generateRequiredDocuments()" [disabled]="allDocumentsReady || documentsAreProcessing">
          <i nz-icon nzType="file-done" nzTheme="outline"></i><span>Lieferscheine und Rechnungen erstellen</span>
        </button>
      </div>

      <div *ngIf="!onlyClosed">
        <button nz-button nzType="secondary" (click)="generateBatchDocument()" [disabled]="!allDocumentsReady || documentsAreProcessing">
        <i nz-icon nzType="printer" nzTheme="outline"></i><span>Lieferscheine und Rechnungen drucken</span>
        </button>
      </div>
    </div>

    <div style="margin-right: auto " class="d-flex justify-content-end full-width">
      <label nz-checkbox [(ngModel)]="showOnlyWithComments" (ngModelChange)="onRefreshList()">Nur mit Kommentar anzeigen</label>
      <nz-select [(ngModel)]="orderStatus" style="width: 200px" (ngModelChange)="onRefreshList()" *ngIf="!onlyClosed">
        <nz-option [nzValue]="0" nzLabel="Offen"></nz-option>
        <nz-option [nzValue]="1" nzLabel="In Bearbeitung"></nz-option>
        <nz-option [nzValue]="46" nzLabel="Position unvollständig"></nz-option>
      </nz-select>
    </div>

  </div>
</app-table-header>

<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data"
  [nzLoading]="loading"
  [nzFrontPagination]="false"
  id="openorders-list-table"
>
  <thead>
  <tr>
    <th
      [(nzChecked)]="checked"
      [nzIndeterminate]="indeterminate"
      (nzCheckedChange)="onAllChecked($event)"
    ></th>
    <th nzColumnKey="id" nzWidth="100px">#ID</th>
    <th nzColumnKey="fullDate">Bestelldatum</th>
    <th nzColumnKey="number" >Bestellnummer</th>
    <th nzColumnKey="customer">Kunde</th>
    <th nzColumnKey="status">Aktueller Bestellstatus</th>
    <th nzColumnKey="trackingCode">Versandetiketten generiert</th>
    <th nzColumnKey="isInvoiceRequired">Rechnung erforderlich</th>
    <th nzColumnKey="isInvoiceGenerated">Rechnung erstellt</th>
    <th nzColumnKey="isDeliveryNoteGenerated">Lieferschein generiert</th>

    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let order of data">
    <td [nzChecked]="setOfCheckedId.has(order.id)" (nzCheckedChange)="onItemChecked(order.id, $event)"></td>
    <td>{{order.id}}</td>
    <td>{{order.fullDate|dateFormat}}</td>
    <td>{{order.number}}  <app-order-comments [order]="order"></app-order-comments></td>
    <td>{{order.customer}}</td>
    <td>{{order.status}}</td>
    <td nzAlign="center">
      <app-boolean-icon [state]="order.trackingCode !== ''"
                        nz-tooltip [nzTooltipTitle]="order.deliveryMethodName"></app-boolean-icon>
    </td>
    <td nzAlign="center"> <app-boolean-icon [state]="order.isInvoiceRequired"></app-boolean-icon></td>
    <td nzAlign="center"> <app-boolean-icon [state]="order.isInvoiceGenerated"></app-boolean-icon></td>
    <td nzAlign="center"> <app-boolean-icon [state]="order.isDeliveryNoteGenerated"></app-boolean-icon></td>
    <td>
      <div class="actions" *ngIf="order.documentKey">
        <app-download-document [documentKey]="order.documentKey"></app-download-document>
        <i nz-icon nzType="sync" nzTheme="outline" (click)="regenerateDocument(order.id)"></i>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>
<ng-template #progressBar>
  <nz-progress [nzPercent]="percentDone"></nz-progress>
</ng-template>
<ng-template #modalFooter>
  <button nz-button nzType="primary">Speichern</button>
</ng-template>

<app-document-pdf-safari-modal [pdfLink]="pdfLink">

</app-document-pdf-safari-modal>

<app-generate-delivery-slips-modal (generated)="onRefreshList()"  #generateDeliverySlipsModalComponent>

</app-generate-delivery-slips-modal>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
