export enum StockTransferStatusTypes {
  Open = 'Open',
  Dispatched = 'Dispatched',
  Booked = 'Booked',
  Canceled = 'Canceled'
}

export enum StockTransferStatusTranslationsTypes {
  Open = 'Offen',
  Dispatched = 'Versandt',
  Booked = 'Gebucht',
  Canceled = 'Abgesagt'
}

export enum StockTransferWindows {
  OUTGOING,
  INCOMING,
}

