import { Injectable } from '@angular/core';
import { GenericService } from '../../../../core/services/GenericService';
import { ApiService } from '../../../../core/services';
import { Observable } from 'rxjs';
import { ITableResults } from '../../../../core/interfaces';
import { ToolsService } from '../../../../core/services/ToolsService';
import { HttpUtils } from '../../../../core/utils/HttpUtils';

@Injectable({
  providedIn: 'root'
})
export class GeneratedExportsService<T> extends GenericService<T> {
  protected readonly baseUrl = '/tools';

  constructor(
    public apiService: ApiService,
    private readonly _toolsService: ToolsService
  ) {
    super(apiService);
  }

  public getGeneratedDocuments(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    search: Array<{ key: string; value: string[] }>,
    additionalFilters: Array<{ key: string; value: string[] }>,
  ):  Observable<ITableResults<T>> {
    const params = HttpUtils.prepareQueryableParams(pageIndex, pageSize, sortField, sortOrder, filters, search, additionalFilters);
    return this.apiService.get(`${ this.baseUrl }/generated-table-exports`, params );
  }

  getQueryableAll(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null, filters: Array<{ key: string; value: string[] }>, search: Array<{ key: string; value: string[] }>): Observable<ITableResults<T>> {
    throw new Error('Method not implemented.');
  }

  getById(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }

  delete(id: string): Observable<T> {
    throw new Error('Method not implemented.');
  }

  update(object: T, id: number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  create(object: T): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
