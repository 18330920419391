import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { INotification, NotificationTypes } from '../interfaces';
import { NotifyFactory } from '../factories';
import { NzNotificationService } from 'ng-zorro-antd/notification';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationStream: Subject<INotification> = new Subject<INotification>();

  constructor(
              private notifyFactory: NotifyFactory,
              private externalNotify: NzNotificationService) {
    this.notificationStream.subscribe((notify: INotification) => {
      switch (notify.type) {
        case NotificationTypes.SUCCESS:
          return this.externalNotify.success(notify.title, notify.body);
        case NotificationTypes.ERROR:
          return this.externalNotify.error(notify.title, notify.body);
        case NotificationTypes.INFO:
          return this.externalNotify.info(notify.title, notify.body);
        case NotificationTypes.WARNING:
          return this.externalNotify.warning(notify.title, notify.body);
      }
    });
  }

  pushSuccess(title='Erfolgreich', body: string) {
    this.propagateNewNotify(this.createNotifyObject(
      'success',
      title,
      body,
    ));
  }

  pushError(title = 'Fehlgeschlagen', body: string) {
    this.propagateNewNotify(this.createNotifyObject(
      'error',
      title,
      body,
    ));
  }

  pushWarning(title = 'Achtung', body: string) {
    this.propagateNewNotify(this.createNotifyObject(
      'warning',
      title,
      body,
    ));
  }

  pushInfo(title = "Info", body: string) {
    this.propagateNewNotify(this.createNotifyObject(
      'info',
      title,
      body,
    ));
  }

  private createNotifyObject(type: string, title: string, body: string): INotification {
    return this.notifyFactory.createNotify(type, title, body);
  }

  private propagateNewNotify(notify: INotification) {
    this.notificationStream.next(notify);
  }

}
