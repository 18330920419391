import { Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'datetimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  public override transform(value: any, args?: any): any {
    return super.transform(value, `${environment.defaultDateFormat} ${environment.defaultTimeFormat}`);
  }
}
