import { Injectable } from '@angular/core';
import { GenericService } from '../../../core/services/GenericService';
import { ApiService } from '../../../core/services';
import { IArticle, IArticleVariant, IPrice } from '../../../core/interfaces';
import { Observable } from 'rxjs';
import { ISimpleListItem } from '../../../core/interfaces/ISimpleListItem';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService<T = IArticle> extends GenericService<T> {
  protected readonly baseUrl = '/articles';

  constructor(public apiService: ApiService) {
    super(apiService);
  }

  getSimpleList( search = '', onlyBagable= false): Observable<ISimpleListItem[]> {
    const params = new HttpParams()
      .append('search', `${search}`)
      .append('onlyBagable', `${onlyBagable}`);
    return this.apiService.get(`${this.baseUrl}/simple`, params);
  }

  getArticlesVariants( search = '', onlyBagable = false, onlyRecipeCards = false): Observable<ISimpleListItem[]> {
    const params = new HttpParams()
      .append('search', `${search}`)
      .append('onlyBagable', `${onlyBagable}`)
      .append('onlyRecipeCards', `${onlyRecipeCards}`);
    return this.apiService.get(`${this.baseUrl}/variants/simple`, params);
  }

  getArticleByEan(ean: string) {
    return this.apiService.get(`${this.baseUrl}/variants/ean/${ean}`);
  }

  public getVariantsByArticleId(articleId: number): Observable<IArticleVariant[]> {
    return this.apiService.get(`${this.baseUrl}/${ articleId }/variants`);
  }

  public getVariantById(variantId: number): Observable<IArticleVariant> {
    return this.apiService.get(`${this.baseUrl}/variants/${ variantId }`);
  }

  public updateArticleVariant(variant: IArticleVariant, variantId: number): Observable<any> {
    return this.apiService.put(`${this.baseUrl}/variants/${ variantId }`, variant);
  }

  public createArticleVariant(variant: IArticleVariant): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/variants`, variant);
  }

  public setDefaultVariant(variantId: number): Observable<any> {
    return this.apiService.post(`${this.baseUrl}/variants/${ variantId }/set-default`);
  }

  public removeArticleVariant(variantId: number): Observable<any> {
    return this.apiService.delete(`${this.baseUrl}/variants/${ variantId }`);
  }

  public setVariantPrice(variantId: number, price: IPrice): Observable<void> {
    return this.apiService.post(`${ this.baseUrl }/variants/${ variantId }/set-price`, price);
  }
  public updateArticleMinStock(articleId: number, variantId: number, minStock: number): Observable<void> {
    return this.apiService.post(`${this.baseUrl}/min-stock`, { articleId, articleVariantId: variantId, minStock });
  }

  public getArticlesCategories(search = '', results = 10): Observable<ISimpleListItem[]> {
    const params = new HttpParams().append('search', search).append('results', results.toString());

    return this.apiService.get(`${ this.baseUrl }/get-articles-categories`, params);
  }

}
