<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
>
  <app-table-search-bar (search)="search($event)" [searchValue]="currentSearch"></app-table-search-bar>
</app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzScroll]="{ x: tableMaxWidth }"
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
  appKeepState
  id="stores-list-table"
>
  <thead>
  <tr>
    <th nzColumnKey="id" [nzSortFn]="true" nzWidth="75px">#ID</th>
    <th nzColumnKey="name" [nzSortFn]="true">Name</th>
    <th nzColumnKey="address" [nzSortFn]="true">Adresse</th>
    <th nzColumnKey="isMainStore" [nzSortFn]="true">Haupt-Lagerhaus</th>
    <th nzColumnKey="isActive" [nzSortFn]="true">Aktiv</th>
    <th nzRight nzWidth="70px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let store of data.results">
    <td>{{store.id}}</td>
    <td>{{store.name}}</td>
    <td>{{store.address}}</td>
    <td>{{store.isMainStore | booleanFormat}}</td>
    <td>{{store.isActive | booleanFormat}}</td>
    <td nzRight>
      <div class="actions">
        <app-add-edit-modal (refresh)="onRefreshList(false)" #modal>
        <span trigger>
            <i nz-icon nzType="edit"  nzTheme="outline" (click)="modal.showModal(store)"></i>
        </span>
        </app-add-edit-modal>
        <span>
      </span>
      </div>
    </td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
