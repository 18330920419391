import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef, OnDestroy, ViewContainerRef, ViewChild, ElementRef, TemplateRef, Output, EventEmitter
} from '@angular/core';

import { IGlobalModal,  } from '../../../core/directives';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OpenOrdersService } from '../core/services/OpenOrdersService';
import { NotificationService } from '../../../core/services';
import { OpenOrdersServiceErrorResolverService } from '../core/services/error-resolvers/open-orders-service-error-resolver.service';
import { IBatchDocumentUrl, IGenerateDeliverySlips, IGenerateOrderOperation, IOpenOrder } from '../core/interfaces';
import { ModalGeneric } from '../../../ui/generics';
import { Guid } from 'guid-typescript';
import { ISignalRResponse } from '../../../core/interfaces/ISignalRResponse';
import { DeliveryDocumentsWebSocketService } from '../core/services/DeliveryDocumentsWebSocketService';
import { IDeliverySlipGenerated } from '../core/interfaces/IDeliverySlipGenerated';

@Component({
  selector: 'app-generate-delivery-slips-modal',
  templateUrl: './generate-delivery-slips-modal.component.html',
  styleUrls: ['./generate-delivery-slips-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateDeliverySlipsModalComponent extends ModalGeneric implements OnInit, OnDestroy, IGlobalModal {

  public orders: IOpenOrder[];
  @Output() generated = new EventEmitter<any>();
  public loading = false;

  public title = 'Versandetiketten erstellen';
  private _lastActionId: string;
  private _numberOfDoneOperations = 0;
  private _numberOfAllOperations = 0;
  constructor(
    protected cdr: ChangeDetectorRef,
    private _modal: NzModalService,
    private _viewContainerRef: ViewContainerRef,
    private _service: OpenOrdersService,
    private _notifyService: NotificationService,
    private _errorResolver: OpenOrdersServiceErrorResolverService,
    private _wsService: DeliveryDocumentsWebSocketService,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.initWs();
  }

  public generate(): void {
    this.loading = true;
    this._lastActionId =  Guid.create().toString();
    this.orders = this.orders.map((order) => ({...order, deliverySlipStatus: 0}));
    const ordersToGenerate = this.orders.filter(value => {
      if (value.deliveryMethod !== null) {
        return true;
      }
      value.deliverySlipStatus = 2;
      value.deliverySlipError = 'Nicht unterstützte Versandform';
      this._service.setUncompletedOrder(value.id.toString(), value.deliverySlipError).subscribe()
      return false;
    }).map(value => ({
      operationId: this._lastActionId,
      orderId: value.id,
      deliveryMethod: value.deliveryMethod,
      shopProviderKey: value.shopProviderKey
    } as IGenerateDeliverySlips));

    this.cdr.detectChanges();
    this._numberOfAllOperations = ordersToGenerate.length;
    this._numberOfDoneOperations = 0;
    this._service.generateDeliverySlips(ordersToGenerate).subscribe(value => {
    }, this.parseError);
  }

  private initWs(): void {
    this._wsService.onWaitForDeliverySlips((response: ISignalRResponse<IDeliverySlipGenerated>) => {

      if (response.operationId === this._lastActionId) {
        this._numberOfDoneOperations++;
        const curEl = this.orders.find(value => value.id === parseInt(response.response.orderId, 10));
        if (response.hasError || !response.response.creationSuccess ) {
          if  (curEl) {
            curEl.trackingCode = '';
            curEl.deliverySlipStatus = 2;
            curEl.deliverySlipError = response.errorMessage;
          }
        }
        if (response.response?.creationSuccess) {
          if (curEl) {
            curEl.deliverySlipStatus = 1;
            curEl.trackingCode = response.response.trackingCode;
          }
        }
        if (this._numberOfDoneOperations === this._numberOfAllOperations) {
          this._notifyService.pushSuccess('Fertig!', 'Erforderliche Dokumente erzeugt');
          this.loading = false;
          this.generated.emit();
        }
        this.cdr.detectChanges();
      }
    });
  }

  // may we use it to show it for request?
  private printTrackingSlip(base64) {
    // Files.openPdfByBase64(this.deliveryNote.deliverySlip, link => {
    //   this.pdfLink = link;
    //   this.cdr.detectChanges();
    // });
  }

  // private loadDeliveryNote = () => {
  //   this.loading = true;
  //   this.cdr.detectChanges();
  //   this._service.getDeliveryNoteByOrderNumber(this._orderNumber).pipe(
  //     map(value => {
  //       return new DeliveryNote({
  //         ...value,
  //         articles: value.articles.filter(art => (art.orderNumber.toLowerCase() !== 'Spende'.toLocaleLowerCase())
  //       ).map(article => new DeliveryNoteArticle(article))
  //       });
  //     })
  //   ).subscribe((val: DeliveryNote) => {
  //     this.deliveryNote = val;
  //     this.uncompletedReason = val.internalComment;
  //     this.loading = false;
  //     this.eanField.nativeElement.focus();
  //     this.cdr.detectChanges();
  //   }, (data) => {
  //     Fields.PlayWrongScanSound();
  //     if (!this.isManualInput) {
  //       this._orderNumber = '';
  //       this.cdr.detectChanges();
  //     }
  //     this.parseError(data);
  //   });
  // }

  handleCancel() {
    super.handleCancel();
  }

  private parseError = data => {
    this.loading = false;
    this._notifyService.pushError('Fehlgeschlagen', this._errorResolver.getMessage((data && data.InternalCode) ? data.InternalCode : ''));
  }
}
