import { Pipe, PipeTransform} from '@angular/core';
import {
  BulkContainerStatuses,
  DeliveryOrderStatuses,
  ProductionOrderStatuses
} from '../enums';
import { StockTransferStatusTranslationsTypes } from '../../pages/stock-transfer/core/enums';

@Pipe({
  name: 'statusFormat'
})
export class StatusFormatPipe implements PipeTransform {

  private _statusesList = {
    ...DeliveryOrderStatuses,
    ...BulkContainerStatuses,
    ...ProductionOrderStatuses,
    ...StockTransferStatusTranslationsTypes
  };

  transform(value: string): any {
    return this._statusesList[value];
  }
}
