import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IExport } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  private _operationIds: string[] = [];
  private _exportGenerated$ = new BehaviorSubject<IExport>(null);

  public get operationIds(): string[] {
    return this._operationIds;
  }

  public set operationId(id: string) {
    this._operationIds.push(id);
  }

  public get exportGenerated$(): Observable<IExport> {
    return this._exportGenerated$.asObservable();
  }

  public set exportGenerated(val: IExport) {
    this._exportGenerated$.next(val);
  }

  public operationIdExists(id: string): boolean {
    return this._operationIds.includes(id);
  }

  public removeOperationId(id: string): void {
    this._operationIds = this._operationIds.filter(operationId => operationId !== id);
  }

  public clearOperationIds(): void {
    this._operationIds = [];
  }
}
