import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ListComponent } from '../list/list.component';

@Component({
  selector: 'app-stock-transfer',
  templateUrl: './stock-transfer.component.html',
  styleUrls: ['./stock-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockTransferComponent implements OnInit {

  @ViewChild('listComponent') listComponent: ListComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
