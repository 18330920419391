import { StaticDependenciesService } from '../services';
import { FileTypes } from '../enums/FileTypes';

export default class Files {

  static openByUrl(url: string, safariBehaviour: (link) => void, isPdf = false) {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf( 'safari') !== -1) {
        if (userAgent.indexOf('chrome') > -1) {
          this.openInBlankWindow(url, isPdf);
        } else {
          safariBehaviour(url);
        }
      } else {
        this.openInBlankWindow(url, isPdf);
      }
  }

  static openPdfByBase64(base64: string, safariBehaviour: (link) => void) {
    const url = this.pdfUrlFromBase64(base64);
    Files.openByUrl(url, safariBehaviour, true);
  }

  public static downloadCSV(fileName: string, content: Blob): void {
    Files.downloadFile(fileName, FileTypes.CSV, content);
  }

  public static downloadPDF(fileName: string, content: Blob): void {
    Files.downloadFile(fileName, FileTypes.PDF, content);
  }

  private static downloadFile(fileName: string, fileType: FileTypes, content: Blob): void {
    const hiddenElement = StaticDependenciesService.renderer.createElement('a');

    hiddenElement.href = window.URL.createObjectURL(content);
    hiddenElement.download = `${ fileName.replaceAll(' ', '-') }.${ fileType }`;
    hiddenElement.click();
  }

  private static pdfUrlFromBase64(base64:string) : string {
    return "data:application/pdf;base64," + base64;
  }

  private static openInBlankWindow(url, isPdf) {
    const win = window.open("","_blank");
    if (win) {
      let html = '';
      html += '<html><body style="margin:0!important">';
      html += `<embed width="100%" height="100%" src="${url}" type="${(isPdf?'application/pdf':'')}" />`;
      html += '</body></html>';
      setTimeout(() => {
        win.document.write(html);
      }, 0);
    }
  }
}



