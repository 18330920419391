import { Injectable } from '@angular/core';
import { SignalRService } from '../../../../core/services/SingalRService';
import { JwtService } from '../../../../core/services/auth';
import { ISignalRResponse } from '../../../../core/interfaces/ISignalRResponse';
import { IOpenOrder } from '../interfaces/IOpenOrder';
import { IBatchDocumentUrl } from '../interfaces/IBatchDocumentUrl';
import { IDeliverySlipGenerated } from '../interfaces/IDeliverySlipGenerated';

@Injectable({
  providedIn: 'root'
})
export class DeliveryDocumentsWebSocketService extends SignalRService {
  private readonly _hubUrl = '/documents';

  constructor(
    protected readonly jwtService: JwtService
  ) {
    super(jwtService);
    super.buildConnection(this._hubUrl);
  }

  public onWaitForGeneratedDocuments(method: (payload: ISignalRResponse<IOpenOrder>) => void): void {
    this.createMethod('shop-order-documents-generated', method);
  }
  public onWaitForDeliverySlips(method: (payload: ISignalRResponse<IDeliverySlipGenerated>) => void): void {
    this.createMethod('shop-order-delivery-slip-generated', method);
  }

  public onWaitForBatchDocument(method: (payload: ISignalRResponse<IBatchDocumentUrl>) => void): void {
    this.createMethod('shop-order-batch-document-generated', method);
  }
}
