import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'routerLink'
})
export class RouterLinkPipe implements PipeTransform {

  public transform(path: string): string {
    return path?.split('?')[0];
  }
}
