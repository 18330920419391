import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private _localStorageItemKey = 'states';

  public setLocalStorageItemKey(userID?: string) {
    const workstationMode = window.localStorage.getItem('workstationMode') === 'true';
    if (workstationMode && userID) {
      this._localStorageItemKey = `states_${userID}`;
    } else {
      this._localStorageItemKey = 'states';
    }
  }

  public set item(value: { key: string; value: string; }) {
    const allItems = this.allItems;
    const fItem = allItems.find(item => item.key === value.key);
    fItem ? fItem.value = value.value : allItems.push(value);
    localStorage.setItem(this._localStorageItemKey, JSON.stringify(allItems));
  }

  public get allItems(): { key: string; value: string; }[] {
    return JSON.parse(localStorage.getItem(this._localStorageItemKey)) || [];
  }

  public getValue(key: string): string {
    const fItem = this.allItems.find(item => item.key === key);
    return fItem ? fItem.value : '';
  }

  public removeItem(key: string): void {
    localStorage.setItem(this._localStorageItemKey, JSON.stringify(this.allItems.filter(item => item.key !== key)));
  }

  public clearLocalStorage(): void {
    localStorage.removeItem(this._localStorageItemKey);
  }
}
