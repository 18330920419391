<app-table-header
  (reload)="onRefreshList(false)"
  [loading]="loading"
  [withExportOption]="!!data.info.exportTableName"
  (exportBtnClicked)="exportBtnClicked()"
></app-table-header>
<nz-table
  #tableRef
  nzShowSizeChanger
  [nzData]="data.results"
  [nzLoading]="loading"
  [nzTotal]="data.info.totalResults"
  [nzFrontPagination]="false"
  [nzPageSize]="pageSize"
  [nzPageIndex]="data.info.page"
  (nzQueryParams)="onQueryParamsChange($event)"
>
  <thead>
  <tr>
    <th nzColumnKey="id" [nzSortFn]="true" nzSortOrder="descend">#ID</th>
    <th nzColumnKey="createdAt" [nzSortFn]="true">Datum</th>
    <th nzColumnKey="new.quantity">Menge</th>
    <th nzColumnKey="bulkContainer.charge" [nzSortFn]="true">Charge</th>
    <th nzColumnKey="comment" [nzSortFn]="true">Kommentar</th>
    <th nzColumnKey="user.fullName" [nzSortFn]="true">Ersteller</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let log of data.results">
    <td>#{{log.id}}</td>
    <td>{{log.createdAt|dateFormat}}</td>
    <td>{{log.difference | productionUnit:true}}</td>
    <td>{{log.bulkContainer.charge}}</td>
    <td>{{log.comment}}</td>
    <td>{{log.user?.fullName}}</td>
  </tr>
  </tbody>
</nz-table>

<app-export-table-modal #exportTableModalComponent></app-export-table-modal>
