import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef, Renderer2
} from '@angular/core';
import { ListComponentGeneric } from '../../../../../ui/generics';
import { NotificationService } from '../../../../../core/services';
import {
  BulkContainersServiceErrorResolverService,
} from '../../../core/services';
import { IBulkContainer } from '../../../core/interfaces/IBulkContainer';
import { ITableResults } from '../../../../../core/interfaces';
import { INonPackChangeLog } from '../../../core/interfaces/INonPackChangeLog';
import { NonPackableService } from '../../../core/services/NonPackableService';

@Component({
  selector: 'app-nonpack-change-log-list',
  templateUrl: '../../../bulk-containers/single-bulk-container/change-log-list/change-log-list.component.html',
  styleUrls: ['../../../bulk-containers/single-bulk-container/change-log-list/change-log-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NonPackChangeLogListComponent extends ListComponentGeneric<IBulkContainer> implements OnInit {
  private _articleId:number;

  // @ts-ignore
  public data: ITableResults<INonPackChangeLog> = {
    results: [],
    info: {
      page: 1,
      results: 0,
      totalResults: 0,
      exportTableName: null
    }
  };

  protected readonly exportedTableVisibleName = 'Non packing change log list';

  @Input() set articleId(val: number) {
    if(val) {
      this._articleId = val
      this.loadDefault();
    }
  }

  constructor(
    protected _service: NonPackableService,
    protected _cdr: ChangeDetectorRef,
    protected _notifyService: NotificationService,
    protected renderer: Renderer2,
    protected _errorResolver: BulkContainersServiceErrorResolverService
  ) {
    super(_service, _cdr, _notifyService, renderer, _errorResolver);
  }

  ngOnInit() {}

  load(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filter: { key: string; value: string[] }[],
    search: { key: string; value: string[] }[],
    additionalFilters: { key: string; value: string[] }[] = [],
  ): void {
    this.lastLoadParams = {
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filter,
      search: null,
      additionalFilters
    };
    filter = [...filter, ...this.predefindedFilters];
    this.loading = true;
    this._service.getQueryableNonPackableChangeLogByArticleId(
      this._articleId,
      pageIndex, pageSize, sortField, sortOrder, filter).pipe(
    ).subscribe(res => {
      this.loading = false;
      this.data = res;
      this._cdr.detectChanges();
    }, this.parseError);
  }
}
