import { Injectable } from '@angular/core';
import { BaseServiceErrorResolverService } from '../../../../../core/services/error-resolvers/base-service-error-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class BulkContainersServiceErrorResolverService extends BaseServiceErrorResolverService {
 codes = {
   BulkContainerHasOpenProductionOrders: 'Lieferung hat einen oder mehrere Produktionsaufträge offen'
 };
}
