import { Pipe, PipeTransform } from '@angular/core';
import { IStock } from '../interfaces/IStock';

@Pipe({
  name: 'mainStoreNameByStocks'
})
export class MainStoreNameByStocksPipe implements PipeTransform {

  public transform(val: IStock[]): IStock {
    return val?.find(x => +x.stockId === 1);
  }
}
